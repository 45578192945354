<template>
  <launch-pad />
</template>
<script>
import LaunchPad from '@/components/launchpad/LaunchPad.vue'
export default {
  name: 'LaunchView',
  components: {
    LaunchPad
  }
}
</script>
<style lang="scss" scoped></style>
