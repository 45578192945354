<template>
  <div class="custom-list-wrapper">
    <div v-show="loading.Phones || loading.phones || loading.addresses">
      <span>Loading...</span>
    </div>
    <div v-show="!loading.Phones && !loading.phones && !loading.addresses" class="custom-list-header">
      <button class="btn btn-primary btn-sm" variant="outline-secondary" size="sm" @click="newPhone">Add</button>
      <button v-show="isShowDelete" class="btn btn-primary btn-sm ms-2" variant="outline-secondary" size="sm" @click="confirmDelete">Delete</button>
      <button v-show="isShowPrimary" class="btn btn-primary btn-sm ms-2" variant="outline-secondary" size="sm" @click="primaryPhone">
        Set as Primary
      </button>
      <span v-show="showTitle" class="custom-title">Phones</span>
    </div>
    <div v-show="!loading.phones" class="custom-list">
      <div v-for="(item, idx) of phones" :key="idx">
        <contact-phone-record :record-data="item" @itemChecked="itemChecked" @editItem="editPhone" />
      </div>
    </div>
  </div>
  <dialog-phone v-model="actionPhone" :phone="phoneRecord" @closing="closeModal" @send="sendPhone" />
  <confirm-delete
    v-model="actionDelete"
    :confirm-title="confirmDeleteTitle"
    :confirm-action="confirmDeleteAction"
    :confirm-question="confirmDeleteQuestion"
    @closing="closeModal"
    @proceed="performDelete"
  />
</template>
<script>
import { mapState, mapActions } from 'pinia'
import { contactStore } from '@/stores/contact'
import DialogPhone from '@/components/contact/forms/DialogPhone.vue'
import ContactPhoneRecord from '@/components/contact/listings/ContactPhoneRecord.vue'
import ConfirmDelete from '@/components/utilities/elements/ConfirmDialog.vue'
export default {
  name: 'ContactPhones',
  components: {
    DialogPhone,
    ContactPhoneRecord,
    ConfirmDelete
  },
  props: {
    record: {
      type: Object
    },
    showTitle: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      confirmDeleteTitle: 'Confirm Phone Deletion',
      confirmDeleteAction: 'Delete',
      confirmDeleteQuestion: 'Are you sure you wish to Permanently Delete checked phones?',
      actionPhone: null,
      actionDelete: null,
      isShowDelete: false,
      isShowPrimary: false,
      recordChecked: null,
      phoneRecord: undefined
    }
  },
  computed: {
    ...mapState(contactStore, ['loading', 'phones', 'phone'])
  },
  methods: {
    ...mapActions(contactStore, ['getPhones', 'resetPhone', 'assignPhone', 'setCheckedPhone', 'savePhone', 'deletePhone', 'setPrimaryPhone']),
    closeModal() {
      this.actionPhone = null
      this.actionDelete = null
      this.phoneRecord = undefined
      this.recordChecked = null
    },
    itemChecked(value, checked) {
      this.isShowDelete = checked
      this.isShowPrimary = checked
      this.recordChecked = null
      console.log('Checking Phone', {
        data: value,
        checked: checked
      })
      if (checked) {
        this.recordChecked = value
      }
      this.setCheckedPhone({
        contactPhone: value.contactPhone,
        isChecked: checked
      })
    },
    newPhone() {
      this.resetPhone({
        firm: this.record.firm,
        contact: this.record.contact,
        dir: this.record.user ? 'user' : 'firm'
      })
      this.phoneRecord = this.phone
      this.actionPhone = this.record
    },
    editPhone(data) {
      this.assignPhone(data)
      this.phoneRecord = data
      this.actionPhone = this.record
    },
    confirmDelete() {
      this.actionDelete = true
    },
    async performDelete() {
      this.actionDelete = false
      this.isShowDelete = false
      this.isShowPrimary = false
      this.recordChecked = null
      let phones = []
      for (let phone of this.phones) {
        if (phone.isChecked) {
          phones.push({
            contactPhone: phone.contactPhone,
            contact: this.record.contact
          })
        }
      }
      for (let phone of phones) {
        await this.deletePhone(phone)
      }
      await this.getPhones(this.record.contact)
    },
    async primaryPhone() {
      let data = {
        id: this.recordChecked.contactPhone,
        contact: this.record.contact
      }
      await this.setPrimaryPhone(data)
      this.isShowDelete = false
      this.isShowPrimary = false
      this.recordChecked = null
      await this.getPhones(this.record.contact)
    },
    async sendPhone() {
      this.actionPhone = null
      this.phoneRecord = undefined
      if (this.phone) {
        await this.savePhone(this.phone)
        await this.getPhones(this.record.contact)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.custom-list-wrapper {
  background-color: #ffffff;
  max-height: 210px;
  height: 210px;
  padding: 2px;
}
.custom-list-header {
  text-align: left;
  padding: 5px;
  border-bottom: 1px solid rgb(144, 144, 144);
  margin-bottom: 5px;
}
.custom-list {
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 5px;
  margin-left: 5px;
  margin-right: 5px;
  height: 155px;
}
.custom-title {
  float: right;
  position: relative;
}
</style>
