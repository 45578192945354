<template>
  <div class="row">
    <div class="col">
      <!-- Location Name and Postal Code (used for identification in other Applications) -->
      <div class="row">
        <div class="col-4">
          <z-input v-model="firmName" name="firmName" label="Name" :validator="v$" @keyup="changed(true)" @change="changed(true)" />
        </div>
        <div class="col-2">
          <z-input v-model="postalCode" name="postalCode" label="Postal Code" :validator="v$" @keyup="changed(false)" @change="changed(false)" />
        </div>
        <div class="col-2">
          <z-select v-model="firmType" name="firmType" label="Type" :options="types" :disabled="true" />
        </div>
        <div class="col">
          <input
            v-model="isInactive"
            type="checkbox"
            true-value="true"
            false-value="false"
            class="custom-checkbox me-2 custom-inactive-check"
            @click="changed(true)"
          />
          <span class="custom-inactive">
            Inactive?
            <div
              ref="info"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="This setting this will also apply to any child locations. Users will also become inactive."
            >
              <i class="fas fa-info-circle"></i>
            </div>
          </span>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <span class="custom-firm-name"
            >Note: for location name, it's extremely important the name matches exactly between LaunchPad and related applications.</span
          >
        </div>
      </div>
      <!-- Additional ID references -->
      <div class="row custom-border-top">
        <div class="col">
          <z-input v-model="firmId" name="firmId" label="Id #" @keyup="changed(false)" @change="changed(false)" />
          <div ref="infoId" data-bs-toggle="tooltip" data-bs-placement="top" title="Extra ID reference." class="custom-info">
            <i class="fas fa-info-circle"></i>
          </div>
        </div>
        <div class="col">
          <z-input v-model="firmNumber" name="firmNumber" label="Number" @keyup="changed(false)" @change="changed(false)" />
          <div
            ref="infoNumber"
            data-bs-toggle="tooltip"
            data-bs-placement="top"
            title="Your Location's Number (also used in pushes)."
            class="custom-info"
          >
            <i class="fas fa-info-circle"></i>
          </div>
          <span class="custom-firm-number">highly recommend the use of a Location Number</span>
        </div>
        <div class="col">
          <z-input v-model="opusId" name="opusId" label="OPUS IVS #" @keyup="changed(false)" @change="changed(false)" />
        </div>
        <div class="col"></div>
      </div>
      <!-- Type and IsInactive? -->
      <div class="row">
        <div class="col"></div>
      </div>
      <div class="row">
        <div class="col">
          <!-- Lookup for User Contact for this Location -->
          <z-search
            v-model.trim="searchTerm"
            name="search"
            class="custom-search-user"
            label="Main Contact:"
            placeholder="Search"
            :readonly="isLoading"
          />
          <div v-if="isFoundUsers" class="custom-found-user">
            <ul>
              <li v-for="user of foundFirmUsers" :key="user.user" :value="user.user" @click="selectUser(user)">
                {{ formatName(user) }}
              </li>
            </ul>
          </div>
        </div>
        <!-- For SHOPs - CCC Id and CCC Diag Number -->
        <div class="col">
          <z-input v-if="isShop && !isAdd" v-model="cccId" name="cccId" label="CCC Secureshare #" @keyup="changed(false)" @change="changed(false)" />
        </div>
        <div class="col">
          <z-input
            v-if="isShop && !isAdd"
            v-model="cccNumber"
            name="cccNumber"
            label="CCC Diagnostic #"
            @keyup="changed(false)"
            @change="changed(false)"
          />
        </div>
        <div class="col">
          <z-input
            v-if="isShop && !isAdd"
            v-model="mitchellId"
            name="mitchellId"
            label="Mitchell #"
            @keyup="changed(false)"
            @change="changed(false)"
          />
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <div v-if="!anyAppsAvailable" class="row mt-3">
          <div class="col">
            <span>Note: be sure to configure App Solutions at the corporate level.</span>
          </div>
        </div>
        <!-- For CENTERs and SHOPs -->
        <div v-if="(isShop || isCenter) && !isAdd && anyAppsAvailable" class="row mt-3 custom-border-top">
          <div class="col">
            <p>
              Check those App Solutions this location will be using. At the same time, if the location exists within an App Solution, configure their
              Location Ids here.
            </p>
            <p>
              To assist, use the 'search' button/icon in attempt to find the location Id (using name and postal code). You can also manually add the
              Id.
            </p>
          </div>
        </div>
        <div v-if="(isShop || isCenter) && !isAdd && anyAppsAvailable" class="row">
          <!-- For CENTERs -->
          <!-- ADAS MAP Setting -->
          <div v-if="isAdasAvailable" class="col-4">
            <div class="input-group">
              <input
                v-model="isADAS"
                type="checkbox"
                true-value="true"
                false-value="false"
                class="custom-checkbox me-2"
                title="check if using ADAS Map"
                @click="changed(false)"
              />
              <label class="form-label ms-1 me-2 pt-2">Uses ADAS MAP?<span class="ms-3 me-1">id:</span></label>
              <input
                v-model="adasId"
                class="form-control"
                name="adasId"
                placeholder="ADAS Map"
                type="number"
                @keyup="changed(false)"
                @change="changed(false)"
              />
              <button class="btn btn-link" title="Search ADAS Map for location" @click="searchApp('ADAS')">
                <font-awesome-icon icon="fa-solid fa-magnifying-glass-location custom-search-icon" style="font-size: 25px" />
              </button>
              <!-- <button class="btn btn-primary btn-sm" title="Push Location to ADAS Map" :disabled="!isADAS || isPushing" @click="pushApp('ADAS')">
                <div v-show="isPushing" class="custom-icon">
                  <i class="fas fa-spinner fa-spin"></i>
                </div>
                Push
              </button> -->
            </div>
          </div>
          <!-- CalCopilot Setting -->
          <div v-if="isCalCopilotAvailable" class="col-4">
            <div class="input-group">
              <input
                v-model="isCalCopilot"
                type="checkbox"
                true-value="true"
                false-value="false"
                class="custom-checkbox me-2"
                title="check if using CalCopilot"
                @click="changed(false)"
              />
              <label v-if="isCenter" class="form-label ms-1 me-2 pt-2">A Calibration Business?<span class="ms-3 me-1">id:</span></label>
              <label v-else class="form-label ms-1 me-2 pt-2">Use Calibration Service?<span class="ms-3 me-1">id:</span></label>
              <input
                v-model="calcopilotId"
                class="form-control"
                name="calcopilotId"
                placeholder="CalCopilot"
                type="number"
                @keyup="changed(false)"
                @change="changed(false)"
              />
              <button class="btn btn-link" title="Search CalCopilot for location" @click="searchApp('Calibrate')">
                <font-awesome-icon icon="fa-solid fa-magnifying-glass-location custom-search-icon" style="font-size: 25px" />
              </button>
              <!-- <button
                class="btn btn-primary btn-sm"
                title="Push Location to CalCopilot"
                :disabled="!isCalCopilot || isPushing"
                @click="pushApp('Calibrate')"
              >
                <div v-show="isPushing" class="custom-icon">
                  <i class="fas fa-spinner fa-spin"></i>
                </div>
                Push
              </button> -->
            </div>
          </div>
          <!-- TestDrive Copilot Setting -->
          <div v-if="isTestDriveAvailable" class="col-4">
            <div class="input-group">
              <input
                v-model="isTestDrive"
                type="checkbox"
                true-value="true"
                false-value="false"
                class="custom-checkbox me-2"
                title="check if using Test Drive Copilot"
                @click="changed(false)"
              />
              <label class="form-label ms-1 me-2 pt-2">Uses TestDrive Copilot?<span class="ms-3 me-1">id:</span></label>
              <input
                v-model="adtestdriveIdasId"
                class="form-control"
                name="testdriveId"
                placeholder="Test Drive Copilot"
                type="number"
                @keyup="changed(false)"
                @change="changed(false)"
              />
              <button class="btn btn-link custom-search-icon" title="Search Test Drive Copilot for location" @click="searchApp('TestDrive')">
                <font-awesome-icon icon="fa-solid fa-magnifying-glass-location custom-search-icon" style="font-size: 25px" />
              </button>
              <!-- <button
                class="btn btn-primary btn-sm"
                title="Push Location to Test Drive Copilot"
                :disabled="!isTestDrive || isPushing"
                @click="pushApp('TestDrive')"
              >
                <div v-show="isPushing" class="custom-icon">
                  <i class="fas fa-spinner fa-spin"></i>
                </div>
                Push
              </button> -->
            </div>
          </div>
          <div class="col-4"></div>
        </div>
        <div v-if="anyAppsAvailable" class="row mt-2">
          <div class="col">
            <p v-if="(isShop || isCenter) && !isAdd">
              Otherwise, if location does not exist in the App Solution, then use the 'Push' button to push the location over to that solution.
            </p>
            <p v-if="isCalCopilot && isCalCopilotAvailable && isCenter" class="mt-4">
              <b>Note:</b> if this is a Calibration Business, be sure to set up your Locations being served (see "Serving" tab above).
            </p>
            <p v-if="isCalCopilot && isCalCopilotAvailable && isShop" class="mt-4">
              <b>Note:</b> As this is a Shop, be sure to add this Shop to a Calibration Business (as part of their served network locations).
            </p>
          </div>
        </div>
        <div v-if="isOkayToMove" class="row mt-1">
          <div class="col">
            <div class="row custom-border-top">
              <div class="col">
                <p>To move this location to another, search and select the location (to move to) and click the 'Move' button.</p>
              </div>
            </div>
            <div class="row">
              <div class="col-4">
                <Multiselect
                  ref="searchLocation"
                  v-model="foundLocation"
                  class="form-control custom-multiselect"
                  placeholder="type to search"
                  :close-on-select="true"
                  :filter-results="false"
                  :min-chars="1"
                  :resolve-on-load="false"
                  :delay="500"
                  :searchable="true"
                  :open-direction="'top'"
                  :options="
                    async (query) => {
                      return await fetchLocations(query)
                    }
                  "
                  label="firmName"
                  value-prop="firm"
                  @select="selectedLocation"
                />
              </div>
              <div class="col-1">
                <button class="btn btn-primary btn-sm" @click="reassignLocation">Move</button>
              </div>
              <div class="col"></div>
            </div>
            <div class="row">
              <div class="col">
                <p><b>Note:</b> Be sure to choose a "parent" location that is identical to this location's current parent.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from 'pinia'
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { locationStore } from '@/stores/location'
import { lookupStore } from '@/stores/lookup'
import { Tooltip } from 'bootstrap'
import ZSearch from '@/components/utilities/elements/Search.vue'
import ZInput from '@/components/utilities/elements/Input.vue'
import ZSelect from '@/components/utilities/elements/Select.vue'
import Multiselect from '@vueform/multiselect'
export default {
  name: 'LocationBasic',
  components: {
    ZSearch,
    ZInput,
    ZSelect,
    Multiselect
  },
  props: {
    parent: Object,
    isAdd: {
      type: Boolean,
      default: false
    }
  },
  emits: ['message', 'changed', 'saved', 'moved'],
  data() {
    return {
      foundLocation: null,
      isPushing: false,
      rules: {
        firmName: { required },
        firmType: { required }
      },
      v$: null
    }
  },
  beforeMount() {
    this.v$ = useVuelidate(this.rules, this.location)
    this.isPushing = false
    this.newUsername = ''
    this.editingUsername = false
  },
  mounted() {
    new Tooltip(this.$refs.info)
    new Tooltip(this.$refs.infoId)
    new Tooltip(this.$refs.infoNumber)
  },
  computed: {
    ...mapState(lookupStore, ['lookups']),
    ...mapState(locationStore, ['loading', 'location', 'userSearchTerm', 'foundFirmUsers', 'foundLocations']),
    isLoading() {
      let _is = false
      if (this.loading) {
        _is = this.loading.users || this.loading.locations
      }
      return _is
    },
    types() {
      let _types = []
      if (this.lookups) {
        _types = this.lookups['FirmType']
      }
      console.log('Firm Types', _types)
      return _types
    },
    isPartner() {
      let _is = false
      if (this.location) {
        if (this.location.firmType === 5) {
          _is = true
        }
      }
      return _is
    },
    isCenter() {
      let _is = false
      if (this.location) {
        if (this.location.firmType === 6) {
          _is = true
        }
      }
      return _is
    },
    isShop() {
      let _is = false
      if (this.location) {
        if (this.location.firmType === 7) {
          _is = true
        }
      }
      return _is
    },
    isOkayToMove() {
      let _is = false
      if (this.location) {
        if (this.location.firmType > 3) {
          _is = true
        }
      }
      return _is
    },
    firmName: {
      get() {
        let _data = null
        if (this.location) {
          _data = this.location.firmName
        }
        return _data
      },
      set(value) {
        this.setLocation({
          name: 'firmName',
          value: value
        })
      }
    },
    firmType: {
      get() {
        let _data = null
        if (this.location) {
          _data = this.location.firmType
        }
        return _data
      },
      set(value) {
        this.setLocation({
          name: 'firmType',
          value: parseInt(value)
        })
      }
    },
    postalCode: {
      get() {
        let _data = null
        if (this.location) {
          _data = this.location.postalCode
        }
        return _data
      },
      set(value) {
        this.setLocation({
          name: 'postalCode',
          value: value
        })
      }
    },
    isInactive: {
      get() {
        let _data = false
        if (this.location) {
          _data = this.location.isInactive
        }
        return _data
      },
      set(value) {
        this.setLocation({
          name: 'isInactive',
          value: value === 'true'
        })
      }
    },
    firmId: {
      get() {
        let _data = null
        if (this.location) {
          _data = this.location.firmId
        }
        return _data
      },
      set(value) {
        this.setLocation({
          name: 'firmId',
          value: value
        })
      }
    },
    firmNumber: {
      get() {
        let _data = null
        if (this.location) {
          _data = this.location.firmNumber
        }
        return _data
      },
      set(value) {
        this.setLocation({
          name: 'firmNumber',
          value: value
        })
      }
    },
    opusId: {
      get() {
        let _data = null
        if (this.location) {
          _data = this.location.opusId
        }
        return _data
      },
      set(value) {
        this.setLocation({
          name: 'opusId',
          value: value
        })
      }
    },
    cccId: {
      get() {
        let _data = null
        if (this.location) {
          _data = this.location.cccId
        }
        return _data
      },
      set(value) {
        this.setLocation({
          name: 'cccId',
          value: value
        })
      }
    },
    cccNumber: {
      get() {
        let _data = null
        if (this.location) {
          _data = this.location.cccNumber
        }
        return _data
      },
      set(value) {
        this.setLocation({
          name: 'cccNumber',
          value: value
        })
      }
    },
    mitchellId: {
      get() {
        let _data = null
        if (this.location) {
          _data = this.location.mitchellId
        }
        return _data
      },
      set(value) {
        this.setLocation({
          name: 'mitchellId',
          value: value
        })
      }
    },
    anyAppsAvailable() {
      let _is = false
      if (this.location) {
        if (this.location.availableApps) {
          _is = this.location.availableApps.length > 0
        }
      }
      return _is
    },
    isAdasAvailable() {
      let _is = false
      if (this.location) {
        if (this.location.availableApps) {
          let found = this.location.availableApps.find((x) => x.appNumber.includes('ADAS'))
          if (found) {
            _is = true
          }
        }
      }
      return _is
    },
    isCalCopilotAvailable() {
      let _is = false
      if (this.location) {
        if (this.location.availableApps) {
          let found = this.location.availableApps.find((x) => x.appNumber.includes('Calibrate'))
          if (found) {
            _is = true
          }
        }
      }
      return _is
    },
    isTestDriveAvailable() {
      let _is = false
      if (this.location) {
        if (this.location.availableApps) {
          let found = this.location.availableApps.find((x) => x.appNumber.includes('TestDrive'))
          if (found) {
            _is = true
          }
        }
      }
      return _is
    },
    calcopilotId: {
      get() {
        let _data = null
        if (this.location) {
          _data = this.location.calcopilotId
        }
        return _data
      },
      set(value) {
        this.setLocation({
          name: 'calcopilotId',
          value: parseInt(value)
        })
      }
    },
    adasId: {
      get() {
        let _data = null
        if (this.location) {
          _data = this.location.adasId
        }
        return _data
      },
      set(value) {
        this.setLocation({
          name: 'adasId',
          value: parseInt(value)
        })
      }
    },
    testdriveId: {
      get() {
        let _data = null
        if (this.location) {
          _data = this.location.testdriveId
        }
        return _data
      },
      set(value) {
        this.setLocation({
          name: 'testdriveId',
          value: parseInt(value)
        })
      }
    },
    isCalCopilot: {
      get() {
        let _data = false
        if (this.location) {
          _data = this.location.isCalCopilot
        }
        return _data
      },
      set(value) {
        this.setLocation({
          name: 'isCalCopilot',
          value: value === 'true'
        })
      }
    },
    isADAS: {
      get() {
        let _data = false
        if (this.location) {
          _data = this.location.isADAS
        }
        return _data
      },
      set(value) {
        this.setLocation({
          name: 'isADAS',
          value: value === 'true'
        })
      }
    },
    isTestDrive: {
      get() {
        let _data = false
        if (this.location) {
          _data = this.location.isTestDrive
        }
        return _data
      },
      set(value) {
        this.setLocation({
          name: 'isTestDrive',
          value: value === 'true'
        })
      }
    },
    searchTerm: {
      get() {
        return this.userSearchTerm
      },
      set(value) {
        this.setUserSearchTerm(value)
        this.searchForUsers()
      }
    },
    isFoundUsers() {
      let _is = false
      if (this.foundFirmUsers) {
        if (this.foundFirmUsers.length > 0) {
          _is = true
        }
      }
      return _is
    }
  },
  methods: {
    ...mapActions(locationStore, [
      'setLocation',
      'saveLocation',
      'searchAppFirm',
      'setUserSearchTerm',
      'searchFirmUser',
      'pushLocation',
      'searchLocations',
      'resetFoundLocations',
      'moveLocation'
    ]),
    changed(is) {
      this.$emit('changed', is)
    },
    async searchForUsers() {
      console.log('Search Users...')
      let data = {
        firm: this.parent.parent,
        search: this.userSearchTerm
      }
      await this.searchFirmUser(data)
    },
    formatName(user) {
      let data = null
      if (user.firstname) {
        data = `${user.lastname}, ${user.firstname}`
      } else {
        data = user.lastname
      }
      return data
    },
    selectUser(user) {
      let data = null
      console.log('Selected User', user)
      if (user.firstname) {
        data = `${user.lastname}, ${user.firstname}`
      } else {
        data = user.lastname
      }
      this.setLocation({
        name: 'UserId',
        value: parseInt(user.user)
      })
      this.setUserSearchTerm(data)
      this.changed()
    },
    async searchApp(app) {
      let found = null
      let name = ''
      let number = this.getAppNumber(app)
      if (number) {
        switch (app) {
          case 'ADAS':
            console.log('Searching ADAS')
            name = 'ADAS Copilot'
            found = await this.searchAppFirm(number)
            console.log('Found ADAS Firm', found)
            if (found) {
              if (Array.isArray(found)) {
                this.adasId = found[0].firm
              } else {
                this.adasId = found
              }
            }
            break
          case 'Calibrate':
            console.log('Searching Calibrate')
            name = 'Cal Copilot'
            found = await this.searchAppFirm(number)
            console.log('Found Calibrate Firm', found)
            if (found) {
              if (Array.isArray(found)) {
                this.calcopilotId = found[0].firm
              } else {
                this.calcopilotId = found
              }
            }
            break
          case 'TestDrive':
            name = 'Test Drive Copilot'
            console.log('Searching TestDrive')
            found = await this.searchAppFirm(number)
            console.log('Found TestDrive Firm', found)
            if (found) {
              if (Array.isArray(found)) {
                this.testdriveId = found[0].firm
              } else {
                this.testdriveId = found
              }
            }
            break
        }
        if (found === undefined || found === null || found === '') {
          alert(`Could not find Location in ${name}. Please verify name/postal code, then try again. Otherwise, you may have to 'Add Location'.`)
        } else {
          alert('Found Location')
        }
      } else {
        alert('App not available')
      }
    },
    async fetchLocations(query) {
      let search = {
        search: query,
        type: null,
        firmType: this.firmType,
        firm: this.location.firm
      }
      return await this.searchLocations(search)
    },
    selectedLocation() {
      this.$nextTick(() => {
        let found = this.foundLocations.find((x) => parseInt(x.firm) === parseInt(this.foundLocation))
        if (found) {
          console.log('Found Location', found)
        }
      })
    },
    async reassignLocation() {
      let data = {
        firm: this.location.firm,
        firm2: this.foundLocation
      }
      console.log('Moving Location', data)
      this.resetFoundLocations()
      this.$refs.searchLocation.clear()
      this.$refs.searchLocation.clearSearch()
      this.foundLocation = null
      let result = await this.moveLocation(data)
      console.log('Move Result', result)
      if (result !== undefined && result !== null) {
        if (parseInt(result) > 0) {
          // refresh
          this.$emit('moved', data)
        } else {
          alert('Move failed, ensure the Location can be a valid "child" under the targeted move location.')
        }
      }
    },
    getAppNumber(app) {
      let number = null
      let found = this.location.availableApps.find((x) => x.appNumber.includes(app))
      if (found) {
        number = found.appNumber
      }
      return number
    },
    async pushApp(app) {
      // NOTE: currently, not used
      let data = {
        firm: this.location.firm,
        firmType: this.location.firmType,
        appAdas: null,
        appCalibrate: null,
        appTestDrive: null,
        isPushUsers: false
      }
      let found = null
      switch (app) {
        case 'ADAS':
          found = this.location.availableApps.find((x) => x.appNumber.includes('ADAS'))
          if (found) {
            data.appAdas = found.appNumber
          }
          break
        case 'Calibrate':
          found = this.location.availableApps.find((x) => x.appNumber.includes('Calibrate'))
          if (found) {
            data.appCalibrate = found.appNumber
          }
          break
        case 'TestDrive':
          found = this.location.availableApps.find((x) => x.appNumber.includes('TestDrive'))
          if (found) {
            data.appTestDrive = found.appNumber
          }
          break
      }
      if (data.appAdas !== null || data.appCalibrate !== null || data.appTestDrive !== null) {
        console.log('Push App', data)
        this.isPushing = true
        await this.pushLocation(data)
        this.isPushing = false
      }
    },
    async save() {
      let response = {
        valid: false,
        firm: null,
        msg: null
      }
      try {
        const valid = await this.v$.$validate()
        if (valid) {
          console.log('Save Location', this.location)
          let result = await this.saveLocation()
          response.valid = true
          response.firm = result
          this.$emit('saved', result)
        } else {
          let msg = 'Location Not Valid'
          response.msg = msg
          this.$emit('message', msg)
        }
      } catch (e) {
        console.error(e)
        response.msg = 'Error: Unable to save'
        this.message = response.msg
      }
      return response
    }
  }
}
</script>
<style lang="scss">
.custom-inactive {
  position: relative;
  display: inline-flex;
  div {
    color: #0a58ca;
    margin-left: 5px;
    top: -5px;
  }
}
.custom-inactive-check {
  margin-top: 30px;
}
.custom-inline {
  display: inline-flex;
}
.custom-info {
  position: absolute;
  top: 0;
  right: 20px;
  color: #0a58ca;
}
.custom-search-icon {
  position: relative;
  top: 0px;
}
.custom-border-top {
  border-top: 1px solid #dee2e6;
  padding-top: 10px;
}
.custom-search-user {
  z-index: 10;
}
.custom-found-user {
  position: relative;
  width: 90%;
  max-height: 150px;
  overflow-x: hidden;
  overflow-y: auto;
  border: 1px solid #dee2e6;
  padding: 0;
  top: -2px;
  z-index: 5;
  ul {
    list-style: none;
    margin: 0 auto;
    padding: 8px;
    cursor: pointer;
    :hover {
      background-color: #0d6efd;
      color: #fff;
    }
    li {
      padding: 2px;
    }
  }
}
.custom-icon {
  width: 100%;
  display: inline;
}
.custom-firm-name {
  color: red;
  font-size: 0.9em;
  font-weight: 500;
  margin-bottom: 10px;
  position: relative;
  top: -10px;
}
.custom-border-top {
  border-top: 1px solid #dee2e6;
}
.custom-firm-number {
  font-size: 0.8em;
  font-weight: 600;
  position: relative;
  top: -10px;
  color: #ca0a0a;
}
</style>
