import { createRouter, createWebHistory } from 'vue-router'
import guard from './guard'
import routes from './routes'

const router = createRouter({
  history: createWebHistory(import.meta.env.BASE_URL),
  routes: routes
})
router.beforeEach((to, from, next) => {
  guard(to, from, next)
})
export default router
