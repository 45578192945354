<template>
  <header :class="{ 'bd-banner-navbar': isBannerEnabled }" class="navbar navbar-expand navbar-dark flex-column flex-md-row bd-navbar">
    <router-link class="navbar-brand mr-0 mr-md-2 custom-brand" to="/" aria-label="atc_service">
      <img src="~@/assets/images/Opus_IVS_90h_extrawhite.png" alt="atc_service" style="height: 48px" />
      <span class="custom-app-title">Launchpad</span>
    </router-link>

    <div class="navbar-nav-scroll">
      <ul class="navbar-nav bd-navbar-nav flex-row">
        <li v-for="n of nav" :key="n.name" class="nav-item">
          <router-link v-if="profile.isAllowed(n.url)" exact class="nav-link" :to="n.url" title="n.name">{{ n.name }}</router-link>
        </li>
      </ul>
    </div>

    <ul class="navbar-nav flex-row ml-md-auto d-none d-md-flex">
      <li v-if="gotProfile" class="nav-item">
        <a id="profile" href="#" class="nav-link" title="profile" @click="showProfile">
          <i class="fas fa-user pr-1"></i>
          {{ username }}
        </a>
      </li>
      <li class="nav-item">
        <a id="logout" href="#" class="nav-link" title="logout" @click="logout">
          <i class="fas fa-sign-out-alt"></i>
          Logout
        </a>
      </li>
    </ul>
    <service-hub />
  </header>
</template>
<script>
import { mapState, mapActions } from 'pinia'
import { authStore } from '@/stores/auth'
import ServiceHub from '../ServiceHub.vue'
export default {
  name: 'NavTop',
  components: {
    ServiceHub
  },
  emits: ['showProfile'],
  data() {
    return {
      nav: []
    }
  },
  mounted() {
    this.initMenu()
  },
  computed: {
    ...mapState(authStore, ['profile']),
    gotProfile() {
      let _got = false
      if (this.profile) {
        _got = true
      }
      return _got
    },
    isBannerEnabled() {
      let env = window.config ? window.config.environment : ''
      return env !== 'prod'
    },
    username: {
      get() {
        let user = ''
        if (this.profile !== undefined && this.profile !== null) {
          user = this.profile.username
        }
        return user
      }
    }
  },
  methods: {
    ...mapActions(authStore, ['clearToken']),
    initMenu() {
      this.nav = []
    },
    showProfile() {
      this.$emit('showProfile')
    },
    logout() {
      this.clearToken()
      window.location.assign('/')
    }
  }
}
</script>
<style lang="scss" scoped>
.custom-brand {
  position: relative;
  background: #fff;
  padding: 5px;
}
.custom-app-title {
  position: absolute;
  bottom: 2px;
  left: 52px;
  color: #000;
  font-size: 0.7em;
  font-weight: 500;
}
</style>
