<template>
  <form @submit.prevent="">
    <z-dialog :show="isVisible" :modal-size="'modal-xl'">
      <template #header>
        <div class="custom-header">
          <div class="row">
            <div class="col-md-11">
              <h4 class="modal-title">Add User to App</h4>
            </div>
            <div class="col-md-1">
              <a href="#" class="custom-close" @click="close">
                <i class="fa fa-times"></i>
              </a>
            </div>
          </div>
        </div>
      </template>
      <template #body>
        <div class="row">
          <div class="col">
            <input
              v-if="isAdasFirmId"
              id="appAdasGerber"
              v-model="apps"
              type="checkbox"
              class="custom-checkbox ms-4"
              value="ADASGerber"
              @change="captureApps"
            />
            <span v-if="isAdasFirmId" class="ms-1">ADAS Map (Gerber)</span>
            <input
              v-if="isCalCopilotFirmId"
              id="appCalibrate"
              v-model="apps"
              type="checkbox"
              class="custom-checkbox ms-4"
              value="Calibrate"
              @change="captureApps"
            />
            <span v-if="isCalCopilotFirmId" class="ms-1">Calibrate Copilot</span>
            <input
              v-if="isTestDriveFirmId"
              id="appTestDrive"
              v-model="apps"
              type="checkbox"
              class="custom-checkbox ms-4"
              value="TestDrive"
              @change="captureApps"
            />
            <span v-if="isTestDriveFirmId" class="ms-1">Test Drive Copilot</span>
          </div>
        </div>
        <div class="row">
          <div class="col"></div>
        </div>
      </template>
      <template #footer>
        <div class="custom-footer">
          <div class="col">
            <span title="id">{{ ids }}</span>
          </div>
          <div class="col"></div>
          <div class="col custom-right-align">
            <button type="button" class="btn btn-light" @click="close()">Cancel</button>
            <button v-show="isSave" class="btn btn-danger" @click="submit">Save</button>
          </div>
        </div>
      </template>
    </z-dialog>
  </form>
</template>
<script>
import { mapState, mapActions } from 'pinia'
import { userStore } from '@/stores/user'
import ZDialog from '@/components/utilities/elements/Dialog.vue'
export default {
  name: 'DialogAddUserToApp',
  components: {
    ZDialog
  },
  props: {
    modelValue: {
      type: Object
    }
  },
  data() {
    return {
      apps: []
    }
  },
  emits: ['closing', 'save'],
  computed: {
    ...mapState(userStore, ['loading', 'user']),
    isVisible() {
      return this.modelValue !== null
    },
    ids() {
      let _ids = ''
      if (this.modelValue) {
        _ids = `# ${this.modelValue.user}`
      }
      return _ids
    },
    isAdasFirmId() {
      return this.user.adasFirmId !== null
    },
    isCalCopilotFirmId() {
      return this.user.calcopilotFirmId !== null
    },
    isTestDriveFirmId() {
      return this.user.testdriveFirmId !== null
    }
  },
  methods: {
    ...mapActions(userStore, ['setAppUser']),
    close() {
      this.$emit('closing')
    },
    captureApps() {
      this.$nextTick(() => {
        this.setAppUser({
          name: 'apps',
          value: this.apps
        })
        console.log('Set Apps', {
          apps: this.apps,
          onboard: this.onboard
        })
      })
    },
    async submit() {}
  }
}
</script>
<style lang="scss" scoped>
.custom-header {
  width: 100%;
}
.hostWrapper {
  max-height: 650px;
  overflow-x: hidden;
  overflow-y: auto;
}
.custom-footer {
  width: 100%;
  display: inline-flex;
}
.custom-right-align {
  text-align: right;
}
.custom-close {
  float: right;
  margin-right: 10px;
  color: #000;
  cursor: pointer;
}
</style>
