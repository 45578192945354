<template>
  <user-header
    :show-title="showTitle"
    :firm="currentFirm"
    :pages="pages"
    :page-sizes="pageSizes"
    :num-items="count"
    @search="search"
    @add="newUser"
    @delete="deleteUser"
    @activate="activateUser"
  />
  <div class="table-responsive" :class="{ disabled: isLoading }">
    <div class="custom-listing" :style="listingHeight">
      <z-table
        v-model="records"
        class="table table-bordered table-striped"
        :is-single-row-detail="true"
        :is-header-fixed="isHeaderFixed"
        :sort-by="sortBy"
        :order-by="orderBy"
        :columns="tableColumnsName"
        :detail-column-class="detailColumnClass"
        :loading="isLoading"
        @sort="setOrder"
      >
        <template #tbody="props">
          <td class="custom-column-60">
            <a href="#" @click="editUser(props.row)">view</a>
          </td>
          <th scope="row" class="custom-column-60">{{ props.row.user }}</th>
          <td>{{ props.row.username }}</td>
          <td>{{ props.row.fullName }}</td>
          <td>{{ props.row.firmName }}</td>
          <td>{{ props.row.roleName }}</td>
          <!-- <td>{{ formatDate(props.row.lastLoginDate) }}</td>
          <td>{{ props.row.loginCountMonth }}</td> -->
          <td class="text-center">{{ showInactive(props.row.isInactive) }}</td>
          <!-- <td v-if="isFirm" class="text-center">{{ showFirmContact(props.row.user) }}</td> -->
        </template>
        <template #detail="props">
          <user-current-locations :user="props.row.user" />
        </template>
        <template #empty> Nothing found! </template>
      </z-table>
    </div>
  </div>
  <confirm-delete
    v-model="actionDelete"
    :confirm-title="confirmDeleteTitle"
    :confirm-action="confirmDeleteAction"
    :confirm-question="confirmDeleteQuestion"
    @closing="closeModal"
    @proceed="performDeletion"
  />
  <dialog-user
    v-model="actionUser"
    :show-firm="showFirm"
    @closing="closeModal"
    @saved="savedUser"
    @moved="movedUser"
    @changePassword="changePassword"
  />
  <dialog-password v-model="actionPassword" @closing="closeModalPassword" @save="performChangePassword" />
</template>
<script>
import { mapState, mapActions } from 'pinia'
import { authStore } from '@/stores/auth'
import { userStore } from '@/stores/user'
import { lookupStore } from '@/stores/lookup'
import globals from '@/plugins/globals'
import ZTable from '@/components/utilities/elements/Table.vue'
import UserHeader from './UserHeader.vue'
import ConfirmDelete from '@/components/utilities/elements/ConfirmDialog.vue'
import DialogUser from './forms/DialogUser.vue'
import DialogPassword from './forms/DialogPassword.vue'
import UserCurrentLocations from './forms/UserCurrentLocations.vue'

export default {
  name: 'UserListing',
  components: {
    ZTable,
    UserHeader,
    ConfirmDelete,
    DialogUser,
    DialogPassword,
    UserCurrentLocations
  },
  props: {
    firm: Object,
    showTitle: {
      type: Boolean,
      default: true
    },
    isHeaderFixed: {
      type: Boolean,
      default: false
    },
    listingHeight: String,
    showFirm: Object
  },
  data() {
    return {
      confirmDeleteTitle: 'Confirm User Deletion',
      confirmDeleteAction: 'Delete',
      confirmDeleteQuestion: 'Are you sure you wish to Permanently Delete checked users?',
      confirmActivateTitle: 'Confirm User Activation',
      confirmActivateAction: 'Activate',
      confirmActivateQuestion: 'Are you sure you wish to Activate checked users?',
      actionDelete: null,
      actionActivate: null,
      actionUser: null,
      actionPassword: null,
      page: 1,
      pageSizes: [
        { name: '10', value: 10 },
        { name: '25', value: 25 },
        { name: '50', value: 50 },
        { name: '100', value: 100 }
      ],
      detailColumnClass: 'custom-column-60'
    }
  },
  async mounted() {
    this.page = 1
    this.setListParam({
      name: 'page',
      value: this.page
    })
    await this.getRoles(this.currentFirm.firm)
    await this.search()
  },
  computed: {
    ...mapState(authStore, ['profile']),
    ...mapState(userStore, ['loading', 'listParams', 'users']),
    count() {
      return this.users.count
    },
    records() {
      return this.users.items
    },
    tableColumnsName() {
      let columns = [
        {
          field: 'user',
          label: '',
          sortable: false,
          class: 'custom-column-60'
        },
        {
          field: 'user',
          label: 'Id',
          sortable: true,
          class: 'custom-column-80'
        },
        { field: 'username', label: 'Username', sortable: true },
        { field: 'fullName', label: 'Name', sortable: true },
        { field: 'firmName', label: 'Location (primary)', sortable: false },
        { field: 'roleName', label: 'Role', sortable: false },
        // { field: 'lastLoginDate', label: 'Last Login', sortable: false },
        // { field: 'loginCountMonth', label: '# Logins', sortable: false },
        { field: 'isInactive', label: 'Inactive?', sortable: false }
      ]
      // if (this.isFirm) {
      //   columns.push({ field: 'isFirm', label: 'Firm Contact?', sortable: false, class: 'custom-column-120' })
      // }
      return columns
    },
    pages() {
      return Math.ceil(this.users.count / this.listParams.pageSize)
    },
    sortBy() {
      return this.listParams.sortBy
    },
    orderBy() {
      return this.listParams.orderBy
    },
    isLoading() {
      return this.loading.users
    },
    currentFirm() {
      let _firm = null
      if (this.firm == null) {
        _firm = {
          firm: this.profile.firm,
          firmName: this.profile.firmName
        }
        return _firm
      } else {
        return this.firm
      }
    },
    isFirm() {
      let _is = false
      if (this.showFirm) {
        _is = true
      }
      return _is
    }
  },
  methods: {
    ...mapActions(lookupStore, ['getRoles', 'getLookups', 'getTechnicians']),
    ...mapActions(userStore, ['setListParam', 'paginate', 'getUser', 'getUsers', 'resetUser', 'deleteUsers', 'saveCredentials']),
    formatDate(date, type = 'datetime') {
      return globals.formatDate(date, type)
    },
    closeModal() {
      this.actionUser = null
      this.actionDelete = null
    },
    closeModalPassword() {
      this.actionPassword = null
    },
    async setOrder(column, sortBy) {
      this.page = 1
      this.setListParam({
        name: 'sortBy',
        value: sortBy
      })
      this.setListParam({
        name: 'orderBy',
        value: column.field
      })
      await this.search(this.page, this.searchTerm)
    },
    showInactive(isInactive) {
      let _data = null
      if (isInactive) {
        _data = 'Y'
      }
      return _data
    },
    showFirmContact(id) {
      let _data = null
      if (parseInt(this.showFirm.user) === parseInt(id)) {
        _data = 'Y'
      }
      return _data
    },
    async search(page = 1) {
      // Load up the Users
      try {
        this.page = page
        await this.getUsers({
          page: this.page,
          offset: (this.page - 1) * this.listParams.pageSize,
          sortBy: this.listParams.sortBy,
          orderBy: this.listParams.orderBy,
          pageSize: this.listParams.pageSize,
          searchTerm: this.listParams.searchTerm,
          firm: this.firm !== undefined && this.firm !== null ? this.firm.firm : this.profile.firm
        })
      } catch (e) {
        console.log('Error', e)
      }
    },
    newUser() {
      this.resetUser(this.currentFirm.firm)
      this.actionUser = {
        isAdd: true,
        user: null
      }
    },
    async editUser(data) {
      await this.getLookups({
        keys: ['Language', 'TimeZone'],
        firm: 1,
        refresh: false
      })
      await this.getLookups({
        keys: ['FirmChannel'],
        firm: this.firm.firm,
        refresh: true
      })
      await this.getTechnicians()
      await this.getUser(data.username)
      console.log('Viewing User', data)
      this.actionUser = {
        isAdd: false,
        user: data.user,
        firm: this.firm.firm
      }
    },
    savedUser() {
      this.actionUser = {
        isAdd: false,
        user: null
      }
      this.search()
    },
    movedUser() {
      this.actionUser = null
      this.search()
    },
    deleteUser() {
      this.actionDelete = true
    },
    activateUser() {},
    async performDeletion() {
      let users = []
      for (let user of this.users.items) {
        if (user.isChecked) {
          users.push({
            userId: user.user.toString(),
            token: null
          })
        }
      }
      this.actionDelete = null
      if (users.length > 0) {
        this.page = 1
        await this.deleteUsers(users)
        await this.search(this.page, this.searchTerm)
      }
    },
    async performChangePassword(data) {
      await this.saveCredentials(data)
      this.actionPassword = null
    },
    changePassword(data) {
      this.actionUser = null
      this.actionPassword = data
    }
  }
}
</script>
<style lang="scss">
.custom-listing {
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
  max-height: 500px;
  height: 500px;
}
.custom-header {
  position: sticky;
  top: 0;
}
</style>
