<template>
  <config-host />
</template>
<script>
import ConfigHost from '@/components/configuration/ConfigHost.vue'
export default {
  name: 'ConfigView',
  components: {
    ConfigHost
  }
}
</script>
<style lang="scss" scoped></style>
