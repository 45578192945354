<template>
  <section>
    <ul class="list-unstyled ps-0 custom-links">
      <li v-for="n of nav" :key="n.root" class="mb-1">
        <button
          class="btn btn-toggle align-items-center rounded"
          :class="{ hidden: n.root.trim() === '' }"
          data-bs-toggle="collapse"
          :data-bs-target="`#${n.root}-collapse`"
          aria-expanded="true"
        >
          {{ n.root }}
        </button>
        <div :id="`${n.root}-collapse`" class="collapse show">
          <ul class="navbar-nav btn-toggle-nav list-unstyled fw-normal pb-1 small custom-link">
            <li v-for="p of n.pages" :key="p.name" class="nav-item">
              <router-link exact :to="p.url" class="nav-link">
                <i :class="[{ hidden: p.icon.trim() === '' }, p.icon]"></i>
                <span>{{ p.name }}</span>
              </router-link>
            </li>
          </ul>
        </div>
      </li>
    </ul>
  </section>
</template>
<script>
import { mapState, mapActions } from 'pinia'
import { authStore } from '@/stores/auth'

export default {
  name: 'NavLeft',
  data() {
    return {
      searchText: '',
      isCollapsed: false,
      nav: [
        {
          root: '',
          isActive: true,
          isHidden: false,
          pages: [{ url: '/', name: 'Home', icon: '', isHidden: false }]
        }
      ],
      navSupport: []
    }
  },
  mounted() {
    this.initMenu()
  },
  computed: {
    ...mapState(authStore, ['profile']),
    isAdmin() {
      let _is = false
      if ([1, 2, 3, 5].includes(this.profile.roles[0])) {
        _is = true
      }
      return _is
    }
  },
  methods: {
    ...mapActions(authStore, ['clearToken']),
    initMenu() {
      this.nav = [
        {
          root: '',
          isActive: true,
          isHidden: false,
          pages: [{ url: '/', name: 'Home', icon: '', isHidden: false }]
        }
      ]
      if (this.isAdmin) {
        this.nav[0].pages.push({
          url: '/configuration',
          name: 'Configuration',
          icon: 'fa-solid fa-sliders',
          isHidden: false
        })
      }
      if ([1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 19, 20].includes(this.profile.roles[0])) {
        this.nav[0].pages.push({
          url: '/locations',
          name: 'Locations',
          icon: 'fa-solid fa-map-marker-alt',
          isHidden: false
        })
      }
      this.nav[0].pages.push({
        url: '/launchpad',
        name: 'Launchpad',
        icon: 'fa-solid fa-rocket',
        isHidden: false
      })
      this.navSupport = [
        { name: 'About', icon: '', action: 'about' },
        { name: 'Logout', icon: '', action: 'logout' }
      ]

      console.log('Nav Items', this.nav)
    },
    isActive() {
      return true
    },
    isVisible(pages) {
      return pages.some((p) => this.store.isAllowed(p.url))
    },
    search() {
      this.isCollapsed = false
      for (let n of this.nav) {
        n.isHidden = false
        let count = 0
        for (let p of n.pages) {
          p.isHidden = !p.name.toLowerCase().includes(this.searchText.toLowerCase())
          if (!p.isHidden) {
            count++
          }
        }
        n.isHidden = !count
      }
      this.$forceUpdate()
    },
    performAction(action) {
      if (action === 'about') {
        this.$router.push('/about')
      } else if (action === 'logout') {
        this.clearToken()
        window.location.assign('/')
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.hidden {
  display: none;
}
.custom-active {
  font-weight: 500;
  color: #007bff;
}
.custom-links {
  padding-top: 1rem;
  padding-bottom: 1rem;
  margin-left: -15px;
  margin-right: -15px;
}
.custom-link {
  font-size: 1rem;
  line-height: 1.5;
  font-weight: 400;
  svg {
    width: 36px;
  }
  a {
    display: block;
    padding: 0.25rem 1.5rem;
    margin-top: 5px;
    margin-bottom: 5px;
    color: #fff;
  }
}
.badge-incoming {
  position: relative;
  top: -5px;
  display: inline-flex;
  align-items: center;
  align-content: center;
  justify-content: center;
  color: white;
  background: darkgreen;
  position: relative;
  box-sizing: border-box;
  text-align: center;
  vertical-align: middle;
  font-weight: normal;
  font-size: 12px;
  border-radius: 18px;
  padding: 0 4px;
  height: 18px;
  min-width: 18px;
}
</style>
