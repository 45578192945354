import { defineStore } from 'pinia'
import { api } from '@/plugins/api'

const onboardDefault = {
  apps: [
    {
      firm: null,
      name: 'ADAS',
      checked: false
    },
    {
      firm: null,
      name: 'ADASGerber',
      checked: false
    },
    {
      firm: null,
      name: 'Calibrate',
      checked: false
    },
    {
      firm: null,
      name: 'CalibrateGerber',
      checked: false
    },
    {
      firm: null,
      name: 'TestDrive',
      checked: false
    },
    {
      firm: null,
      name: 'TestDriveGerber',
      checked: false
    }
  ],
  basic: {
    marketing: null,
    email: '',
    firstname: '',
    lastname: ''
  },
  additional: {
    firm: null,
    company: '',
    addresslines: '',
    city: '',
    stateprovince: null,
    postalcode: '',
    country: 1,
    phonecc: 1,
    phoneno: '',
    language: 1,
    timezone: 12,
    webaddress: ''
  },
  shipping: {
    addresslines: '',
    city: '',
    stateprovince: null,
    postalcode: '',
    country: 1
  }
}
export const onboardStore = defineStore('onboard', {
  /* Current State */
  state: () => ({
    firm: null, // Launchpad Firm ID
    companyId: null,
    testMode: false,
    onboard: JSON.parse(JSON.stringify(onboardDefault)),
    isSame: false,
    disable: false,
    lookups: {}
  }),
  actions: {
    async sleep(milliseconds) {
      await new Promise((resolve) => {
        return setTimeout(resolve, milliseconds)
      })
    },
    setFirm(firm) {
      this.firm = firm
    },
    resetOnboard() {
      this.onboard = JSON.parse(JSON.stringify(onboardDefault))
    },
    /* Getting the lookup values for the dropdowns. */
    async getLookups() {
      let keys = ['StateProvince', 'Country', 'TimeZone', 'Language']
      let results = await api.get(`lookup/onboardLookups`)
      if (results) {
        let _lookups = {}
        for (let key of keys) {
          _lookups[key] = []
          for (let item of results) {
            if (item.key === key) {
              item.codename = item.name
              item.name = item.value || item.name
              item.value = item.id
              item.isInactive = false
              _lookups[key].push(item)
            }
          }
        }
        this.lookups = JSON.parse(JSON.stringify(Object.assign(this.lookups, _lookups)))
      }
      console.log('Got Lookups', this.lookups)
      return this.lookups
    },
    /* Find Code for Country */
    getCountryCode(id) {
      let code = null
      let found = this.lookups.Country.find((x) => parseInt(x.id) === parseInt(id))
      if (found) {
        code = found.code
      }
      return code
    },
    /* Find Abbr for State/Province */
    getStateProvinceAbbr(id) {
      let abbr = null
      let found = this.lookups.StateProvince.find((x) => parseInt(x.id) === parseInt(id))
      if (found) {
        abbr = found.name
      }
      return abbr
    },
    /* Capture data in state */
    setData(data) {
      switch (data.name) {
        case 'apps': {
          this.onboard.apps.forEach((element) => {
            element.checked = false
          })
          data.value.forEach((element) => {
            let found = this.onboard.apps.find((x) => x.name === element)
            if (found) {
              found.checked = true
            }
          })
          break
        }
        case 'testmode':
          this.testMode = data.value.toString() === 'true' ? true : false
          break
        case 'email':
          this.onboard.basic.email = data.value
          break
        case 'firstname':
          this.onboard.basic.firstname = data.value
          break
        case 'lastname':
          this.onboard.basic.lastname = data.value
          break
        case 'company':
          this.onboard.additional.company = data.value
          break
        case 'addresslines':
          this.onboard.additional.addresslines = data.value
          break
        case 'city':
          this.onboard.additional.city = data.value
          break
        case 'stateprovince':
          this.onboard.additional.stateprovince = data.value
          break
        case 'postalcode':
          this.onboard.additional.postalcode = data.value
          break
        case 'country':
          this.onboard.additional.country = data.value
          break
        case 'phonecc':
          this.onboard.additional.phonecc = data.value
          break
        case 'phoneno':
          this.onboard.additional.phoneno = data.value
          break
        case 'language':
          this.onboard.additional.language = data.value
          break
        case 'timezone':
          this.onboard.additional.timezone = data.value
          break
        case 'webaddress':
          this.onboard.additional.webaddress = data.value
          break
        default:
          break
      }
    },
    /* Set Shipping same as Main Address */
    setShipping(isSame) {
      this.onboard.isSame = isSame
      if (isSame) {
        this.onboard.shipping.addresslines = this.onboard.additional.addresslines
        this.onboard.shipping.city = this.onboard.additional.city
        this.onboard.shipping.stateprovince = this.onboard.additional.stateprovince
        this.onboard.shipping.postalcode = this.onboard.additional.postalcode
        this.onboard.shipping.country = this.onboard.additional.country
      } else {
        this.onboard.shipping.addresslines = ''
        this.onboard.shipping.city = ''
        this.onboard.shipping.stateprovince = null
        this.onboard.shipping.postalcode = ''
        this.onboard.shipping.country = null
      }
    },
    /* Save data to our backend */
    async saveOnboard(action) {
      let result = null
      let endpoint = null
      let firm = null
      switch (action) {
        case 'basic':
          console.log('Saving Basic', this.onboard.basic)
          endpoint = 'firm/onboardMarketing'
          // save to each App Solution
          for await (let app of this.onboard.apps) {
            if (app.checked) {
              result = await api.post(endpoint, {
                app: app.name,
                marketing: this.onboard.basic.marketing,
                firstName: this.onboard.basic.firstname,
                lastName: this.onboard.basic.lastname,
                emailAddress: this.onboard.basic.email
              })
              console.log('Saved Basic', result)
              this.onboard.basic.marketing = result
              app.marketing = result
            }
          }

          // this.onboard.apps.forEach(async (app) => {
          //   if (app.checked) {
          //     result = await api.post(endpoint, {
          //       app: app.name,
          //       marketing: this.onboard.basic.marketing,
          //       firstName: this.onboard.basic.firstname,
          //       lastName: this.onboard.basic.lastname,
          //       emailAddress: this.onboard.basic.email
          //     })
          //     console.log('Saved Basic', result)
          //     if (result === '') {
          //       result = null
          //     }
          //     this.onboard.basic.marketing = result
          //     app.marketing = result
          //   }
          // })
          break
        case 'additional':
          console.log('Saving Additional', this.onboard.additional)
          // now, save to each App Solution
          if (this.onboard.additional.firm !== null) {
            endpoint = 'firm/onboardFirmUpdate'
          } else {
            endpoint = 'firm/onboardFirm'
          }
          for await (let app of this.onboard.apps) {
            if (app.checked) {
              result = await api.post(endpoint, {
                app: app.name,
                firm: app.firm,
                company: this.onboard.additional.company,
                firstName: this.onboard.basic.firstname,
                lastName: this.onboard.basic.lastname,
                email: this.onboard.basic.email,
                addressLines: this.onboard.additional.addresslines,
                city: this.onboard.additional.city,
                stateProvince: this.onboard.additional.stateprovince.toString(),
                postalCode: this.onboard.additional.postalcode,
                country: this.onboard.additional.country.toString(),
                phoneCC: this.onboard.additional.phonecc.toString(),
                phoneNo: this.onboard.additional.phoneno,
                languageId: this.onboard.additional.language,
                timezoneId: this.onboard.additional.timezone,
                webaddress: this.onboard.additional.webaddress,
                companyId: null,
                firmType: 'Corporate'
              })
              firm = result
              if (firm === '') {
                firm = null
              }
              this.onboard.additional.firm = firm
              app.firm = firm
            }
          }
          // this.onboard.apps.forEach(async (app) => {
          //   if (app.checked) {
          //     result = await api.post(endpoint, {
          //       app: app.name,
          //       firm: app.firm,
          //       company: this.onboard.additional.company,
          //       firstName: this.onboard.basic.firstname,
          //       lastName: this.onboard.basic.lastname,
          //       email: this.onboard.basic.email,
          //       addressLines: this.onboard.additional.addresslines,
          //       city: this.onboard.additional.city,
          //       stateProvince: this.onboard.additional.stateprovince.toString(),
          //       postalCode: this.onboard.additional.postalcode,
          //       country: this.onboard.additional.country.toString(),
          //       phoneCC: this.onboard.additional.phonecc.toString(),
          //       phoneNo: this.onboard.additional.phoneno,
          //       languageId: this.onboard.additional.language,
          //       timezoneId: this.onboard.additional.timezone,
          //       webaddress: this.onboard.additional.webaddress,
          //       companyId: null,
          //       firmType: 'Corporate'
          //     })
          //     firm = result
          //     if (firm === '') {
          //       firm = null
          //     }
          //     this.onboard.additional.firm = firm
          //     app.firm = firm
          //   }
          // })
          break
        case 'shipping':
          console.log('Saving Shipping', this.onboard.shipping)
          endpoint = 'firm/onboardShipping'
          // save to each App Solution
          for await (let app of this.onboard.apps) {
            if (app.checked) {
              result = await api.post(endpoint, {
                app: app.name,
                id: this.onboard.shipping.id,
                firm: app.firm,
                addressLines: this.onboard.shipping.addresslines,
                city: this.onboard.shipping.city,
                stateProvince: this.onboard.shipping.stateprovince.toString(),
                postalCode: this.onboard.shipping.postalcode,
                country: this.onboard.shipping.country.toString()
              })
              this.onboard.shipping.id = result
              app.shippingId = result
              firm = app.firm
            }
          }
          // this.onboard.apps.forEach(async (app) => {
          //   if (app.checked) {
          //     result = await api.post(endpoint, {
          //       app: app.name,
          //       id: this.onboard.shipping.id,
          //       firm: app.firm,
          //       addressLines: this.onboard.shipping.addresslines,
          //       city: this.onboard.shipping.city,
          //       stateProvince: this.onboard.shipping.stateprovince.toString(),
          //       postalCode: this.onboard.shipping.postalcode,
          //       country: this.onboard.shipping.country.toString()
          //     })
          //     this.onboard.shipping.id = result
          //     app.shippingId = result
          //     firm = app.firm
          //   }
          // })
          break
      }
      console.log('Performed an Onboard Save', this.onboard)
      return this.onboard
    }
  }
})
