<template>
  <div class="custom-list-wrapper">
    <div v-show="loading.emails || loading.phones || loading.addresses">
      <span>Loading...</span>
    </div>
    <div v-show="!loading.emails && !loading.phones && !loading.addresses" class="custom-list-header">
      <button class="btn btn-primary btn-sm" variant="outline-secondary" size="sm" @click="newEmail">Add</button>
      <button v-show="isShowDelete" class="btn btn-primary btn-sm ms-2" variant="outline-secondary" size="sm" @click="confirmDelete">Delete</button>
      <button v-show="isShowPrimary" class="btn btn-primary btn-sm ms-2" variant="outline-secondary" size="sm" @click="primaryEmail">
        Set as Primary
      </button>
      <span v-show="showTitle" class="custom-title">Email Addresses</span>
    </div>
    <div v-show="!loading.emails" class="custom-list">
      <div v-for="(item, idx) of emails" :key="idx">
        <contact-email-record :record-data="item" @itemChecked="itemChecked" @editItem="editEmail" />
      </div>
    </div>
  </div>
  <dialog-email v-model="actionEmail" :email="emailRecord" @closing="closeModal" @send="sendEmail" />
  <confirm-delete
    v-model="actionDelete"
    :confirm-title="confirmDeleteTitle"
    :confirm-action="confirmDeleteAction"
    :confirm-question="confirmDeleteQuestion"
    @closing="closeModal"
    @proceed="performDelete"
  />
</template>
<script>
import { mapState, mapActions } from 'pinia'
import { contactStore } from '@/stores/contact'
import DialogEmail from '@/components/contact/forms/DialogEmail.vue'
import ContactEmailRecord from '@/components/contact/listings/ContactEmailRecord.vue'
import ConfirmDelete from '@/components/utilities/elements/ConfirmDialog.vue'
export default {
  name: 'ContactEmails',
  components: {
    DialogEmail,
    ContactEmailRecord,
    ConfirmDelete
  },
  props: {
    record: {
      type: Object
    },
    showTitle: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      confirmDeleteTitle: 'Confirm Email Deletion',
      confirmDeleteAction: 'Delete',
      confirmDeleteQuestion: 'Are you sure you wish to Permanently Delete checked emails?',
      actionEmail: null,
      actionDelete: null,
      isShowDelete: false,
      isShowPrimary: false,
      recordChecked: null,
      emailRecord: undefined
    }
  },
  computed: {
    ...mapState(contactStore, ['loading', 'emails', 'email'])
  },
  methods: {
    ...mapActions(contactStore, ['getEmails', 'resetEmail', 'assignEmail', 'setCheckedEmail', 'saveEmail', 'deleteEmail', 'setPrimaryEmail']),
    closeModal() {
      this.actionEmail = null
      this.actionDelete = null
      this.emailRecord = undefined
      this.recordChecked = null
    },
    itemChecked(value, checked) {
      this.isShowDelete = checked
      this.isShowPrimary = checked
      this.recordChecked = null
      if (checked) {
        this.recordChecked = value
      }
      this.setCheckedEmail({
        contactEmail: value.contactEmail,
        isChecked: checked
      })
    },
    newEmail() {
      this.resetEmail({
        firm: this.record.firm,
        contact: this.record.contact,
        dir: this.record.user ? 'user' : 'firm'
      })
      this.emailRecord = this.email
      this.actionEmail = this.record
    },
    editEmail(data) {
      this.assignEmail(data)
      this.emailRecord = data
      this.actionEmail = this.record
    },
    confirmDelete() {
      this.actionDelete = true
    },
    async performDelete() {
      this.actionDelete = false
      this.isShowDelete = false
      this.isShowPrimary = false
      this.recordChecked = null
      let emails = []
      for (let email of this.emails) {
        if (email.isChecked) {
          emails.push({
            contactEmail: email.contactEmail,
            contact: this.record.contact
          })
        }
      }
      for (let email of emails) {
        await this.deleteEmail(email)
      }
      await this.getEmails(this.record.contact)
    },
    async primaryEmail() {
      let data = {
        id: this.recordChecked.contactEmail,
        contact: this.record.contact
      }
      await this.setPrimaryEmail(data)
      this.isShowDelete = false
      this.isShowPrimary = false
      this.recordChecked = null
      await this.getEmails(this.record.contact)
    },
    async sendEmail() {
      this.actionEmail = null
      this.emailRecord = undefined
      if (this.email !== null) {
        await this.saveEmail(this.email)
        await this.getEmails(this.record.contact)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.custom-list-wrapper {
  background-color: #ffffff;
  max-height: 210px;
  height: 210px;
  padding: 2px;
}
.custom-list-header {
  text-align: left;
  padding: 5px;
  border-bottom: 1px solid rgb(144, 144, 144);
  margin-bottom: 5px;
}
.custom-list {
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 5px;
  margin-left: 5px;
  margin-right: 5px;
  height: 155px;
}
.custom-title {
  float: right;
  position: relative;
}
</style>
