<template>
  <div class="row mt-2">
    <div class="col">
      <z-tabs v-model="currentLocationTab" :tabs="locationTabs" @change="setLocationTab" />
      <div v-if="currentLocationTab === 'Basic'" class="row p-2">
        <div class="col">
          <location-basic ref="basic" :parent="firm" :is-add="isAdd" @changed="changed" @moved="movedLocation" />
        </div>
      </div>
      <div v-if="currentLocationTab === 'Users'" class="row p-2">
        <div class="col">
          <p>The following are those users associated with this location (and any decendent locations).</p>
          <user-listing ref="users" :firm="firm" :show-firm="showFirm" :show-title="false" />
        </div>
      </div>
      <div v-if="currentLocationTab === 'Contact'" class="row p-2">
        <div class="col">
          <p>Use the following to supply this location's address, phone, and email information.</p>
          <p>For Mobile Copilot, it is important you, at least, set the Address of this location if it is a Center or a Shop.</p>
          <contact-info :record="firm" />
        </div>
      </div>
      <div v-if="currentLocationTab === 'App Config'" class="row p-2">
        <div class="col">
          <location-apps ref="apps" @changed="changed" />
        </div>
      </div>
      <div v-if="currentLocationTab === 'Apps'" class="row p-2">
        <div class="col">
          <location-export />
        </div>
      </div>
      <div v-if="currentLocationTab === 'Serving'" class="row p-2">
        <div class="col">
          <network-tree ref="locations" :firm="firm" @changed="changed" />
        </div>
      </div>
      <div v-if="currentLocationTab === 'Served By'" class="row p-2 mt-2">
        <div class="col">
          <location-served-by ref="servedBy" />
        </div>
      </div>
      <div v-if="currentLocationTab === 'Log'" class="row p-2 mt-2">
        <div class="col">
          <review-log ref="reviewLog" />
        </div>
      </div>
    </div>
  </div>
  <div class="row mt-2">
    <div class="col">
      <span># {{ id }}</span>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from 'pinia'
import { authStore } from '@/stores/auth'
import { locationStore } from '@/stores/location'
import ZTabs from '@/components/utilities/elements/Tabs.vue'
import LocationBasic from './LocationBasic.vue'
import LocationApps from './LocationApps.vue'
import LocationExport from './LocationExport.vue'
import LocationServedBy from './LocationServedBy.vue'
import UserListing from '@/components/users/UserListing.vue'
import NetworkTree from './NetworkTree.vue'
import ContactInfo from '@/components/contact/listings/ContactInfo.vue'
import ReviewLog from './ReviewLog.vue'
export default {
  name: 'LocationHost',
  components: {
    ZTabs,
    LocationBasic,
    LocationApps,
    LocationExport,
    LocationServedBy,
    UserListing,
    NetworkTree,
    ContactInfo,
    ReviewLog
  },
  props: {
    firm: Object,
    isAdd: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isSave: false,
      currentLocationTab: 'Basic',
      message: null,
      isLocationChanged: false
    }
  },
  mounted() {
    this.emitter.on('firm-contact-changed', (data) => {
      console.log('Location Host set save', data)
      this.isSave = true
      this.$emit('save')
    })
    this.currentLocationTab = 'Basic'
    this.init()
  },
  emits: ['save', 'saved', 'moved'],
  computed: {
    ...mapState(authStore, ['profile']),
    ...mapState(locationStore, ['location', 'apps']),
    isAdmin() {
      let _is = false
      if ([1, 2, 3, 5].includes(this.profile.roles[0])) {
        _is = true
      }
      return _is
    },
    id() {
      let _id = null
      if (this.firm) {
        _id = this.firm.id
      }
      return _id
    },
    firmType() {
      let _data = null
      if (this.firm) {
        _data = this.firm.type
      }
      return _data
    },
    isCalCopilot() {
      let _is = false
      if (this.location) {
        if (this.location.firmType === 6) {
          _is = true
        }
      }
      return _is
    },
    isShop() {
      let _is = false
      if (this.location) {
        if (this.location.firmType === 7) {
          _is = true
        }
      }
      return _is
    },
    locationTabs() {
      let _tabs = ['Basic']
      if (!this.isAdd) {
        _tabs.push('Users')
        _tabs.push('Contact')
        if (this.firmType === 'Admin' && this.isAdmin) {
          _tabs.push('App Config')
          _tabs.push('Log')
        } else {
          _tabs.push('Apps')
        }
        if (this.isCalCopilot && !this.isShop) {
          _tabs.push('Serving')
        }
        if (this.isShop) {
          _tabs.push('Served By')
        }
      }
      return _tabs
    },
    showFirm() {
      let _show = null
      if (this.firm) {
        _show = {
          firm: this.firm.id,
          user: null
        }
      }
      return _show
    }
  },
  methods: {
    ...mapActions(locationStore, ['saveLocation', 'getServedBy', 'getLog']),
    init() {
      this.isSave = false
      this.currentLocationTab = 'Basic'
    },
    changed(is) {
      this.isLocationChanged = is
      this.isSave = true
      this.$emit('save')
    },
    async setLocationTab(value) {
      let proceed = true
      if (this.isSave) {
        proceed = await this.save()
      }
      if (proceed) {
        this.currentLocationTab = value
        if (value === 'Users') {
          if (this.$refs.users) {
            this.$refs.users.search(1, null)
          }
        } else if (value === 'Served By') {
          await this.getServedBy(this.firm.id)
        } else if (value === 'Log') {
          let data = {
            startDate: null,
            endDate: null,
            firm: this.firm.id
          }
          await this.getLog(data)
        }
      }
    },
    movedLocation(data) {
      this.$emit('moved', data)
    },
    async save() {
      let proceed = true
      this.message = null
      if (this.isSave) {
        console.log('Saving Location', this.currentLocationTab)
        let response = null
        if (this.currentLocationTab === 'Basic') {
          response = await this.$refs.basic.save()
        } else if (this.currentLocationTab === 'App Config') {
          response = await this.$refs.apps.save()
        } else if (this.currentLocationTab === 'Contact') {
          console.log('Saving contact info')
        } else if (this.currentLocationTab === 'Serving') {
          response = await this.$refs.locations.save()
        } else if (this.currentLocationTab === 'Users') {
          if (this.isSave) {
            let result = await this.saveLocation()
            if (result !== undefined && result !== null) {
              response = {
                valid: true,
                msg: null
              }
            } else {
              response = {
                valid: false,
                msg: 'Location User save failed.'
              }
            }
          }
        }
        if (response) {
          if (response.valid) {
            this.isSave = false
            this.$emit('saved', this.isLocationChanged)
            this.isLocationChanged = false
          } else {
            proceed = false
            this.message = response.msg
          }
        } else {
          proceed = false
          this.message = 'Save Failed'
        }
      }
      return proceed
    }
  },
  beforeUnmount() {
    this.emitter.off('firm-contact-changed')
  }
}
</script>
<style lang="scss" scoped>
.custom-subtitle {
  font-style: italic;
  margin-left: 10px;
}
</style>
