<template>
  <p>Use the 'Push' button(s) to send this location (and <u>decendent</u> hierarchy of locations) to the appropriate App Solution.</p>
  <p>Any location that doesn't exist, will be created, otherwise they'll be updated.</p>
  <p class="custom-notice">Note: for location name, it's extremely important the name matches exactly between LaunchPad and related applications.</p>
  <div class="row mt-4">
    <div class="col-2 custom-label"><span>App Solution</span></div>
    <div class="col-3 custom-label"><span>Location Push</span></div>
  </div>
  <ul class="custom-list">
    <li v-for="app of availableApps" :key="app.app">
      <div class="row">
        <div class="col-2">
          <span>{{ app.appName }}</span>
        </div>
        <div class="col-3 custom-action">
          <button class="btn btn-primary btn-sm" :disabled="isPushing" @click="pushToApp(app)">
            <div v-show="isPushing" class="custom-icon">
              <i class="fas fa-spinner fa-spin"></i>
            </div>
            Push
          </button>
          <div class="d-inline">
            <input
              v-model="app.isPushUsers"
              type="checkbox"
              :true-value="true"
              :false-value="false"
              :disabled="isPushing"
              class="custom-checkbox ms-3"
            />
            <span>include users</span>
          </div>
        </div>
        <div class="col">
          <!-- Push Progress -->
          <div v-if="pushingApp === app.appNumber" class="row">
            <div class="col-4">
              <div class="progress">
                <div
                  class="progress-bar"
                  :class="{ 'bg-success': !isPushing, 'progress-bar-striped progress-bar-animated': isPushing }"
                  role="progressbar"
                  :style="`width: ${progress}%;`"
                  :aria-valuenow="progress"
                  aria-valuemin="0"
                  aria-valuemax="100"
                ></div>
              </div>
            </div>
            <div class="col custom-stats">
              <span>{{ duration }}</span>
              <span class="ms-2">{{ total }}</span>
            </div>
          </div>
          <div v-if="pushingApp === app.appNumber" class="row">
            <div class="col custom-stats">
              <span>{{ data }}</span>
            </div>
          </div>
        </div>
      </div>
    </li>
  </ul>
</template>
<script>
import { mapState, mapActions } from 'pinia'
import { locationStore } from '@/stores/location'
export default {
  name: 'LocationExport',
  data() {
    return {
      isLoading: false,
      isPushing: false,
      progress: 0,
      duration: null,
      data: null,
      total: null,
      isComplete: false,
      pushingApp: null
    }
  },
  async mounted() {
    this.emitter.on('process-pushlocation', (status) => {
      console.log('Push Location Progress', status)
      this.pushProgress(status)
    })
    await this.init()
  },
  computed: {
    ...mapState(locationStore, ['location', 'apps']),
    availableApps() {
      let _apps = []
      if (this.apps) {
        this.apps.forEach((app) => {
          if (app.appNumber) {
            if (app.appNumber.includes('ADAS')) {
              _apps.push(app)
            }
            if (app.appNumber.includes('Calibrate')) {
              _apps.push(app)
            }
            if (app.appNumber.includes('TestDrive')) {
              _apps.push(app)
            }
          }
        })
      }
      console.log('Available Apps', _apps)
      return _apps
    },
    isCalCopilot() {
      let _data = false
      if (this.location) {
        _data = this.location.isCalCopilot
      }
      return _data
    },
    isADAS() {
      let _data = false
      if (this.location) {
        _data = this.location.isADAS
      }
      return _data
    },
    isTestDrive() {
      let _data = false
      if (this.location) {
        _data = this.location.isTestDrive
      }
      return _data
    }
  },
  methods: {
    ...mapActions(locationStore, ['getLocationApps', 'pushLocation']),
    async init() {
      this.isPushing = false
      this.isLoading = true
      await this.getLocationApps({
        id: this.location.firm,
        dir: 4
      })
      this.isLoading = false
    },
    pushProgress(status) {
      this.isPushing = true
      let isComplete = this.getStatus(status, 'IsComplete')
      if (isComplete) {
        if (parseInt(isComplete) === 1) {
          console.log('Push Location Complete')
          this.isPushing = false
          this.data = ''
        }
      } else {
        let total = this.getStatus(status, 'TotalCount')
        let count = this.getStatus(status, 'Count')
        let duration = this.getStatus(status, 'TotalDuration')
        this.data = this.getStatus(status, 'Location')
        if (total !== null && count !== null) {
          this.progress = (parseInt(count) / parseInt(total)) * 100
        }
        if (duration) {
          if (duration > 3600) {
            this.duration = `${(duration / 3600).toFixed(1)} hours`
          } else if (duration > 60) {
            this.duration = `${(duration / 60).toFixed(1)} minutes`
          } else {
            this.duration = `${duration.toFixed(0)} seconds`
          }
        }
        if (count !== null) {
          this.total = `for ${count} of ${total} records.`
        }
        console.log('Current Progress', {
          progress: this.progress,
          duration: duration,
          data: this.data,
          total: this.total
        })
        if (this.progress >= 100) {
          this.isPushing = false
        }
      }
    },
    getStatus(status, item) {
      let value = null
      if (status) {
        let found = status.find((x) => x.attribute === item)
        if (found) {
          value = found.data ? found.data : found.value
        }
      }
      return value
    },
    async pushToApp(app) {
      let data = {
        firm: this.location.firm,
        firmType: this.location.firmType,
        appAdas: null,
        appCalibrate: null,
        appTestDrive: null,
        isPushUsers: app.isPushUsers
      }
      let found = null
      if (app.appNumber.includes('ADAS')) {
        found = this.location.availableApps.find((x) => x.appNumber.includes('ADAS'))
        if (found) {
          data.appAdas = found.appNumber
        }
      } else if (app.appNumber.includes('Calibrate')) {
        found = this.location.availableApps.find((x) => x.appNumber.includes('Calibrate'))
        if (found) {
          data.appCalibrate = found.appNumber
        }
      } else if (app.appNumber.includes('TestDrive')) {
        found = this.location.availableApps.find((x) => x.appNumber.includes('TestDrive'))
        if (found) {
          data.appTestDrive = found.appNumber
        }
      }
      if (data.appAdas !== null || data.appCalibrate !== null || data.appTestDrive !== null) {
        console.log('Push App', {
          location: this.location,
          data: data
        })
        this.isPushing = true
        this.pushingApp = app.appNumber
        await this.pushLocation(data)
        this.pushingApp = null
        this.isPushing = false
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.custom-list {
  list-style: none;
  li {
    padding-top: 5px;
    padding-bottom: 5px;
  }
}
.custom-row {
  width: 100%;
  display: inline;
}
.custom-label {
  margin-left: 20px;
  span {
    font-weight: 500;
  }
}
.custom-action {
  button {
    margin-left: 20px;
    top: 3px;
  }
}
.custom-icon {
  width: 100%;
  display: inline;
}
.custom-stats {
  font-size: 0.9em;
}
.custom-notice {
  color: red;
  font-size: 0.9em;
  font-weight: 500;
  margin-bottom: 10px;
  position: relative;
}
</style>
