import { defineStore } from 'pinia'
import { api } from '@/plugins/api'
const baseUrl = window.config ? `${window.config.apiUrl}/user` : `${import.meta.env.VITE_API_URL}/user`
// const baseUrl = `${import.meta.env.VITE_API_URL}/user`
// this should be an api call but for now we just hard code
const PAGE_PERMISSIONS = {
  '/login': [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25],
  '/locations': [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 19, 20],
  '/users': [1, 2, 3, 4, 5, 7, 9, 11, 13, 15, 17],
  '/launchpad': [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25],
  '/chat': [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25],
  '/chatadmin': [1, 2, 3, 5],
  '/configuration': [1, 2, 3, 5]
}

export const authStore = defineStore({
  id: 'auth',
  state: () => ({
    // initialize state from local storage to enable user to stay logged in
    token: JSON.parse(sessionStorage.getItem('token')),
    profile: sessionStorage.getItem('profile') ? JSON.parse(sessionStorage.getItem('profile')) : { username: null, password: null, roles: [] },
    // profile: { username: null, password: null },
    passthrough: ''
  }),
  actions: {
    async login(username, password) {
      const token = await api.post(`${baseUrl}/getToken`, { username, password })
      if (token) {
        this.token = token
        // store the token to a session variable
        sessionStorage.setItem('token', JSON.stringify(token))
        // decode the token
        var base64Url = token.split('.')[1]
        var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
        var jsonPayload = decodeURIComponent(
          atob(base64)
            .split('')
            .map(function (c) {
              return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
            })
            .join('')
        )
        // parse the payload
        var tokenPayload = JSON.parse(jsonPayload)
        // establish a profile
        let profile = {
          user: parseInt(tokenPayload.uid),
          contact: parseInt(tokenPayload.cid),
          firm: parseInt(tokenPayload.fid),
          firmName: tokenPayload.bname,
          timezone: parseInt(tokenPayload.tz),
          firstName: tokenPayload.fname,
          lastName: tokenPayload.lname,
          username: tokenPayload.uname,
          email: tokenPayload.email,
          language: tokenPayload.cc,
          type: parseInt(tokenPayload.ct),
          firmType: parseInt(tokenPayload.bt),
          guid: tokenPayload.guid,
          groups: tokenPayload.gids,
          roles: [],
          techId: tokenPayload.tid,
          appts: tokenPayload.appts ? JSON.parse(tokenPayload.appts) : []
        }
        profile.appts.forEach((element) => {
          if (element.appNumber === 'Remote') {
            element.token = this.token
          } else if (element.token.indexOf('incorrect') > 0) {
            element.token = null
          }
        })
        profile.roles.push(parseInt(tokenPayload.rid))
        // save the profile to a session variable
        sessionStorage.setItem('profile', JSON.stringify(profile))
        this.profile = profile
      }
    },
    setData(data) {
      switch (data.name) {
        case 'username':
          this.profile.username = data.value
          break
        case 'password':
          this.profile.password = data.value
          break
      }
    },
    setPassthrough(passthrough) {
      this.passthrough = passthrough
    },
    clearToken() {
      this.token = null
      this.profile = null
      sessionStorage.removeItem('token')
      sessionStorage.removeItem('profile')
    },
    logout() {
      this.token = null
      this.profile = null
      sessionStorage.clear()
      this.$router.push('/login')
    },
    isAllowed() {
      return (page = '') => {
        if (!PAGE_PERMISSIONS[page]) {
          return true
        }
        if (this.profile.roles && this.profile.roles.length) {
          return this.profile.roles.some((r) => PAGE_PERMISSIONS[page].includes(r))
        }
        return PAGE_PERMISSIONS[page].includes('Guest')
      }
    },
    async resetPassword(data) {
      return await api.post('user/resetPassword', data)
    }
  }
})
