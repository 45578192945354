<template>
  <div class="custom-nonav-wrapper">
    <slot />
  </div>
</template>
<script>
export default {
  name: 'LayoutNoNav',
  computed: {
    version() {
      let _version = import.meta.env.VITE_VERSION
      return _version
    }
  }
}
</script>
<style lang="scss" scoped>
.custom-nonav-wrapper {
  padding: 10px;
}
</style>
