<template>
  <component :is="layout">
    <router-view />
  </component>
</template>

<script>
import NONAV from '@/layouts/NoNav.vue'
import DEFAULT from '@/layouts/Default.vue'
import SIDENAV from '@/layouts/SideNav.vue'
import LAYOUT from '@/layouts/Default.vue'

const options = {
  NONAV,
  DEFAULT,
  SIDENAV,
  LAYOUT
}

export default {
  name: 'LayoutBroker',
  props: {
    name: String
  },
  computed: {
    layout() {
      let component = options[this.name.toUpperCase().replace(/\W/g, '')]
      return component || NONAV
    }
  }
}
</script>
