<template>
  <section class="custom-wrapper">
    <p>Select those solution applications to be available for this corporation location.</p>
    <p>For ADAS Map and Test Drive Copilot, you may "push" this location (and <u>decendent</u> hierarchy of locations) to that App Solution.</p>
    <div class="row">
      <div class="col-2 custom-label"><span>App</span></div>
      <div class="col-2 custom-label text-center"><span>Accepts BMS?</span></div>
      <div class="col-2 custom-label"><span></span></div>
      <div class="col"></div>
    </div>
    <ul class="custom-list">
      <li v-for="app of apps" :key="app.app">
        <div class="row">
          <div class="col-2">
            <input
              :id="app.app"
              v-model="app.checked"
              type="checkbox"
              :true-value="true"
              :false-value="false"
              class="custom-checkbox me-2"
              @click="changed(app)"
            />
            <span>{{ app.appName }}</span>
          </div>
          <div class="col-2 text-center">
            <input :id="`bms_${app.app}`" v-model="app.isBms" type="checkbox" :true-value="true" :false-value="false" class="custom-checkbox me-2" />
          </div>
          <div class="col-3 custom-action">
            <button v-if="showAppPush(app)" class="btn btn-primary btn-sm" :disabled="isPushing" @click="pushToApp(app)">
              <div v-show="isPushing && pushingApp === app.appNumber" class="custom-icon">
                <i class="fas fa-spinner fa-spin"></i>
              </div>
              Push
            </button>
            <div v-if="showAppPush(app)" class="d-inline">
              <input
                v-model="app.isPushUsers"
                type="checkbox"
                :true-value="true"
                :false-value="false"
                class="custom-checkbox ms-3"
                :disabled="isPushing"
              />
              <span>include users</span>
            </div>
          </div>
          <div class="col">
            <!-- Push Progress -->
            <div v-if="showAppPush(app) && pushingApp === app.appNumber" class="row">
              <div class="col-4">
                <div class="progress">
                  <div
                    class="progress-bar"
                    :class="{ 'bg-success': !isPushing, 'progress-bar-striped progress-bar-animated': isPushing }"
                    role="progressbar"
                    :style="`width: ${progress}%;`"
                    :aria-valuenow="progress"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  ></div>
                </div>
              </div>
              <div class="col custom-stats">
                <span>{{ duration }}</span>
                <span class="ms-2">{{ total }}</span>
              </div>
            </div>
            <div v-if="showAppPush(app) && pushingApp === app.appNumber" class="row">
              <div class="col custom-stats">
                <span>{{ data }}</span>
              </div>
            </div>
          </div>
        </div>
      </li>
    </ul>
  </section>
</template>
<script>
import { mapState, mapActions } from 'pinia'
import { locationStore } from '@/stores/location'
export default {
  name: 'LocationApps',
  data() {
    return {
      isPushing: false,
      progress: 0,
      duration: null,
      data: null,
      total: null,
      isComplete: false,
      pushingApp: null
    }
  },
  async mounted() {
    this.emitter.on('process-pushlocation', (status) => {
      // console.log('Push Location Progress', status)
      this.pushProgress(status)
    })
    await this.init()
  },
  emits: ['changed', 'saved'],
  computed: {
    ...mapState(locationStore, ['location', 'apps']),
    availableApps() {
      let _apps = []
      if (this.apps) {
        this.apps.forEach((element) => {
          _apps.push(element)
          // if (element.appNumber.includes('Calibrate')) {
          //   _apps.push(element)
          // } else if (!element.appNumber.includes('Calibrate')) {
          //   _apps.push(element)
          // }
        })
      }
      return _apps
    },
    isCalCopilot() {
      let _data = false
      if (this.location) {
        _data = this.location.isCalCopilot
      }
      return _data
    }
  },
  methods: {
    ...mapActions(locationStore, ['getLocationApps', 'saveLocationApps', 'pushLocation']),
    async init() {
      await this.getLocationApps({
        id: this.location.firm,
        dir: 2
      })
    },
    pushProgress(status) {
      this.isPushing = true
      let isComplete = this.getStatus(status, 'IsComplete')
      if (isComplete) {
        if (parseInt(isComplete) === 1) {
          console.log('Push Location Complete')
          this.isPushing = false
        }
      } else {
        let total = this.getStatus(status, 'TotalCount')
        let count = this.getStatus(status, 'Count')
        let duration = this.getStatus(status, 'TotalDuration')
        this.data = this.getStatus(status, 'Location')
        if (total !== null && count !== null) {
          this.progress = (parseInt(count) / parseInt(total)) * 100
        }
        if (duration) {
          if (duration > 3600) {
            this.duration = `${(duration / 3600).toFixed(1)} hours`
          } else if (duration > 60) {
            this.duration = `${(duration / 60).toFixed(1)} minutes`
          } else {
            this.duration = `${duration.toFixed(0)} seconds`
          }
        }
        if (count !== null) {
          this.total = `for ${count} of ${total} records.`
        }
        // console.log('Current Progress', {
        //   progress: this.progress,
        //   duration: duration,
        //   data: this.data,
        //   total: this.total
        // })
        if (this.progress >= 100) {
          this.isPushing = false
        }
      }
    },
    getStatus(status, item) {
      let value = null
      if (status) {
        let found = status.find((x) => x.attribute === item)
        if (found) {
          value = found.data ? found.data : found.value
        }
      }
      return value
    },
    showAppPush(app) {
      let show = false
      if (app.checked) {
        show = true
        // if (app.appNumber.includes('ADAS') || app.appNumber.includes('TestDrive')) {
        //   show = true
        // }
      }
      return show
    },
    changed() {
      this.$emit('changed')
    },
    async save() {
      let response = {
        valid: false,
        firm: this.location.firm,
        msg: null
      }
      await this.saveLocationApps()
      await this.init()
      response.valid = true
      this.$emit('saved')
      return response
    },
    async pushToApp(app) {
      this.isPushing = true
      this.progress = 0
      this.duration = null
      this.data = null
      this.total = null
      let data = {
        firm: parseInt(app.firm),
        firmType: 2,
        appAdas: app.appNumber.includes('ADAS') ? app.appNumber : null,
        appCalibrate: app.appNumber.includes('Calibrate') ? app.appNumber : null,
        appTestDrive: app.appNumber.includes('TestDrive') ? app.appNumber : null,
        isPushUsers: app.isPushUsers
      }
      this.pushingApp = app.appNumber
      console.log('Pushing App', {
        app: app,
        data: data
      })
      await this.pushLocation(data)
      this.isPushing = false
      setTimeout(() => {
        this.pushingApp = null
        this.progress = 0
        this.duration = null
        this.data = null
        this.total = null
      }, 3000)
    }
  }
}
</script>
<style lang="scss" scoped>
.custom-wrapper {
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
  max-height: 500px;
  height: 500px;
}
.custom-list {
  list-style: none;
  li {
    padding-top: 5px;
    padding-bottom: 5px;
  }
}
.custom-row {
  width: 100%;
  display: inline;
}
.custom-label {
  margin-left: 20px;
  span {
    font-weight: 500;
  }
}
.custom-action {
  button {
    margin-left: 20px;
    top: 0;
  }
}
.custom-stats {
  font-size: 0.9em;
}
</style>
