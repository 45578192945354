<template>
  <div class="row">
    <div class="col"></div>
    <div class="col-3 text-center">
      <h3>Launchpad</h3>
    </div>
    <div class="col"></div>
  </div>
  <div class="row mt-3">
    <div class="col"></div>
    <div class="col-3 text-center">
      <ul class="custom-actions">
        <li v-for="app of apps" :key="app.app">
          <button class="btn btn-primary btn-block" style="width: 100%" @click="launch(app)">{{ app.appName }}</button>
        </li>
      </ul>
    </div>
    <div class="col"></div>
  </div>
</template>
<script>
import { mapState, mapActions } from 'pinia'
import { authStore } from '@/stores/auth'
import { userStore } from '@/stores/user'
export default {
  name: 'LaunchPad',
  mounted() {
    this.init()
  },
  computed: {
    ...mapState(authStore, ['profile']),
    ...mapState(userStore, ['loading', 'user', 'userApps']),
    isShop() {
      let _is = false
      if (this.profile) {
        if ([17, 18, 24, 25].includes(this.profile.roles[0])) {
          _is = true
        }
      }
      return _is
    },
    isRemoteTech() {
      let _is = false
      if (this.profile) {
        if (this.profile.techId) {
          if (this.profile.techId.trim() !== '') {
            _is = true
          }
        }
      }
      return _is
    },
    apps() {
      let _data = []
      if (this.userApps) {
        console.log('User Apps', this.userApps)
        this.userApps.forEach((element) => {
          if (this.isShop) {
            if (element.appNumber !== 'Remote') {
              _data.push(element)
            }
          } else {
            _data.push(element)
          }
        })
      }
      return _data
    }
  },
  methods: {
    ...mapActions(userStore, ['getUserApps']),
    async init() {
      await this.getUserApps(this.profile.user)
    },
    launch(app) {
      console.log('Launching', app)
      // pull token from profile to pass along
      let token = null
      let url = 'http://localhost:8086' // for testing
      if (this.profile.appts.length > 0) {
        let found = this.profile.appts.find((x) => x.appNumber === app.appNumber)
        if (found) {
          if (found.token !== null) {
            if (found.token.indexOf('credentials') < 0) {
              token = found.token
            }
          }
        }
        if (app.appNumber.indexOf('ADAS') >= 0) {
          url = 'http://localhost:8084' // for testing
        }
      }
      console.log('Launching', {
        url: url,
        portalUrl: app.portalUrl,
        token: token
      })
      if (token) {
        // window.open(`${url}/login?t=${token}`) // for testing
        window.open(`${app.portalUrl}/login?t=${token}`)
      } else {
        window.open(`${app.portalUrl}/login`)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.custom-actions {
  list-style: none;
  margin: 0 auto;
  li {
    padding: 10px;
  }
}
</style>
