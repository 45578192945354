<template>
  <div id="app">
    <layout-broker :name="layout">
      <router-view />
    </layout-broker>
  </div>
</template>
<script>
import LayoutBroker from '@/layouts/Broker.vue'
export default {
  name: 'App',
  components: {
    LayoutBroker
  },
  mounted() {
    let apiUrl = window.config ? window.config.apiUrl : ''
    console.log('API URL', apiUrl)
  },
  computed: {
    layout() {
      return this.$route.meta && this.$route.meta.layout ? this.$route.meta.layout : 'no-nav'
    }
  },
  methods: {}
}
</script>
<style lang="scss"></style>
