<template>
  <div class="row custom-record">
    <div class="col-md-1">
      <input v-model="isChecked" type="checkbox" class="custom-checkbox" />
    </div>
    <div class="col-md-2 custom-data">
      <span>{{ recordData.phoneTypeName }}</span>
    </div>
    <div class="col-md-5 custom-data">
      <span>{{ recordData.phoneNo }}</span>
      <span v-if="recordData.extension !== ''"> x{{ recordData.extension }}</span>
    </div>
    <div class="col-md-2">
      <span v-show="recordData.isPrimary">(primary)</span>
    </div>
    <div class="col-md-1">
      <a href="#" @click="editItem">edit</a>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ContactPhoneRecord',
  props: {
    recordData: Object
  },
  emits: ['itemChecked', 'editItem'],
  computed: {
    isChecked: {
      get() {
        let _is = false
        if (this.recordData) {
          if (this.recordData.isChecked) {
            _is = true
          }
        }
        return _is
      },
      set(value) {
        this.$emit(
          'itemChecked',
          {
            contactPhone: this.recordData.contactPhone,
            contact: this.recordData.contact,
            phone: this.recordData.phone
          },
          value
        )
      }
    }
  },
  methods: {
    editItem() {
      this.$emit('editItem', this.recordData)
    }
  }
}
</script>
<style lang="scss" scoped>
.custom-record {
  padding: 5px;
}
.custom-data {
  text-align: left;
}
</style>
