<template>
  <div class="row">
    <div class="col-md-12">
      <span>{{ name }}</span>
    </div>
  </div>
</template>
<script>
export default {
  name: 'ContactSummary',
  props: {
    record: Object
  },
  computed: {
    name() {
      let _name = ''
      if (this.record) {
        _name = `for ${this.record.name}`
      }
      return _name
    }
  }
}
</script>
