<template>
  <div class="custom-list-wrapper">
    <div v-show="loading.emails || loading.phones || loading.addresses">
      <span>Loading...</span>
    </div>
    <div v-show="!loading.emails && !loading.phones && !loading.addresses" class="custom-list-header">
      <button class="btn btn-primary btn-sm" variant="outline-secondary" size="sm" @click="newAddress">Add</button>
      <button v-show="isShowDelete" class="btn btn-primary btn-sm ms-2" variant="outline-secondary" size="sm" @click="confirmDelete">Delete</button>
      <button v-show="isShowPrimary" class="btn btn-primary btn-sm ms-2" variant="outline-secondary" size="sm" @click="primaryAddress">
        Set as Primary
      </button>
      <span v-show="showTitle" class="custom-title">Addresses</span>
    </div>
    <div v-show="!loading.addresses" class="custom-list">
      <div v-for="(item, idx) of addresses" :key="idx">
        <contact-address-record :record-data="item" @itemChecked="itemChecked" @editItem="editAddress" @viewItem="viewAddress" />
      </div>
    </div>
  </div>
  <dialog-address v-model="actionAddress" :address="addressRecord" @closing="closeModal" @send="sendAddress" />
  <confirm-delete
    v-model="actionDelete"
    :confirm-title="confirmDeleteTitle"
    :confirm-action="confirmDeleteAction"
    :confirm-question="confirmDeleteQuestion"
    @closing="closeModal"
    @proceed="performDelete"
  />
</template>
<script>
import { mapState, mapActions } from 'pinia'
import { contactStore } from '@/stores/contact'
import DialogAddress from '@/components/contact/forms/DialogAddress.vue'
import ContactAddressRecord from '@/components/contact/listings/ContactAddressRecord.vue'
import ConfirmDelete from '@/components/utilities/elements/ConfirmDialog.vue'
export default {
  name: 'ContactAddresses',
  components: {
    DialogAddress,
    ContactAddressRecord,
    ConfirmDelete
  },
  props: {
    record: {
      type: Object
    },
    showTitle: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      confirmDeleteTitle: 'Confirm Address Deletion',
      confirmDeleteAction: 'Delete',
      confirmDeleteQuestion: 'Are you sure you wish to Permanently Delete checked addresses?',
      actionAddress: null,
      actionDelete: null,
      isShowDelete: false,
      isShowPrimary: false,
      recordChecked: null,
      addressRecord: undefined,
      actionAddressMap: null
    }
  },
  computed: {
    ...mapState(contactStore, ['loading', 'addresses', 'address'])
  },
  methods: {
    ...mapActions(contactStore, [
      'getAddresses',
      'resetAddress',
      'assignAddress',
      'setCheckedAddress',
      'saveAddress',
      'deleteAddress',
      'setPrimaryAddress'
    ]),
    closeModal() {
      this.actionAddress = null
      this.actionDelete = null
      this.addressRecord = undefined
      this.recordChecked = null
      this.actionAddressMap = null
    },
    itemChecked(value, checked) {
      this.isShowDelete = checked
      this.isShowPrimary = checked
      this.recordChecked = null
      if (checked) {
        this.recordChecked = value
      }
      this.setCheckedAddress({
        contactAddress: value.contactAddress,
        isChecked: checked
      })
    },
    newAddress() {
      this.resetAddress({
        firm: this.record.firm,
        contact: this.record.contact,
        dir: this.record.user ? 'user' : 'firm'
      })
      this.addressRecord = this.address
      this.actionAddress = this.record
    },
    editAddress(data) {
      this.assignAddress(data)
      this.addressRecord = data
      this.actionAddress = this.record
    },
    viewAddress(data) {
      this.addressRecord = data
      this.actionAddressMap = this.record
    },
    confirmDelete() {
      this.actionDelete = true
    },
    async performDelete() {
      this.actionDelete = false
      this.isShowDelete = false
      this.isShowPrimary = false
      this.recordChecked = null
      let addresses = []
      for (let address of this.addresses) {
        if (address.isChecked) {
          addresses.push({
            contactAddress: address.contactAddress,
            contact: this.record.contact
          })
        }
      }
      for (let address of addresses) {
        await this.deleteAddress(address)
      }
      await this.getAddresses(this.contact.contact)
    },
    async primaryAddress() {
      let data = {
        id: this.recordChecked.contactAddress,
        contact: this.record.contact
      }
      await this.setPrimaryAddress(data)
      this.isShowDelete = false
      this.isShowPrimary = false
      this.recordChecked = null
      await this.getAddresses(this.record.contact)
    },
    async sendAddress() {
      this.actionAddress = null
      this.addressRecord = undefined
      if (this.address !== null) {
        await this.saveAddress(this.address)
        await this.getAddresses(this.record.contact)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.custom-list-wrapper {
  background-color: #ffffff;
  max-height: 210px;
  height: 210px;
  padding: 2px;
}
.custom-list-header {
  text-align: left;
  padding: 5px;
  border-bottom: 1px solid rgb(144, 144, 144);
  margin-bottom: 5px;
}
.custom-list {
  overflow-y: scroll;
  overflow-x: hidden;
  padding: 5px;
  margin-left: 5px;
  margin-right: 5px;
  height: 155px;
}
.custom-title {
  float: right;
  position: relative;
}
</style>
