<template>
  <table class="min-w-full">
    <thead v-if="tableColumns && tableColumns.length && !isNoHeader" class="bg-white" :class="{ 'table-sticky': isHeaderFixed }">
      <tr>
        <!-- ***************************** -->
        <!-- empty detail placeholder cell -->
        <!-- ***************************** -->
        <th v-if="$slots.detail" />
        <template v-for="(column, cIndex) of tableColumns" :key="cIndex">
          <th v-if="column.isCheckAll" class="z-column-60-center custom-checkall">
            <input v-model="checkedAll" type="checkbox" class="z-checkbox-small-centered" />
          </th>
          <th
            v-else
            v-show="!column.hidden"
            class="border-b-2 border-gray-300 text-left text-sm leading-4 text-blue-600 tracking-wider"
            :class="[{ cursor: column.sortable }, column.class]"
            :data-attribute="column.attribute"
            @click="sort(column, cIndex)"
          >
            <!-- ***************************** -->
            <!-- thead scoped slot -->
            <!-- ***************************** -->
            <slot v-if="$slots.thead" name="thead" :column="column" :index="cIndex" />

            <!-- ***************************** -->
            <!-- default column -->
            <!-- ***************************** -->
            <template v-else>
              <div v-if="column.html !== undefined" :style="column.style" v-html="column.html"></div>
              <span v-else class="column-header" :title="column.title">
                {{ column.label }}
              </span>
            </template>

            <!-- ***************************** -->
            <!-- sort icon -->
            <!-- ***************************** -->

            <div v-if="column.sortable" class="fa-stack fa-1x custom-stack">
              <template v-if="column.sortable">
                <template v-if="column.field === currentSortBy">
                  <div v-show="currentOrderBy === 'asc'">
                    <i class="sort-base fa-solid fa-sort fa-stack-1x"></i>
                    <i class="text-dark fa-solid fa-sort-up fa-stack-1x"></i>
                  </div>
                  <div v-show="currentOrderBy === 'desc'">
                    <i class="sort-base fa-solid fa-sort fa-stack-1x"></i>
                    <i class="text-dark fa-solid fa-sort-down fa-stack-1x"></i>
                  </div>
                </template>
                <template v-else>
                  <div>
                    <i class="sort-base fa-solid fa-sort fa-stack-1x"></i>
                  </div>
                </template>
              </template>
            </div>
          </th>
        </template>
      </tr>
    </thead>
    <tbody v-if="tableData === undefined || tableData === null">
      <tr v-if="loading">
        <td colspan="999" class="table-row-center">
          <span><i class="fa-solid fa-spinner fa-spin"></i> Loading...</span>
        </td>
      </tr>
    </tbody>
    <tbody v-else-if="tableData.length === 0">
      <tr v-if="loading">
        <td colspan="999" class="table-row-center">
          <span><i class="fa-solid fa-spinner fa-spin"></i> Loading...</span>
        </td>
      </tr>
    </tbody>
    <tbody v-else-if="tableData && tableData.length" class="bg-white divide-y divide-gray-200">
      <!-- ***************************** -->
      <!-- loading row -->
      <!-- ***************************** -->
      <tr v-if="loading">
        <td colspan="999" class="table-row-center">
          <span><i class="fa-solid fa-spinner fa-spin"></i> Loading...</span>
        </td>
      </tr>

      <template v-for="(row, rIndex) in tableData" :key="rIndex + 1">
        <tr @click="rowClicked(row)">
          <!-- ***************************** -->
          <!-- detail show hide buttons -->
          <!-- ***************************** -->
          <template v-if="$slots.detail">
            <td class="cursor text-center" :class="detailColumnClass" @click="toggleDetail(row)">
              <div v-show="!row.isDetailVisible">
                <i class="fa-solid fa-chevron-up" aria-hidden="true"></i>
              </div>
              <div v-show="row.isDetailVisible">
                <i class="fa-solid fa-chevron-down" aria-hidden="true"></i>
              </div>
            </td>
          </template>

          <!-- ***************************** -->
          <!-- tbody scoped slot -->
          <!-- ***************************** -->
          <slot v-if="$slots.tbody" name="tbody" :row="row" />

          <!-- ***************************** -->
          <!-- tbody if no slot but columns  -->
          <!-- ***************************** -->
          <template v-for="(column, cIndex) of tableColumns" v-else-if="tableColumns.length" :key="cIndex">
            <td v-show="!column.hidden">{{ row[column.field] }}</td>
          </template>

          <!-- ***************************** -->
          <!-- tbody default -->
          <!-- ***************************** -->
          <template v-else>
            <td>{{ row }}</td>
          </template>
        </tr>

        <!-- ***************************** -->
        <!-- detail scoped slot -->
        <!-- ***************************** -->
        <tr v-if="$slots.detail && row.isDetailVisible" :key="rIndex * -1">
          <td colspan="999" :class="detailClass">
            <slot name="detail" :row="row" />
          </td>
        </tr>
      </template>
    </tbody>
    <!-- ***************************** -->
    <!-- empty slot -->
    <!-- ***************************** -->
    <tbody v-else>
      <tr>
        <td colspan="999">
          <slot v-if="$slots.empty" name="empty" />
        </td>
      </tr>
    </tbody>

    <!-- ***************************** -->
    <!-- tfoot slot -->
    <!-- ***************************** -->
    <tfoot v-if="$slots.tfoot">
      <tr>
        <td colspan="999">
          <slot name="tfoot" />
        </td>
      </tr>
    </tfoot>
  </table>
</template>

<script>
export default {
  name: 'ZTable',
  props: {
    sortBy: String,
    orderBy: String,
    loading: Boolean,
    isSingleRowDetail: {
      type: Boolean,
      default: false
    },
    isShowSelector: {
      type: Boolean,
      default: false
    },
    modelValue: {
      type: Array,
      default: () => []
    },
    columns: {
      type: Array,
      default: () => []
    },
    detailColumnClass: String,
    detailClass: String,
    isAllChecked: {
      type: Boolean,
      default: false
    },
    isNoHeader: {
      type: Boolean,
      default: false
    },
    isHeaderFixed: {
      type: Boolean,
      default: false
    }
  },
  emits: ['sort', 'click', 'toggle', 'checkedAll'],
  data() {
    return {
      currentSortBy: this.sortBy,
      currentOrderBy: this.orderBy || 'desc'
    }
  },
  computed: {
    tableData() {
      return this.modelValue
    },
    tableColumns() {
      return this.columns
    },
    checkedAll: {
      get() {
        return this.isAllChecked
      },
      set(value) {
        this.$emit('checkedAll', value)
      }
    }
  },
  created() {
    const sortable = this.tableColumns.filter((i) => i.sortable)
    if (sortable.length && !this.currentSortBy) {
      this.currentSortBy = sortable[0].field
    }
  },
  methods: {
    sort(column) {
      if (column.sortable) {
        this.currentOrderBy = column.field === this.currentSortBy ? (this.currentOrderBy === 'asc' ? 'desc' : 'asc') : 'asc'
        this.currentSortBy = column.field
        this.$emit('sort', column, this.currentOrderBy)
      }
    },
    rowClicked(row) {
      this.$emit('click', row)
    },
    toggleDetail(row) {
      if (this.isSingleRowDetail) {
        this.tableData.forEach((element) => {
          if (element !== row) {
            element.isDetailVisible = false
          }
        })
      }
      row.isDetailVisible = !row.isDetailVisible
      this.$forceUpdate()
      this.$emit('toggle', row.isDetailVisible, row)
    }
  }
}
</script>

<style scoped>
.column-header {
  height: 2em;
  line-height: 2em;
  position: relative;
}
.cursor {
  cursor: pointer;
}
.sort-base {
  color: #ddd;
}
.row-highlight {
  cursor: pointer;
}
.table-row-center {
  text-align: center;
}
.custom-checkall {
  vertical-align: middle;
}
.custom-stack {
  display: inline-flex !important;
}
</style>
