<template>
  <section class="contactWrapper">
    <div v-if="stacked" class="">
      <contact-addresses :record="record" :show-title="showTitles" />
      <contact-phones :record="record" :show-title="showTitles" />
      <contact-emails :record="record" :show-title="showTitles" />
    </div>
    <!-- Contact Record Data -->
    <div v-else class="row custom-record-row">
      <div class="col-md-4 custom-record-panel">
        <contact-addresses :record="record" :show-title="showTitles" />
      </div>
      <div class="col-md-4 custom-record-panel">
        <contact-phones :record="record" :show-title="showTitles" />
      </div>
      <div class="col-md-4 custom-record-panel">
        <contact-emails :record="record" :show-title="showTitles" />
      </div>
    </div>
  </section>
</template>
<script>
import { mapActions } from 'pinia'
import { contactStore } from '@/stores/contact'
import { lookupStore } from '@/stores/lookup'
import ContactAddresses from '@/components/contact/listings/ContactAddresses.vue'
import ContactPhones from '@/components/contact/listings/ContactPhones.vue'
import ContactEmails from '@/components/contact/listings/ContactEmails.vue'
export default {
  name: 'ContactInfo',
  components: {
    ContactAddresses,
    ContactPhones,
    ContactEmails
  },
  props: {
    record: Object,
    viewing: String,
    stacked: {
      type: Boolean,
      default: false
    },
    showTitles: {
      type: Boolean,
      default: true
    }
  },
  data() {
    return {
      selectedId: 0,
      isShow: false
    }
  },
  async mounted() {
    await this.getLookups({
      keys: ['AddressType', 'PhoneType', 'EmailType', 'StateProvince', 'Country'],
      firm: 1,
      refresh: true
    })
    console.log('Contact Info Record', this.record)
    await this.getContactAddresses()
    await this.getContactPhones()
    await this.getContactEmails()
  },
  methods: {
    ...mapActions(lookupStore, ['getLookups']),
    ...mapActions(contactStore, ['getAddresses', 'getPhones', 'getEmails']),
    async getContactAddresses() {
      if (this.record) {
        console.log('Getting Addresses', this.record)
        await this.getAddresses(this.record.contact)
      }
    },
    async getContactPhones() {
      if (this.record) {
        console.log('Getting Phones', this.record)
        await this.getPhones(this.record.contact)
      }
    },
    async getContactEmails() {
      if (this.record) {
        console.log('Getting Emails', this.record)
        await this.getEmails(this.record.contact)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.contactWrapper {
  cursor: pointer;
  position: relative;
  width: 100%;
}
// .contactWrapper:hover {
//   background-color: #c1caff;
// }
.contactNumber {
  font-size: 1.2em;
  font-weight: 700;
}
.contactLabel {
  font-size: 1em;
  font-weight: 600;
}
.contactData {
  font-size: 1em;
  font-weight: 400;
}
.actionWrapper {
  position: absolute;
  bottom: 0;
  width: 100%;
}
.custom-record-row {
  margin-left: 0;
  margin-right: 0;
}
.custom-record-panel {
  padding: 5px;
  text-align: center;
}
</style>
