import { defineStore } from 'pinia'
import { api } from '@/plugins/api'
import moment from 'moment'
const adasLinkDefault = {
  adasLinks: null,
  adasLink: null,
  adasChecklistMatrix: null,
  adasChecklistMatrixName: null,
  adasChecklistMatrixVehicle: null,
  vehicleMake: null,
  model: null,
  yearStart: 2017,
  yearEnd: parseInt(moment().add(2, 'y').format('YYYY')),
  systemType: null,
  locationType: null,
  calibrationType: null,
  lookupType: null,
  triggerNotice: null,
  triggerNoticeText: null,
  triggers: [],
  cccKeywords: []
}
const linksDefault = {
  _embedded: {
    data: {
      GEN3NA: {
        _embedded: {
          data: {
            dataSource: {
              name: 'OPUS IVS'
            },
            results: []
          }
        }
      }
    }
  }
}
const linkDefault = {
  groupCategory: {
    id: null,
    name: null
  },
  results: []
}

export const adasStore = defineStore('adas', {
  /* Current State */
  state: () => ({
    adasLinks: {
      count: 0,
      items: []
    },
    adasLink: JSON.parse(JSON.stringify(adasLinkDefault)),
    loading: {
      adasLink: false,
      adasLinks: false
    },
    listParams: {
      page: 1,
      pageSize: 10,
      filterOptions: null,
      filterBy: 1,
      sortBy: 'asc',
      orderBy: 'adasLink',
      searchTerm: '',
      year: null,
      lookup: null
    },
    csvData: null,
    systems: [],
    locations: [],
    links: [],
    newLink: {
      main: JSON.parse(JSON.stringify(linksDefault)),
      link: JSON.parse(JSON.stringify(linkDefault))
    }
  }),
  actions: {
    resetAdasLink() {
      this.adasLink = JSON.parse(JSON.stringify(adasLinkDefault))
    },
    setAdasLink(data) {
      if (data.name === 'cccKeywordAdd') {
        if (data.value) {
          if (data.value.trim() !== '') {
            this.adasLink.cccKeywords.push(data.value)
          }
        }
      } else if (data.name === 'cccKeywordRemove') {
        this.adasLink.cccKeywords = this.adasLink.cccKeywords.filter((el) => {
          return el !== data.value
        })
      } else {
        this.adasLink[data.name] = data.value
      }
      // console.log('Updated ADAS Link', this.adasLink)
    },
    setLink(data) {
      if (data.name === 'linkAdd') {
        this.links.push({
          id: parseInt(data.value.id),
          title: data.value.title,
          url: data.value.url
        })
      } else if (data.name === 'linkRemove') {
        this.links = this.links.filter((el) => {
          return parseInt(el.id) !== parseInt(data.value.id)
        })
      }
      this.processLink()
    },
    processLink() {
      let newLinks = JSON.parse(JSON.stringify(linksDefault))
      let newLink = JSON.parse(JSON.stringify(linkDefault))
      if (this.adasLink.carid !== null) {
        newLinks._embedded.data.GEN3NA._embedded.data.dataSource.name = 'gen3NAData'
      }
      newLink.groupCategory.id = this.adasLink.adasChecklistMatrixVehicle
      newLink.groupCategory.name = this.adasLink.adasChecklistMatrixName
      this.links.forEach((link) => {
        newLink.results.push({
          display: link.title,
          isOpus: true,
          _links: {
            self: {
              href: link.url
            }
          }
        })
      })
      newLinks._embedded.data.GEN3NA._embedded.data.results.push(newLink)
      // console.log('Processed Links', newLinks)
      this.adasLink.links = JSON.stringify(newLinks)
    },
    setListParam(data) {
      this.listParams[data.name] = data.value
    },
    setLoading(data) {
      this.loading[data.name] = data.value
    },
    setCsvData(data) {
      this.csvData = data
    },
    async getAdasLinks(options) {
      this.adasLinks = {
        count: 0,
        items: []
      }
      this.loading.adasLinks = true
      this.adasLinks = await api.get('data/getLinksManage', options)
      this.loading.adasLinks = false
      if (this.adasLinks) {
        if (this.adasLinks.items) {
          this.adasLinks.items.forEach((element) => {
            element.isChecked = false
          })
        }
      }
      console.log('Got ADAS Links', this.adasLinks)
      return this.adasLinks
    },
    async getAdasLink(id) {
      this.resetAdasLink()
      this.loading.adasLink = true
      this.adasLink = await api.get(`data/getLinkManage?adasLinks=${id}`)
      this.loading.adasLink = false
      if (this.adasLink) {
        if (this.adasLink.cccKeywords === undefined || this.adasLink.cccKeywords === null) {
          this.adasLink.cccKeywords = []
        } else {
          this.adasLink.cccKeywords = this.adasLink.cccKeywords.split('|')
        }
        if (this.adasLink.triggers === undefined || this.adasLink.triggers === null) {
          this.adasLink.triggers = []
        } else {
          this.adasLink.triggers = this.adasLink.triggers.split(',').map((d) => {
            return parseInt(d)
          })
        }
        if (this.adasLink.links) {
          let cnt = 1
          let parsed = JSON.parse(this.adasLink.links)
          this.links = []
          if (parsed._embedded.data.GEN3NA._embedded.data) {
            if (parsed._embedded.data.GEN3NA._embedded.data.results) {
              parsed._embedded.data.GEN3NA._embedded.data.results.forEach((element) => {
                element.results.forEach((link) => {
                  this.links.push({
                    id: cnt,
                    title: link.display,
                    url: link._links.self.href
                  })
                })
              })
            }
          }
        }
      }
      console.log('Got ADAS Link', this.adasLink)
      return this.adasLink
    },
    async saveAdasLink() {
      let data = JSON.parse(JSON.stringify(this.adasLink))
      data.triggers = data.triggers.toString()
      data.cccKeywords = data.cccKeywords.join('|')
      console.log('Save ADAS Link', data)
      return await api.post('data/saveLink', data)
    },
    async saveLinksByVehicle(data) {
      return await api.post('data/saveLinksByVehicle', data)
    },
    async saveLinks(data) {
      return await api.post('data/saveLinks', data)
    },
    async importMatrix(data) {
      return await api.post('data/adasImportMatrix', data)
    }
  }
})
