<template>
  <div class="row">
    <div class="col-3">
      <!-- Title and Search -->
      <div class="row">
        <div class="col">
          <h4>Locations</h4>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <div class="input-group">
            <button type="button" class="btn btn-primary btn-sm" @click="refreshLocations">
              <i class="fas fa-rotate"></i>
            </button>
            <input v-model="searchText" type="text" class="form-control" placeholder="search for a location" @keyup.enter="searchLocations" />
            <button type="button" class="btn btn-primary btn-sm" @click="searchLocations">
              <i class="fas fa-search"></i>
            </button>
          </div>
        </div>
      </div>
    </div>
    <div class="col">
      <div class="row">
        <div v-if="isImportExport" class="col text-right">
          <button v-if="isAdmin && isSelected" class="btn btn-primary btn-sm" @click="importLocations">Import Locations</button>
          <button v-if="isAdmin && isSelected" class="btn btn-secondary btn-sm ms-2" @click="exportLocations">Export Locations</button>
          <button v-if="isAdmin && isSelected" class="btn btn-primary btn-sm ms-2" @click="importUsers">Import Users</button>
          <button v-if="isAdmin && isSelected" class="btn btn-secondary btn-sm ms-2" @click="exportUsers">Export Users</button>
        </div>
        <div v-else class="col"></div>
      </div>
      <!-- Action Bar -->
      <div class="row mt-2">
        <div class="col">
          <div class="col">
            <h4 class="d-inline custom-title">
              {{ title }}
            </h4>
            <span class="d-inline custom-subtitle">{{ subtitle }}</span>
          </div>
        </div>
        <div v-if="isSelected" class="col-4">
          <button class="btn btn-primary ms-3" :class="{ 'btn-danger': isSave }" @click="save">Save</button>
          <button v-if="!isShop" class="btn btn-primary btn-sm ms-4" @click="addNewLocation">Add Location</button>
          <span v-if="isTopmost" class="custom-add-location">only 'add' at this level if this will be a 'White Label' partner</span>
        </div>
      </div>
    </div>
  </div>
  <div class="row">
    <div class="col-3">
      <!-- Location Spinner (while loading up the Location Profile) -->
      <div v-if="loading.locationTree" class="loadingWrapper">
        <div class="loadingSpinner">
          <i class="fas fa-spinner fa-spin" aria-hidden="true"></i>
          <span>loading...</span>
        </div>
      </div>
      <!-- Location Tree (on the left) -->
      <div v-else class="custom-locations-wrapper">
        <div class="row">
          <div class="col">
            <tree-node
              id="LocationTree"
              :tree="locationTree"
              :depth="0"
              :show-type="false"
              :is-open="true"
              :select-function="selectLocation"
              :class-function="getClass"
            />
          </div>
        </div>
        <div class="row">
          <div class="col">
            <input v-model="showInactive" type="checkbox" class="custom-checkbox ms-4 me-2 mt-3" @change="refreshLocations" />
            <span>show inactive</span>
          </div>
        </div>
      </div>
    </div>
    <!-- Location Details (on the right) -->
    <div class="col">
      <location-host v-if="isSelected" ref="host" :firm="selectedFirm" @save="isSave = true" @saved="savedLocation" @moved="movedLocation" />
    </div>
  </div>
  <!-- Dialogs -->
  <dialog-add-location v-model="actionLocation" @closing="closeModal" @firmSaved="firmSaved" @saved="savedLocation" />
  <dialog-import v-model="actionImport" @closing="closeModal" @saved="saved" />
</template>
<script>
import { mapState, mapActions } from 'pinia'
import { authStore } from '@/stores/auth'
import { lookupStore } from '@/stores/lookup'
import { locationStore } from '@/stores/location'
import { dataStore } from '@/stores/data'
import { Parser } from '@json2csv/plainjs'
import TreeNode from '@/components/utilities/elements/TreeNode.vue'
import LocationHost from '@/components/locations/forms/LocationHost.vue'
import DialogAddLocation from '@/components/locations/forms/DialogAddLocation.vue'
import DialogImport from '@/components/configuration/data/DialogImport.vue'
export default {
  name: 'LocationTree',
  components: {
    TreeNode,
    LocationHost,
    DialogAddLocation,
    DialogImport
  },
  data() {
    return {
      actionLocation: null,
      selectedFirm: null,
      isSelected: false,
      searchText: null,
      isSave: false,
      searchedTree: [],
      actionImport: null,
      isScroll: false,
      showInactive: false
    }
  },
  async mounted() {
    this.isSelected = false
    this.selectedFirm = null
    this.showInactive = false
    await this.getLookups({
      keys: ['Language', 'TimeZone', 'FirmType'],
      firm: 1,
      refresh: false
    })
    this.getLocationTree({
      firm: this.profile.firm,
      showInactive: this.showInactive
    })
  },
  computed: {
    ...mapState(authStore, ['profile']),
    ...mapState(locationStore, ['loading', 'location', 'addLocation', 'locationTree']),
    ...mapState(dataStore, ['loading', 'locations', 'users']),
    title() {
      let _title = ''
      if (this.selectedFirm) {
        _title = this.selectedFirm.name
      }
      return _title
    },
    subtitle() {
      let _subtitle = ''
      if (this.selectedFirm) {
        _subtitle = this.selectedFirm.type
      }
      return _subtitle
    },
    isAdmin() {
      let _is = false
      if ([1, 2, 3, 4, 7].includes(this.profile.roles[0])) {
        _is = true
      }
      return _is
    },
    isCalCopilot() {
      let _data = false
      if (this.location) {
        _data = this.location.isCalCopilot
      }
      return _data
    },
    isShop() {
      let _is = false
      if (this.selectedFirm) {
        if (this.selectedFirm.type === 'Shop') {
          _is = true
        }
      }
      return _is
    },
    isImportExport() {
      let _is = false
      if (this.selectedFirm) {
        if (this.selectedFirm.type === 'Corporate') {
          _is = true
        }
      }
      return _is
    },
    isTopmost() {
      let _is = false
      if (this.selectedFirm) {
        if (this.selectedFirm.firm === 1) {
          _is = true
        }
      }
      return _is
    }
  },
  methods: {
    ...mapActions(lookupStore, ['getLookups']),
    ...mapActions(locationStore, ['getLocationTree', 'getLocation', 'resetAddLocation', 'setAddLocation', 'setLocation', 'deleteLocation']),
    ...mapActions(dataStore, ['getLocationsForExport', 'getUsersForExport']),
    closeModal() {
      this.actionLocation = null
      this.actionImport = null
    },
    async refreshLocations() {
      this.isSelected = false
      this.searchText = null
      await this.getLocationTree({
        firm: this.profile.firm,
        showInactive: this.showInactive
      })
    },
    firmSaved(data) {
      console.log('Firm Saved', data)
    },
    importLocations() {
      this.actionImport = {
        title: 'Import Locations',
        parentBranch: this.selectedFirm.firm
      }
    },
    importUsers() {
      this.actionImport = {
        title: 'Import Users'
      }
    },
    async exportLocations() {
      try {
        await this.getLocationsForExport(this.selectedFirm.firm)
        if (this.locations) {
          const parser = new Parser()
          const csv = parser.parse(this.locations)
          console.log(csv)
          // download the file as CSV
          var downloadLink = document.createElement('a')
          var blob = new Blob(['\ufeff', csv])
          var url = URL.createObjectURL(blob)
          downloadLink.href = url
          downloadLink.download = `Locations for ${this.selectedFirm.name}.csv`
          document.body.appendChild(downloadLink)
          downloadLink.click()
          document.body.removeChild(downloadLink)
        }
      } catch (err) {
        console.error(err)
      }
    },
    async exportUsers() {
      try {
        await this.getUsersForExport(this.selectedFirm.firm)
        const parser = new Parser()
        const csv = parser.parse(this.users)
        console.log(csv)
        // download the file as CSV
        var downloadLink = document.createElement('a')
        var blob = new Blob(['\ufeff', csv])
        var url = URL.createObjectURL(blob)
        downloadLink.href = url
        downloadLink.download = `Users for ${this.selectedFirm.name}.csv`
        document.body.appendChild(downloadLink)
        downloadLink.click()
        document.body.removeChild(downloadLink)
      } catch (err) {
        console.error(err)
      }
    },
    saved() {
      this.actionImport = null
      this.refreshLocations()
    },
    async addNewLocation() {
      this.resetAddLocation()
      this.setAddLocation({
        name: 'parent',
        value: this.selectedFirm.firm
      })
      let firmType = 2
      let firmTypeName = ''
      switch (this.selectedFirm.type) {
        case 'Corporate':
          firmType = 3
          firmTypeName = 'Division'
          break
        case 'Division':
          firmType = 4
          firmTypeName = 'Distributor'
          break
        case 'Distributor':
          firmType = 5
          firmTypeName = 'Partner'
          break
        case 'Partner':
          firmType = 6
          firmTypeName = 'Center'
          break
        case 'Center':
          firmType = 7
          firmTypeName = 'Shop'
          break
        default:
          break
      }
      this.setAddLocation({
        name: 'firmType',
        value: firmType
      })
      console.log('Add Location - Current Location', this.addLocation)
      this.actionLocation = {
        isAdd: true,
        parentFirm: this.selectedFirm.name,
        firmType: firmTypeName
      }
    },
    async movedLocation() {
      await this.refreshLocations()
      this.searchLocations()
    },
    async savedLocation(isChanged) {
      this.isSave = false
      if (isChanged) {
        this.searchText = null
        this.actionLocation = null
        await this.getLocationTree({
          firm: this.profile.firm,
          showInactive: this.showInactive
        })
        this.searchLocations()
      }
    },
    save() {
      this.$refs.host.save()
    },
    async selectLocation(tree) {
      // select our location and get
      // the location information
      await this.getLocation(tree.id)
      console.log('Selecting Firm', tree)
      this.selectedFirm = {
        parent: tree.parent,
        id: tree.id,
        firm: tree.id,
        name: tree.name,
        type: tree.type,
        contact: tree.contact
      }
      this.isSelected = true
      this.setSelection(tree.id)
      this.isSave = false
      if (this.$refs.host) {
        this.$refs.host.init()
      }
    },
    setSelection(id) {
      // highlight the node in the tree
      var x = document.getElementsByClassName('tree-node-selection')
      if (x !== undefined && x !== null) {
        for (var i = 0; i < x.length; i++) {
          x[i].classList.remove('tree-node-selection')
        }
        x = document.getElementById(id)
        x.classList.add('tree-node-selection')
        if (this.isScroll) {
          x.scrollIntoView()
        }
      }
    },
    resetFound() {
      var x = document.getElementsByClassName('tree-node-found')
      if (x !== undefined && x !== null) {
        for (var i = 0; i < x.length; i++) {
          x[i].classList.remove('tree-node-found')
        }
      }
    },
    setFound(id) {
      var x = document.getElementById(id)
      if (x) {
        x.classList.add('tree-node-found')
      }
    },
    closeTreeNodes(node, rootPath) {
      // loop through the tree and set
      // the isOpen flag to false, closing
      // all nodes
      rootPath.push(node)
      node.isOpen = false
      if (node.members) {
        if (node.members.length > 0) {
          node.members.forEach((element) => {
            this.closeTreeNodes(element, rootPath)
          })
        }
      }
      rootPath.pop()
    },
    openTreeNode(id, node, rootPath) {
      // loop through the tree and set
      // the isOpen flag to open up the
      // appropriate nodes
      rootPath.push(node)
      if (node.id === id) {
        node.isOpen = true
      }
      if (node.members) {
        if (node.members.length > 0) {
          node.members.forEach((element) => {
            this.openTreeNode(id, element, rootPath)
          })
        }
      }
      rootPath.pop()
    },
    traverseTree(search, node, rootPath) {
      // this is an iterative tree search to find our
      // location based upon name
      rootPath.push(node)
      if (node.name.includes(search)) {
        rootPath.forEach((element) => {
          this.searchedTree.push({
            id: element.id,
            name: element.name,
            type: element.type
          })
        })
      }
      if (node.members) {
        if (node.members.length > 0) {
          node.members.forEach((element) => {
            this.traverseTree(search, element, rootPath)
          })
        }
      }
      rootPath.pop()
    },
    traverseTreeById(id, node, rootPath) {
      // this is an iterative tree search to find our
      // location based upon name
      rootPath.push(node)
      if (parseInt(node.id) === parseInt(id)) {
        rootPath.forEach((element) => {
          this.searchedTree.push({
            id: element.id,
            name: element.name,
            type: element.type
          })
        })
      }
      if (node.members) {
        if (node.members.length > 0) {
          node.members.forEach((element) => {
            this.traverseTreeById(id, element, rootPath)
          })
        }
      }
      rootPath.pop()
    },
    async searchLocations() {
      let path = []
      // close all nodes first
      this.closeTreeNodes(this.locationTree, path)
      this.resetFound()
      path = []
      this.searchedTree = []
      // traverse the tree looking for location
      if (this.searchText) {
        this.traverseTree(this.searchText, this.locationTree, path)
      } else {
        this.traverseTreeById(this.selectedFirm.id, this.locationTree, path)
      }
      // if found, open up the tree accordingly
      if (this.searchedTree.length > 0) {
        path = []
        for (let idx = 0; idx < this.searchedTree.length; idx++) {
          const element = this.searchedTree[idx]
          this.openTreeNode(element.id, this.locationTree, path)
          this.$forceUpdate()
          // and select it...
          if (idx === this.searchedTree.length - 1) {
            this.isScroll = true
            await this.selectLocation(element)
            this.isScroll = false
          }
        }
        if (this.searchText) {
          for (let idx = 0; idx < this.searchedTree.length; idx++) {
            const element = this.searchedTree[idx]
            // indicate found
            if (element.name.includes(this.searchText)) {
              this.setFound(element.id)
            }
          }
        }
      }
    },
    getClass(type) {
      let result = null
      switch (type) {
        case 'Admin':
          result = 'custom-dark-green'
          break
        case 'Corporate':
          result = 'custom-orange'
          break
        case 'Division':
          result = 'custom-blue-italic'
          break
        case 'Distributor':
          result = 'custom-green'
          break
        case 'Partner':
          result = 'custom-black-italic'
          break
        case 'Center':
          result = 'custom-green-italic'
          break
        default:
          break
      }
      return result
    }
  }
}
</script>
<style lang="scss" scoped>
.custom-blank {
  height: 30px;
}
.custom-locations-wrapper {
  max-height: 600px;
  overflow-y: auto;
  overflow-x: hidden;
}
.loadingWrapper {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.2);
  z-index: 10;
}
.loadingSpinner {
  margin-top: 40px;
  text-align: center;
  font-weight: 700;
  width: 100%;
  background: rgba(255, 255, 255, 0.6);
  padding: 10px;
  span {
    margin-left: 5px;
  }
}
.tree-node-selection {
  background-color: rgb(255, 255, 187);
}
.tree-node-wrapper {
  border-bottom: 1px solid #b8b8b8;
  padding-top: 10px;
  padding-bottom: 10px;
  cursor: pointer;
}
.tree-node {
  .label-wrapper {
    padding-bottom: 10px;
    margin-bottom: 10px;
    border-bottom: 1px solid #ccc;
    .has-children {
      cursor: pointer;
    }
  }
}
.TeamTree {
  max-height: 500px;
  overflow: auto;
  overflow-x: hidden;
  margin-top: 15px;
}
.tree-link {
  width: 100%;
  a {
    display: inline-block;
    width: 100%;
  }
}
.tree-link:hover {
  color: white;
  background: #2f9af7;
}
.tree-node-remove {
  text-align: center;
  color: #b30505;
}
.titleRight {
  position: absolute;
  right: 10px;
  top: 10px;
  font-weight: 500;
}
.custom-actions {
  float: right;
}
.custom-subtitle {
  font-style: italic;
  margin-left: 10px;
}
.custom-delete {
  float: right;
}
.custom-add-location {
  position: absolute;
  bottom: -15px;
  left: 110px;
  color: red;
  font-size: 0.8em;
  font-weight: 600;
}
</style>
