import { defineStore } from 'pinia'
import { api } from '@/plugins/api'

const userDefault = {
  firm: null,
  user: null,
  emailAddress: null,
  username: null,
  firstName: '',
  lastName: '',
  fullName: '',
  isInactive: false,
  timezone: 5,
  language: 1,
  roles: [],
  guid: null,
  profileUrl: null,
  techId: null,
  contactId: null,
  password: null,
  password2: null
}

export const userStore = defineStore('user', {
  state: () => ({
    user: JSON.parse(JSON.stringify(userDefault)),
    users: {
      count: 0,
      items: []
    },
    loading: {
      user: false,
      users: false,
      userFirms: false,
      userChannels: false,
      userQueue: false,
      userApps: false
    },
    listParams: {
      page: 1,
      pageSize: 10,
      filterOptions: null,
      filterBy: null,
      sortBy: 'asc',
      orderBy: 'user',
      searchTerm: ''
    },
    foundUsers: null,
    userFirms: [],
    userChannels: [],
    userQueues: [],
    userApps: [],
    appUser: {
      emailAddress: null,
      firm: 1,
      firstName: null,
      lastName: null,
      language: 1,
      timezone: 12,
      roles: null,
      username: null,
      apps: [
        {
          firm: null,
          name: 'ADAS',
          checked: false
        },
        {
          firm: null,
          name: 'ADASGerber',
          checked: false
        },
        {
          firm: null,
          name: 'ADASCrash',
          checked: false
        },
        {
          firm: null,
          name: 'ADASOpus',
          checked: false
        },
        {
          firm: null,
          name: 'Calibrate',
          checked: false
        },
        {
          firm: null,
          name: 'CalibrateGerber',
          checked: false
        },
        {
          firm: null,
          name: 'CalibrateCrash',
          checked: false
        },
        {
          firm: null,
          name: 'CalibrateOpus',
          checked: false
        },
        {
          firm: null,
          name: 'TestDrive',
          checked: false
        },
        {
          firm: null,
          name: 'TestDriveGerber',
          checked: false
        },
        {
          firm: null,
          name: 'TestDriveCrash',
          checked: false
        },
        {
          firm: null,
          name: 'TestDriveOpus',
          checked: false
        }
      ]
    }
  }),
  actions: {
    resetUser(firm) {
      this.user = JSON.parse(JSON.stringify(userDefault))
      if (firm) {
        this.user.firm = firm
      }
    },
    setAppUser(data) {
      switch (data.name) {
        case 'apps': {
          this.appUser.apps.forEach((element) => {
            element.checked = false
          })
          data.value.forEach((element) => {
            let found = this.appUser.apps.find((x) => x.name === element)
            if (found) {
              found.checked = true
            }
          })
          break
        }
        default: {
          this.appUser[data.name] = data.value
          break
        }
      }
    },
    setUser(data) {
      this.user[data.name] = data.value
    },
    setListParam(data) {
      this.listParams[data.name] = data.value
    },
    setLoading(data) {
      this.loading[data.name] = data.value
    },
    setPrimaryFirm(firm) {
      this.userFirms.forEach((element) => {
        element.isPrimary = 0
        if (parseInt(element.firm) === parseInt(firm)) {
          element.isPrimary = 1
          element.isSave = true
        }
      })
    },
    setUserFirm(data) {
      this.userFirms.push(data)
    },
    setUserChannel(data) {
      let found = this.userChannels.find((x) => x.channelName === data.channel)
      if (!found) {
        this.userChannels.push({
          userChannel: null,
          user: data.user,
          channelName: data.channel,
          channelId: null,
          isChecked: false,
          isRemove: false,
          isAdd: true
        })
      }
    },
    removeUserChannels() {
      this.userChannels.forEach((channel) => {
        if (channel.isChecked) {
          channel.isRemove = true
          channel.isChecked = false
        }
      })
    },
    setUserQueue(data) {      
      let found = this.userQueues.find((x) => x.ticketQueue === data.ticketQueue)
      if (!found) {
        this.userQueues.push({
          user: data.user,
          ticketTypeName: data.ticketTypeName,
          ticketQueue: data.ticketQueue,
          ticketQueueName: data.ticketQueueName,
          isChecked: false,
          isRemove: false,
          isAdd: true
        })
      }
    },
    removeUserQueues() {
      this.userQueues.forEach((queue) => {
        if (queue.isChecked) {
          queue.isRemove = true
          queue.isChecked = false
        }
      })
    },
    async getUsers(options) {
      this.users = {
        count: 0,
        items: []
      }
      this.loading.users = true
      this.users = await api.get('user/users', options)
      this.loading.users = false
      console.log('Got Users', this.users)
      return this.users
    },
    async getUser(uid) {
      this.resetUser()
      this.loading.user = true
      this.user = await api.get(`user/profile?username=${uid}`)
      this.loading.user = false
      console.log('Got User', this.user)
      return this.user
    },
    async saveUser() {
      return await api.post('user/saveUser', this.user)
    },
    async verifyUsername(username) {
      return await api.get(`user/verifyUsername?username=${username}`)
    },
    async saveCredentials(data) {
      return await api.post(`user/saveCredentials`, data)
    },
    async getUserCurrentFirms(user) {
      this.userFirms = []
      this.loading.userFirms = true
      this.userFirms = await api.get(`user/userFirms?user=${user}`)
      this.loading.userFirms = false
      console.log('Got User Current Firms', this.userFirms)
      if (this.userFirms) {
        this.userFirms.forEach((firm) => {
          firm.user = user
          firm.isPrimary = firm.isPrimary ? 1 : 0
        })
      }
      return this.userFirms
    },
    async getUserFirms(data) {
      this.userFirms = []
      this.loading.userFirms = true
      this.userFirms = await api.get(`firm/userFirms?user=${data.user}&firm=${data.firm}`)
      this.loading.userFirms = false
      if (this.userFirms) {
        this.userFirms.forEach((userFirm) => {
          userFirm.user = data.user
          userFirm.checked = true
          if (userFirm.userFirm === null) {
            userFirm.checked = false
          }
        })
      }
      console.log('Got User Firms', this.userFirms)
      return this.userFirms
    },
    async saveUserFirms(firms) {
      await api.post(`firm/saveUserFirms`, firms)
    },
    removeUserFirm(id) {
      this.userFirms = this.userFirms.filter((x) => parseInt(x.firm) !== parseInt(id))
    },
    async getUserChannels(user) {
      this.userChannels = []
      this.loading.userChannels = true
      this.userChannels = await api.get(`user/userChannels?user=${user}`)
      this.loading.userChannels = false
      if (this.userChannels) {
        this.userChannels.forEach((userChannel) => {
          userChannel.isChecked = false
          userChannel.isRemove = false
          userChannel.isAdd = false
        })
      } else {
        this.userChannels = []
      }
      console.log('Got User Channels', this.userChannels)
      return this.userChannels
    },
    async saveUserChannels() {
      if (this.userChannels) {
        let channels = this.userChannels.filter((x) => x.isAdd || x.isRemove)
        if (channels) {
          await api.post(`user/saveUserChannels`, channels)
        }
      }
    },
    async removeUserChannel(id) {
      await api.post(`user/deleteUserChannel`, id)
    },
    async getUserQueues(user, office) {
      this.userQueues = []
      this.loading.userQueues = true
      this.userQueues = await api.get(`user/userQueues?user=${user}&officeName=${office}`)      
      this.loading.userQueues = false

      if (this.userQueues) {
        this.userQueues.forEach((userQueue) => {
          userQueue.isChecked = false
          userQueue.isRemove = false
          userQueue.isAdd = false
        })
      } else {
        this.userQueues = []
      }
      console.log('Got User Queues', this.userQueues)
      return this.userQueues
    },
    async saveUserQueues() {
      if (this.userQueues) {
        let queues = this.userQueues.filter((x) => x.isAdd || x.isRemove)
        if (queues) {
          await api.post(`user/saveUserQueues`, queues)
        }
      }
    },    
    async removeUserQueue(id) {
      await api.post(`user/deleteUserQueue`, id)
    },
    async searchAppUser(app) {
      console.log('Finding Users', this.user)
      let data = {
        app: app,
        user: this.user.user,
        username: this.user.username,
        firstname: this.user.firstName,
        lastname: this.user.lastName,
        firm: this.user.firm
      }
      this.foundUsers = await api.get('user/searchAppUser', data)
      if (this.foundUsers === '') {
        this.foundUsers = null
      }
      console.log('Found Users', this.foundUsers)
      return this.foundUsers
    },
    async pushUser(data) {
      await api.post('data/pushUser', data)
    },
    async moveUser(data) {
      return await api.post('user/moveUser', data)
    },
    async updateUserParams(data) {
      return await api.post('user/updateUserParams', data)
    },
    async getUserApps(user) {
      this.userApps = []
      this.loading.userApps = true
      this.userApps = await api.get(`user/userApps?user=${user}`)
      this.loading.userApps = false
      return this.userApps
    }    
  }
})
