<template>
  <form @submit.prevent="">
    <z-dialog :show="isVisible" :modal-size="modalSize">
      <template #header>
        <div class="custom-header">
          <div class="row">
            <div class="col-4">
              <h4 class="modal-title">
                {{ title }}
              </h4>
            </div>
            <div class="col">
              <a v-if="isLocations" href="https://opusbroker.blob.core.windows.net/images/Test%20Location%20Import%20092023.csv" target="_blank"
                >Sample Location Import File</a
              >
              <a v-else href="https://opusbroker.blob.core.windows.net/images/Test%20User%20Import%20092023.csv" target="_blank"
                >Sample User Import File</a
              >
            </div>
            <div class="col-1">
              <a href="#" class="custom-close" @click="close">
                <i class="fa fa-times"></i>
              </a>
            </div>
          </div>
        </div>
      </template>
      <template #body>
        <div class="row">
          <div class="col">
            <p v-if="isLocations">
              Use this facility to import Locations (and initial users) that'll be part of the current Location hierarchy. Be sure it is a properly
              formatted comma-delimited (*.csv) file.
              <b>The first location in the comma-delimited file should always have its parent be 'OPUS IVS'</b>.
            </p>
            <p v-else>
              Use this facility to import Users that are associated with existing Locations. Be sure it is a properly formatted comma-delimited
              (*.csv) file.
            </p>
            <p v-if="isLocations">
              <b>Note:</b> for 'White Label' partners, set up a Corporate location within the *.csv file which will place them underneath 'OPUS IVS'.
              For non-'White Label' partners, the Corporate location will be 'OPUS IVS Commercial'.
            </p>
            <p>
              <b class="text-danger">Note:</b> locations need to be imported first, before importing users as users will be associated with locations
              specified within the incoming comma-delimited file. Spelling and case sensitivity must be exact for matching purposes. Also, no leading
              or trailing spaces. <b>Recommended:</b> for locations, it's recommended you use Firm Numbers.
            </p>
          </div>
        </div>
        <div class="row">
          <div class="col-4">
            <div class="progress">
              <div
                class="progress-bar"
                :class="{ 'bg-success': isComplete, 'progress-bar-striped progress-bar-animated': !isComplete }"
                role="progressbar"
                :style="`width: ${progress}%;`"
                :aria-valuenow="progress"
                aria-valuemin="0"
                aria-valuemax="100"
              ></div>
            </div>
          </div>
          <div class="col custom-stats">
            <span>{{ duration }}</span>
            <span class="ms-2">{{ total }}</span>
          </div>
        </div>
        <div class="row">
          <div class="col">
            <span>{{ data }}</span>
          </div>
        </div>
        <div class="importWrapper">
          <div class="custom-file">
            <button class="btn btn-light btn-sm custom-reset" @click="reset">Reset</button>
            <input id="fileSelect" ref="import" type="file" accept=".csv" class="custom-file-input" @change="onFileChange" />
            <label class="custom-file-label" for="fileSelect">
              {{ file.size ? file.name : 'Choose a file to import...' }}
            </label>
          </div>
          <div class="table-responsive">
            <div v-if="isLocations" class="custom-listing">
              <z-table
                v-model="csv"
                class="table table-bordered table-striped"
                :is-single-row-detail="false"
                :is-header-fixed="isHeaderFixed"
                :columns="tableColumnsName"
                :detail-column-class="detailColumnClass"
                :loading="isLoading"
              >
                <template #tbody="props">
                  <td>{{ props.row.row }}</td>
                  <td>{{ props.row.parentName }}</td>
                  <td>{{ props.row.firmName }}</td>
                  <td>{{ props.row.firmTypeName }}</td>
                  <td>{{ props.row.firmNumber }}</td>
                  <td>{{ props.row.city }}</td>
                  <td>{{ props.row.state }}</td>
                  <td>{{ props.row.postalCode }}</td>
                  <td>{{ props.row.timezone }}</td>
                  <td>{{ props.row.language }}</td>
                  <td>{{ props.row.username }}</td>
                  <td>{{ props.row.firstname }}</td>
                  <td>{{ props.row.lastname }}</td>
                  <td>{{ props.row.rolename }}</td>
                  <td>{{ props.row.cccId }}</td>
                  <td>{{ props.row.cccDiag }}</td>
                  <td>{{ props.row.mitchellId }}</td>
                  <td>{{ props.row.address }}</td>
                </template>
                <template #empty> Nothing found! </template>
              </z-table>
            </div>
            <div v-else class="custom-listing">
              <z-table
                v-model="csv"
                class="table table-bordered table-striped"
                :is-single-row-detail="false"
                :is-header-fixed="isHeaderFixed"
                :columns="tableColumnsName"
                :detail-column-class="detailColumnClass"
                :loading="isLoading"
              >
                <template #tbody="props">
                  <td>{{ props.row.row }}</td>
                  <td>{{ props.row.employeeId }}</td>
                  <td>{{ props.row.firstname }}</td>
                  <td>{{ props.row.lastname }}</td>
                  <td>{{ props.row.username }}</td>
                  <td>{{ props.row.isInactive }}</td>
                  <td>{{ props.row.firmnumber }}</td>
                  <td>{{ props.row.firmname }}</td>
                  <td>{{ props.row.rolename }}</td>
                  <td>{{ props.row.rate }}</td>
                  <td>{{ props.row.password }}</td>
                </template>
                <template #empty> Nothing found! </template>
              </z-table>
            </div>
          </div>
        </div>
      </template>
      <template #footer>
        <div class="custom-footer">
          <div v-if="(isLocations && gotData) || (!isLocations && gotData)" class="col-1">
            <button class="btn btn-primary btn-sm" @click="validate">Validate</button>
          </div>
          <div class="col">
            <z-message v-model="message" class="alert alert-warning mt-2" />
          </div>
          <div class="col-2 custom-right-align">
            <button type="button" class="btn btn-light" :disabled="isImporting" @click="close()">Cancel</button>
            <button v-if="isSave" class="btn btn-danger ms-2" :disabled="isImporting" @click="save">
              <i v-if="isImporting" class="fas fa-spinner fa-spin"></i>
              Import
            </button>
          </div>
        </div>
      </template>
    </z-dialog>
  </form>
</template>
<script>
import { mapState, mapActions } from 'pinia'
import { dataStore } from '@/stores/data'
import Papa from 'papaparse'
import ZDialog from '@/components/utilities/elements/Dialog.vue'
import ZTable from '@/components/utilities/elements/Table.vue'
import ZMessage from '@/components/utilities/elements/Message.vue'
export default {
  name: 'DialogLocation',
  components: {
    ZDialog,
    ZTable,
    ZMessage
  },
  props: {
    modelValue: {
      type: Object
    }
  },
  data() {
    return {
      file: {},
      modalSize: 'modal-lg',
      isSave: false,
      message: null,
      isHeaderFixed: true,
      isLoading: false,
      isImporting: false,
      progress: 0,
      duration: null,
      data: null,
      total: null,
      isComplete: false,
      success: [],
      failed: [],
      rawCSV: null
    }
  },
  mounted() {
    this.reset()
    this.emitter.on('process-dataimport', (status) => {
      console.log('Import Progress', status)
      this.calcProgress(status)
    })
  },
  emits: ['closing', 'saved'],
  computed: {
    ...mapState(dataStore, ['csvData', 'csvValidation']),
    isVisible() {
      return this.modelValue !== null
    },
    title() {
      let _title = ''
      if (this.modelValue) {
        _title = this.modelValue.title
      }
      return _title
    },
    isLocations() {
      let _is = false
      if (this.modelValue) {
        _is = this.modelValue.title === 'Import Locations' ? true : false
      }
      return _is
    },
    gotData() {
      let _got = false
      if (this.csvData) {
        if (this.csvData.length > 0) {
          _got = true
        }
      }
      return _got
    },
    parentBranch() {
      let _id = null
      if (this.modelValue) {
        _id = this.modelValue.parentBranch
      }
      return _id
    },
    tableColumnsName() {
      let columns = []
      if (this.isLocations) {
        columns = [
          { field: 'row', label: '#', sortable: false },
          { field: 'parentName', label: 'Parent', sortable: false },
          { field: 'firmName', label: 'Name', sortable: false },
          { field: 'firmTypeName', label: 'Type', sortable: false },
          { field: 'firmNumber', label: 'Number', sortable: false },
          { field: 'city', label: 'City', sortable: false },
          { field: 'state', label: 'State', sortable: false },
          { field: 'postalCode', label: 'Postal Code', sortable: false },
          { field: 'timezone', label: 'Timezone', sortable: false },
          { field: 'language', label: 'Language', sortable: false },
          { field: 'username', label: 'Username', sortable: false },
          { field: 'firstname', label: 'First', sortable: false },
          { field: 'lastname', label: 'Last', sortable: false },
          { field: 'rolename', label: 'Role', sortable: false },
          { field: 'cccId', label: 'CCC Id', sortable: false },
          { field: 'cccDiag', label: 'CCC Diag', sortable: false },
          { field: 'mitchellId', label: 'Mitch Id', sortable: false },
          { field: 'address', label: 'Address', sortable: false }
        ]
      } else {
        columns = [
          { field: 'row', label: '#', sortable: false },
          { field: 'employeeId', label: 'Employee Id', sortable: false },
          { field: 'firstname', label: 'First', sortable: false },
          { field: 'lastname', label: 'Last', sortable: false },
          { field: 'username', label: 'Username/Email', sortable: false },
          { field: 'isInactive', label: 'Inactive?', sortable: false },
          { field: 'firmnumber', label: 'Location Id', sortable: false },
          { field: 'firmname', label: 'Location', sortable: false },
          { field: 'rolename', label: 'Role', sortable: false },
          { field: 'rate', label: 'Rate $', sortable: false },
          { field: 'password', label: 'Password', sortable: false }
        ]
      }
      return columns
    },
    isValid() {
      let _is = false
      if (this.csvValidation) {
        if (this.csvValidation.length > 0) {
          _is = true
          let found = this.csvValidation.find((x) => x.valid === false)
          if (found) {
            _is = false
          }
        }
      }
      return _is
    },
    csv() {
      let _data = []
      if (this.csvData) {
        let row = 1
        _data = JSON.parse(JSON.stringify(this.csvData))
        _data.forEach((element) => (element.row = row++))
      }
      return _data
    }
  },
  methods: {
    ...mapActions(dataStore, ['resetCsvData', 'setCsvData', 'importLocations', 'importUsers', 'verifyCSV']),
    close() {
      this.currentTab = 'Basic'
      this.isSave = false
      this.reset()
      this.$emit('closing')
    },
    reset() {
      this.isImporting = false
      try {
        this.progress = 0
        this.duration = null
        this.data = null
        this.total = null
        this.message = null
        this.file = {}
        this.resetCsvData()
        if (this.$refs.import) {
          this.$refs.import.value = ''
          if (this.$refs.import.value) {
            this.$refs.import.type = 'text'
            this.$refs.import.type = 'file'
          }
        }
      } catch (e) {
        console.error(e)
      }
    },
    async validate() {
      this.message = null
      let data = {
        firm: null,
        fileName: this.file.name,
        fileType: this.isLocations ? 'Locations' : 'Users',
        csvData: Papa.unparse(this.csvData),
        mappedFields: null
      }
      console.log('Validate', data)
      let results = await this.verifyCSV(data)
      if (results) {
        this.message = results.result
      }
    },
    setNotice() {
      this.message = null
      if (!this.isValid) {
        this.csvValidation.forEach((element) => {
          if (!element.valid) {
            if (this.message !== null) {
              this.message += '; '
            } else {
              this.message = ''
            }
            this.message += `${element.name} at row ${element.data}`
          }
        })
      }
    },
    calcProgress(status) {
      this.isImporting = true
      let isComplete = this.getStatus(status, 'IsComplete')
      if (isComplete) {
        if (parseInt(isComplete) === 1) {
          console.log('Import Complete')
          if (this.isLocations) {
            this.data = 'Note: Location Push to App Solutions is being done in a background process.'
          } else {
            this.data = 'Note: User Push to App Solutions is being done in a background process.'
          }
          this.isImporting = false
          try {
            this.reset()
          } catch (e) {
            console.error(e)
          }
        }
      } else {
        let total = this.getStatus(status, 'TotalCount')
        let count = this.getStatus(status, 'Count')
        let duration = this.getStatus(status, 'TotalDuration')
        if (this.isLocations) {
          this.data = this.getStatus(status, 'Location')
        } else {
          this.data = this.getStatus(status, 'User')
        }
        if (total !== null && count !== null) {
          this.progress = (parseInt(count) / parseInt(total)) * 100
        }
        if (duration) {
          if (duration > 3600) {
            this.duration = `${(duration / 3600).toFixed(1)} hours`
          } else if (duration > 60) {
            this.duration = `${(duration / 60).toFixed(1)} minutes`
          } else {
            this.duration = `${duration.toFixed(0)} seconds`
          }
        }
        if (count !== null) {
          this.total = `for ${count} of ${total} records.`
        }
        console.log('Current Progress', {
          progress: this.progress,
          duration: duration,
          data: this.data,
          total: this.total
        })
        if (this.progress >= 100) {
          this.isImporting = false
        }
      }
    },
    getStatus(status, item) {
      let value = null
      if (status) {
        let found = status.find((x) => x.attribute === item)
        if (found) {
          value = found.data ? found.data : found.value
        }
      }
      return value
    },
    onFileChange() {
      try {
        this.isSave = false
        this.resetCsvData()
        let delimiter = ','
        this.message = ''
        this.isLoading = true
        const files = this.$refs.import.files
        this.file = files[0]
        // check for CSV
        let extension = this.file.name.split('.').pop()
        if (extension !== 'csv') {
          this.message = 'You must select a CSV file for import'
        }
        if (this.file && !this.message) {
          Papa.parse(this.file, {
            delimiter,
            header: true,
            skipEmptyLines: true,
            complete: (results) => {
              if (this.isLocations) {
                this.setCsvData('locations', results.data)
              } else {
                this.setCsvData('users', results.data)
              }

              // if (this.isValid) {
              this.isSave = true
              // } else {
              //   this.setNotice()
              // }
            }
          })
        }
        this.isLoading = false
      } catch (e) {
        this.isLoading = false
        console.error(e)
        this.message = 'Unable to import file from disk'
      }
    },
    async save() {
      this.message = null
      if (this.isSave) {
        this.data = null
        if (this.isLocations) {
          await this.importLocations(this.parentBranch)
        } else {
          await this.importUsers()
        }
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.custom-header {
  width: 100%;
}
.custom-close {
  float: right;
  margin-right: 10px;
  color: #000;
  cursor: pointer;
}
.custom-footer {
  width: 100%;
  display: inline-flex;
}
.custom-listing {
  overflow-x: auto;
  overflow-y: auto;
  width: 100%;
  height: 480px;
}
.custom-file-label {
  cursor: pointer;
  text-decoration: underline;
}
.custom-file-label,
.custom-file-label::after {
  padding: 0.5rem 1rem;
}
.custom-file-input {
  cursor: pointer;
}
.custom-stats {
  font-size: 0.9em;
}
.custom-reset {
  position: relative;
  top: -2px;
  margin-right: 3px;
}
</style>
