import { defineStore } from 'pinia'
import { api } from '@/plugins/api'

const addressDefault = {
  firm: null,
  contact: null,
  contactAddress: -1,
  address: -1,
  addressType: 0,
  addressLines: '',
  city: '',
  stateProvince: 0,
  postalCode: '',
  country: 0,
  addressNote: '',
  isPrimary: true,
  dir: null
}
const phoneDefault = {
  firm: null,
  phone: -1,
  contact: null,
  contactPhone: -1,
  phoneType: 0,
  phoneNo: '',
  extension: '',
  phoneNote: null,
  isPrimary: true,
  dir: null
}
const emailDefault = {
  firm: null,
  contact: null,
  contactEmail: -1,
  emailType: 0,
  emailAddress: '',
  isPrimary: true,
  dir: null
}
export const contactStore = defineStore('contact', {
  state: () => ({
    loading: {
      address: false,
      phone: false,
      email: false,
      addresses: false,
      phones: false,
      emails: false
    },
    address: null,
    phone: null,
    email: null,
    addresses: [],
    phones: [],
    emails: []
  }),
  actions: {
    resetAddress(data) {
      this.address = JSON.parse(JSON.stringify(addressDefault))
      if (data) {
        this.address.firm = data.firm
        this.address.contact = data.contact
        if (data.user) {
          this.address.user = data.user
          this.address.dir = 'user'
        } else {
          this.address.dir = 'firm'
        }
      }
    },
    resetPhone(data) {
      this.phone = JSON.parse(JSON.stringify(phoneDefault))
      if (data) {
        this.phone.firm = data.firm
        this.phone.contact = data.contact
        if (data.user) {
          this.phone.user = data.user
          this.phone.dir = 'user'
        } else {
          this.phone.dir = 'firm'
        }
      }
    },
    resetEmail(data) {
      this.email = JSON.parse(JSON.stringify(emailDefault))
      if (data) {
        this.email.firm = data.firm
        this.email.contact = data.contact
        if (data.user) {
          this.email.user = data.user
          this.email.dir = 'user'
        } else {
          this.email.dir = 'firm'
        }
      }
    },
    assignAddress(data) {
      this.address = JSON.parse(JSON.stringify(data))
    },
    assignPhone(data) {
      this.phone = JSON.parse(JSON.stringify(data))
    },
    assignEmail(data) {
      this.email = JSON.parse(JSON.stringify(data))
    },
    setAddress(data) {
      this.address[data.name] = data.value
    },
    setPhone(data) {
      this.phone[data.name] = data.value
    },
    setEmail(data) {
      this.email[data.name] = data.value
    },
    setCheckedAddress(data) {
      let found = this.addresses.find((x) => parseInt(x.contactAddress) === parseInt(data.contactAddress))
      if (found) {
        found.isChecked = data.isChecked
      }
    },
    setCheckedPhone(data) {
      let found = this.phones.find((x) => parseInt(x.contactPhone) === parseInt(data.contactPhone))
      if (found) {
        found.isChecked = data.isChecked
      }
    },
    setCheckedEmail(data) {
      let found = this.emails.find((x) => parseInt(x.contactEmail) === parseInt(data.contactEmail))
      if (found) {
        found.isChecked = data.isChecked
      }
    },
    async getAddresses(contact) {
      this.loading.addresses = true
      this.addresses = []
      let results = await api.get(`contact/contactAddresses?id=${contact}`)
      if (results) {
        if (results.items) {
          this.addresses = results.items
          this.addresses.forEach((element) => {
            element.isPrimary = false
            if (element.primary === 'P') {
              element.isPrimary = true
            }
          })
        }
      }
      this.loading.addresses = false
      console.log('Got Addresses', this.addresses)
      return this.addresses
    },
    async getPhones(contact) {
      this.loading.phones = true
      this.phones = []
      let results = await api.get(`contact/contactPhones?id=${contact}`)
      if (results) {
        if (results.items) {
          this.phones = results.items
          this.phones.forEach((element) => {
            element.isPrimary = false
            if (element.primary === 'P') {
              element.isPrimary = true
            }
          })
        }
      }
      this.loading.phones = false
      console.log('Got Phones', this.phones)
      return this.phones
    },
    async getEmails(contact) {
      this.loading.emails = true
      this.emails = []
      let results = await api.get(`contact/contactEmails?id=${contact}`)
      if (results) {
        if (results.items) {
          this.emails = results.items
          this.emails.forEach((element) => {
            element.isPrimary = false
            if (element.primary === 'P') {
              element.isPrimary = true
            }
          })
        }
      }
      this.loading.emails = false
      console.log('Got Emails', this.emails)
      return this.emails
    },
    async saveAddress() {
      return await api.post('contact/saveAddress', this.address)
    },
    async savePhone() {
      return await api.post('contact/savePhone', this.phone)
    },
    async saveEmail() {
      return await api.post('contact/saveEmail', this.email)
    },
    async setPrimaryAddress(data) {
      return await api.get(`contact/setPrimaryAddress?id=${data.id}&contact=${data.contact}`)
    },
    async setPrimaryPhone(data) {
      return await api.get(`contact/setPrimaryPhone?id=${data.id}&contact=${data.contact}`)
    },
    async setPrimaryEmail(data) {
      return await api.get(`contact/setPrimaryEmail?id=${data.id}&contact=${data.contact}`)
    },
    async deleteAddress(data) {
      return await api.post('contact/deleteAddress', data)
    },
    async deletePhone(data) {
      return await api.post('contact/deletePhone', data)
    },
    async deleteEmail(data) {
      return await api.post('contact/deleteEmail', data)
    }
  }
})
