import HomeView from '@/views/HomeView.vue'
import UsersView from '@/views/UsersView.vue'
import LocationView from '@/views/LocationView.vue'
import LoginView from '@/views/LoginView.vue'
import ResetView from '@/views/ResetView.vue'
import LaunchView from '@/views/LaunchView.vue'
import ConfigView from '@/views/ConfigView.vue'

export default [
  {
    path: '/login',
    name: 'login',
    meta: { allowAnonymous: true },
    component: LoginView
  },
  {
    path: '/reset',
    name: 'reset',
    meta: { allowAnonymous: true },
    component: ResetView
  },
  {
    path: '/',
    name: 'home',
    meta: { layout: 'side-nav' },
    component: HomeView
  },
  {
    path: '/users',
    name: 'users',
    meta: { layout: 'default' },
    component: UsersView
  },
  {
    path: '/locations',
    name: 'locations',
    meta: { layout: 'default' },
    component: LocationView
  },
  {
    path: '/launchpad',
    name: 'launchpad',
    meta: { layout: 'default' },
    component: LaunchView
  },
  {
    path: '/configuration',
    name: 'configuration',
    meta: { layout: 'default' },
    component: ConfigView
  },
  {
    path: '/about',
    name: 'about',
    meta: { layout: 'default' },
    // route level code-splitting
    // this generates a separate chunk (About.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/AboutView.vue')
  }
]
