<template>
  <p>Select those locations this user will have access to.</p>
  <div class="custom-firm-list-wrapper">
    <div class="row">
      <div class="col-8">
        <Multiselect
          ref="searchLocation"
          v-model="foundLocation"
          class="form-control custom-multiselect"
          placeholder="type to search"
          mode="single"
          :close-on-select="true"
          :filter-results="false"
          :min-chars="1"
          :resolve-on-load="false"
          :delay="500"
          :searchable="true"
          :open-direction="'bottom'"
          :options="
            async (query) => {
              return await fetchLocations(query)
            }
          "
          label="firmName"
          value-prop="firm"
          @select="selectedLocation"
        />
      </div>
      <div class="col"></div>
    </div>
    <div class="row mt-3">
      <div class="col">
        <ul class="custom-list">
          <li v-for="(firm, idx) of userFirms" :key="idx">
            <div class="row">
              <div class="col">
                <!-- <input
                  :id="firm.firm"
                  v-model="firm.checked"
                  type="checkbox"
                  :true-value="true"
                  :false-value="false"
                  class="custom-checkbox me-2"
                  @click="changed(firm)"
                /> -->
                <span>{{ firm.firmName }}</span>
              </div>
              <div class="col-4">
                <select v-model="firm.role" class="form-control" @change="changed(firm)">
                  <option value="" disabled selected>user role for this location</option>
                  <option v-for="role of userRoles" :key="role.value" :value="role.value">
                    {{ role.description }}
                  </option>
                </select>
              </div>
              <div class="col-1 text-center">
                <input
                  :id="`${firm.firm}_${idx}`"
                  v-model="firm.isPrimary"
                  type="checkbox"
                  :true-value="1"
                  :false-value="0"
                  class="custom-checkbox me-2"
                  @click="primary(firm)"
                />
              </div>
              <div class="col-1 text-center">
                <div @click="removeLocation(firm.firm)">
                  <i class="fas fa-trash custom-trash"></i>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from 'pinia'
import { authStore } from '@/stores/auth'
import { lookupStore } from '@/stores/lookup'
import { userStore } from '@/stores/user'
import { locationStore } from '@/stores/location'
import Multiselect from '@vueform/multiselect'
export default {
  name: 'UserLocations2',
  components: {
    Multiselect
  },
  props: {
    firm: Number
  },
  data() {
    return {
      isLoading: false,
      foundLocation: null
    }
  },
  async mounted() {
    await this.init()
  },
  emits: ['changed', 'saved'],
  computed: {
    ...mapState(locationStore, ['foundLocations']),
    ...mapState(authStore, ['profile']),
    ...mapState(lookupStore, ['userRoles']),
    ...mapState(userStore, ['loading', 'user', 'userFirms'])
  },
  methods: {
    ...mapActions(locationStore, ['searchLocations']),
    ...mapActions(userStore, ['getUserCurrentFirms', 'setUserFirm', 'saveUserFirms', 'setPrimaryFirm', 'removeUserFirm']),
    async init() {
      this.isLoading = true
      await this.getUserCurrentFirms(this.user.user)
      this.isLoading = false
    },
    changed() {
      this.$emit('changed')
    },
    async fetchLocations(query) {
      let search = {
        search: query,
        type: 3,
        firm: this.user.firm,
        firmType: null
      }
      return await this.searchLocations(search)
    },
    selectedLocation() {
      this.$nextTick(() => {
        let there = this.userFirms.find((x) => parseInt(x.firm) === parseInt(this.foundLocation))
        if (!there) {
          let found = this.foundLocations.find((x) => parseInt(x.firm) === parseInt(this.foundLocation))
          if (found) {
            console.log('Found a Location', found)
            this.setUserFirm({
              firm: found.firm,
              firmName: found.firmName,
              isPrimary: 0,
              role: null,
              roleName: null,
              user: this.user.user
            })
          }
          this.$refs.searchLocation.clear()
          this.$refs.searchLocation.clearSearch()
        }
        this.$emit('changed')
      })
    },
    removeLocation(firm) {
      console.log('Remove Location', firm)
      this.removeUserFirm(firm)
      this.$emit('changed')
    },
    primary(firm) {
      console.log('Set Primary', firm)
      this.setPrimaryFirm(firm)
      this.$emit('changed')
    },
    async save() {
      let response = {
        valid: false,
        user: this.user.user,
        msg: null
      }
      // let firms = []
      // this.firms.forEach((firm) => {
      //   firms.push({
      //     user: this.user.user,
      //     firm: firm.firm,
      //     firmName: firm.firmName,
      //     isPrimary: firm.isPrimary,
      //     role: firm.role
      //   })
      // })
      await this.saveUserFirms(this.userFirms)
      await this.init()
      response.valid = true
      this.$emit('saved')
      return response
    }
  }
}
</script>
<style lang="scss" scoped>
.custom-firm-list-wrapper {
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 400px;
  height: 400px;
}
.custom-loading {
  width: 100%;
  text-align: center;
}
.custom-list {
  list-style: none;
  li {
    padding-top: 5px;
    padding-bottom: 5px;
  }
}
.custom-row {
  width: 100%;
  display: inline;
}
.custom-user-role {
  text-align: center;
  span {
    font-weight: 500;
  }
}
.custom-trash {
  color: rgb(173, 0, 0);
  :hover {
    cursor: pointer;
  }
}
</style>
