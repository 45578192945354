<template>
  <div class="row">
    <div class="col">
      <div class="row">
        <div class="col">
          <Multiselect
            ref="searchLocation"
            v-model="foundLocation"
            class="form-control custom-multiselect"
            placeholder="type to search"
            mode="multiple"
            :close-on-select="false"
            :filter-results="false"
            :min-chars="1"
            :resolve-on-load="false"
            :delay="500"
            :searchable="true"
            :open-direction="'top'"
            :options="
              async (query) => {
                return await fetchLocations(query)
              }
            "
            label="firmName"
            value-prop="firm"
            @select="selectedLocation"
          />
        </div>
      </div>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col">
      <ul class="custom-list">
        <li v-for="location of currentNetwork" :key="location.firm2">
          <div class="row">
            <div class="col">
              <span>{{ location.firmName }}</span>
            </div>
            <div class="col-1 text-center">
              <div @click="removeLocation(location.firm2)">
                <i class="fas fa-trash custom-trash"></i>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from 'pinia'
import { locationStore } from '@/stores/location'
import Multiselect from '@vueform/multiselect'
export default {
  name: 'OutOfNetwork',
  components: {
    Multiselect
  },
  props: {
    firm: Object
  },
  data() {
    return {
      foundLocation: null,
      isSave: false
    }
  },
  emits: ['saved', 'changed'],
  computed: {
    ...mapState(locationStore, ['location', 'outnetwork', 'foundLocations']),
    currentNetwork() {
      let _network = []
      if (this.outnetwork) {
        this.outnetwork.forEach((element) => {
          if (!element.isRemove) {
            _network.push(element)
          }
        })
      }
      return _network
    }
  },
  methods: {
    ...mapActions(locationStore, ['searchLocations', 'pushOutNetwork', 'popOutNetwork', 'saveOutNetwork']),
    async fetchLocations(query) {
      let search = {
        search: query,
        type: 2,
        firm: this.location.firm
      }
      return await this.searchLocations(search)
    },
    selectedLocation() {
      this.$nextTick(() => {
        let found = this.foundLocations.find((x) => parseInt(x.firm) === parseInt(this.foundLocation))
        if (found) {
          this.isSave = true
          let data = {
            firmFirm: null,
            firm: this.firm.id,
            firm2: found.firm,
            firmName: found.firmName,
            relationship: 2,
            isAdd: true,
            isRemove: false
          }
          this.pushOutNetwork(data)
          console.log('Added a Location to Out of Network', this.outnetwork)
          this.$refs.searchLocation.clear()
          this.$refs.searchLocation.clearSearch()
        }
        this.$emit('changed')
      })
    },
    removeLocation(firm) {
      this.isSave = true
      this.popOutNetwork(firm)
      console.log('Removed a Location from Out of Network', {
        id: firm,
        network: this.outnetwork
      })
      this.$emit('changed')
    },
    async save() {
      if (this.isSave) {
        await this.saveOutNetwork()
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.custom-firm-list-wrapper {
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 400px;
  height: 400px;
}
.custom-list {
  list-style: none;
  li {
    padding-top: 5px;
    padding-bottom: 5px;
  }
}
.custom-row {
  width: 100%;
  display: inline;
}
.custom-user-role {
  text-align: center;
  span {
    font-weight: 500;
  }
}
.custom-trash {
  color: rgb(173, 0, 0);
  :hover {
    cursor: pointer;
  }
}
</style>
