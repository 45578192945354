<template>
  <div v-if="message" class="custom-notice" :class="customclass">
    <span v-html="message"></span>
    <button type="button" class="btn btn-sm custom-close" aria-label="Close" @click="close">
      <i class="fas fa-times" title="Close" aria-hidden="true"></i>
    </button>
  </div>
</template>

<script>
export default {
  name: 'ZMessage',
  props: {
    modelValue: String,
    timeout: {
      type: Number,
      default: 0
    },
    customclass: String
  },
  emits: ['update:modelValue'],
  computed: {
    message: {
      get() {
        let msg = this.modelValue
        if (msg !== '' && this.timeout !== 0) {
          this.runTimer()
        }
        return msg
      },
      set(value) {
        this.$emit('update:modelValue', value)
      }
    }
  },
  methods: {
    runTimer() {
      let $this = this
      setTimeout(function () {
        $this.message = ''
      }, this.timeout)
    },
    close() {
      this.message = ''
    }
  }
}
</script>
<style lang="scss" scoped>
.custom-notice {
  position: relative;
}
.custom-close {
  position: absolute;
  right: 5px;
  top: 0;
}
</style>
