<template>
  <section>
    <div class="form-group mb-2">
      <div :class="{ 'form-group row': inline }">
        <div :class="inlineLabelClass">
          <label v-if="label" :for="name" class="control-label">
            {{ label }}:
            <span v-if="hasAsterisk" class="text-danger">*</span>
          </label>
        </div>
        <div :class="inlineInputClass">
          <div :class="{ error: errors.length > 0 }">
            <input
              :id="name"
              class="form-control"
              :class="addClass"
              :style="addStyle"
              :type="type || 'text'"
              :name="name"
              :value="inputValue"
              :min="min"
              :max="max"
              :step="step"
              :maxlength="maxlength || 200"
              :placeholder="placeholder || label"
              :disabled="disabled"
              :readonly="readonly"
              :tabindex="tabindex"
              :autocomplete="autocomplete"
              @input="change"
              @keyup="keyup"
              @blur="blur"
            />
            <div v-if="errors.length > 0">
              <div v-for="error of errors" :key="error.id" class="input-errors">
                <small class="error-msg text-danger">{{ error.msg }}</small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: 'ZInput',
  props: {
    name: String,
    type: String,
    modelValue: [Number, String, Boolean, Date],
    label: String,
    validator: Object,
    disabled: Boolean,
    maxlength: Number,
    placeholder: String,
    autocomplete: String,
    min: String,
    max: String,
    step: String,
    addClass: String,
    addStyle: String,
    readonly: Boolean,
    inline: {
      type: Boolean,
      default: false
    },
    inlineSize: {
      type: Number,
      default: 10
    },
    tabindex: [Number, String]
  },
  emits: ['keyup', 'blur', 'update:modelValue'],
  computed: {
    inputValue() {
      return this.modelValue
    },
    hasAsterisk() {
      let _has = false
      if (this.validator) {
        _has = true
      }
      return _has
    },
    errors() {
      let _data = []
      if (this.validator) {
        if (this.validator.$errors.length > 0) {
          let found = this.validator.$errors.find((x) => x.$property === this.name)
          if (found) {
            _data.push({
              id: 0,
              msg: found.$message
            })
          }
        }
      }
      return _data
    },
    inlineLabelClass() {
      let _data = ''
      if (this.inline) {
        _data = 'col-sm-2 mt-1'
        if (this.inlineSize <= 10) {
          _data = `col-sm-${12 - this.inlineSize} mt-1 text-right`
        }
      }
      return _data
    },
    inlineInputClass() {
      let _data = ''
      if (this.inline) {
        _data = 'col-sm-10'
        if (this.inlineSize <= 10) {
          _data = `col-sm-${this.inlineSize}`
        }
      }
      return _data
    }
  },
  methods: {
    change($event) {
      this.$emit('update:modelValue', $event.target.value)
    },
    keyup($event) {
      this.$emit('keyup', $event.target.value)
    },
    blur($event) {
      this.$emit('blur', $event.target.value)
    }
  }
}
</script>
