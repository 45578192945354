import moment from 'moment'
export default {
  sleep(ms) {
    return new Promise((resolve) => setTimeout(resolve, ms))
  },
  stringSort(data, sortOn, dir) {
    function isNumeric(n) {
      return !isNaN(parseFloat(n)) && isFinite(n)
    }
    let _data = Array.from(new Set(data)).sort(function (a, b) {
      var x = a[sortOn]
      var y = b[sortOn]
      if (isNumeric(x) || isNumeric(y)) {
        if (dir) {
          if (dir.toUpperCase() === 'ASC') {
            return x < y ? -1 : x > y ? 1 : 0
          }
          if (dir.toUpperCase() === 'DESC') {
            return x > y ? -1 : x < y ? 1 : 0
          }
        } else {
          return x < y ? -1 : x > y ? 1 : 0
        }
      } else {
        if (x === null) {
          x = ''
        }
        if (y === null) {
          y = ''
        }
        if (x && y) {
          if (dir) {
            if (dir.toUpperCase() === 'ASC') {
              return a[sortOn].localeCompare(b[sortOn])
            }
            if (dir.toUpperCase() === 'DESC') {
              return b[sortOn].localeCompare(a[sortOn])
            }
          } else {
            return a[sortOn].localeCompare(b[sortOn])
          }
        } else {
          return x < y ? -1 : x > y ? 1 : 0
        }
      }
    })
    return _data
  },
  sortArray(data, sortOn, dir) {
    let _data = Array.from(new Set(data)).sort(function (a, b) {
      if (a[0] === b[0]) {
        var x = a[sortOn]
        var y = b[sortOn]
        if (dir) {
          if (dir.toUpperCase() === 'ASC') {
            return x < y ? -1 : x > y ? 1 : 0
          }
          if (dir.toUpperCase() === 'DESC') {
            return x > y ? -1 : x < y ? 1 : 0
          }
        } else {
          return x < y ? -1 : x > y ? 1 : 0
        }
      }
      return a[0] - b[0]
    })
    return _data
  },
  isNumeric: function (n) {
    return !isNaN(parseFloat(n)) && isFinite(n)
  },
  isObject(objValue) {
    return objValue && typeof objValue === 'object' && objValue.constructor === Object
  },
  formatNumber: function (value, places) {
    let result = 0
    try {
      if (places === undefined || places === null) {
        places = 2
      }
      if (places === 0) {
        result = value.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
      } else {
        result = value.toFixed(places).replace(/\d(?=(\d{3})+\.)/g, '$&,')
      }
      return result
    } catch (e) {
      console.log('Error: formatNumber', e)
    }
  },
  formatMoney: function (amount, decimalCount, decimal, thousands) {
    let result = 0
    try {
      if (amount !== undefined && amount !== null) {
        if (decimalCount === undefined || decimalCount === null) {
          decimalCount = 2
        }
        if (decimal === undefined || decimal === null) {
          decimal = '.'
        }
        if (thousands === undefined || thousands === null) {
          thousands = ','
        }
        decimalCount = Math.abs(decimalCount)
        decimalCount = isNaN(decimalCount) ? 2 : decimalCount
        const negativeSign = amount < 0 ? '-' : ''
        let i = parseInt((amount = Math.abs(Number(amount) || 0).toFixed(decimalCount))).toString()
        let j = i.length > 3 ? i.length % 3 : 0
        result =
          negativeSign +
          (j ? i.substr(0, j) + thousands : '') +
          i.substr(j).replace(/(\d{3})(?=\d)/g, '$1' + thousands) +
          (decimalCount
            ? decimal +
              Math.abs(amount - i)
                .toFixed(decimalCount)
                .slice(2)
            : '')
      }
      return result
    } catch (e) {
      console.log('Error: formatMoney', e)
    }
  },
  formatDate(date, type = '') {
    let _date = null
    try {
      if (date !== undefined && date !== null) {
        if (type === 'datetime') {
          _date = moment(date).local().format('MM/DD/YYYY hh:mm a')
        } else {
          _date = moment(date).local().format('MM/DD/YYYY')
        }
      }
      return _date
    } catch (e) {
      console.log('Error: formatDate', e)
    }
  },
  isJson(str) {
    try {
      JSON.parse(str)
    } catch (e) {
      return false
    }
    return true
  },
  pastDayRange() {
    let _range = null
    _range = {
      start: moment().subtract(1, 'days').format('YYYY-MM-DD'),
      end: moment().format('YYYY-MM-DD')
    }
    return _range
  },
  weekRange() {
    let _range = null
    _range = {
      start: moment().startOf('week').format('YYYY-MM-DD'),
      end: moment().endOf('week').format('YYYY-MM-DD')
    }
    return _range
  },
  monthRange() {
    let _range = null
    _range = {
      start: moment().startOf('month').format('YYYY-MM-DD'),
      end: moment().endOf('month').format('YYYY-MM-DD')
    }
    return _range
  },
  qtrRange() {
    let _range = null
    let qtr = moment().quarter()
    _range = {
      start: moment().quarter(qtr).startOf('quarter').format('YYYY-MM-DD'),
      end: moment().quarter(qtr).endOf('quarter').format('YYYY-MM-DD')
    }
    return _range
  },
  past6months() {
    let _range = null
    _range = {
      start: moment().subtract(6, 'months').startOf('month').format('YYYY-MM-DD'),
      end: moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD')
    }
    return _range
  },
  sixmonthRange() {
    let _range = null
    let qtr = moment().quarter()
    if (qtr === 1 || qtr === 2) {
      _range = {
        start: moment().startOf('year').format('YYYY-MM-DD'),
        end: moment(`${moment().year()}-06-30`).format('YYYY-MM-DD')
      }
    } else {
      _range = {
        start: moment(`${moment().year()}-06-01`).format('YYYY-MM-DD'),
        end: moment().endOf('year').format('YYYY-MM-DD')
      }
    }
    return _range
  },
  pastYear() {
    let _range = null
    _range = {
      start: moment().subtract(12, 'months').startOf('month').format('YYYY-MM-DD'),
      end: moment().subtract(1, 'months').endOf('month').format('YYYY-MM-DD')
    }
    return _range
  },
  yearRange() {
    let _range = null
    _range = {
      start: moment().startOf('year').format('YYYY-MM-DD'),
      end: moment().endOf('year').format('YYYY-MM-DD')
    }
    return _range
  },
  dragElement(elmnt) {
    var pos1 = 0
    var pos2 = 0
    var pos3 = 0
    var pos4 = 0
    function dragMouseDown(e) {
      e = e || window.event
      e.preventDefault()
      // get the mouse cursor position at startup:
      pos3 = e.clientX
      pos4 = e.clientY
      document.onmouseup = closeDragElement
      // call a function whenever the cursor moves:
      document.onmousemove = elementDrag
    }
    function elementDrag(e) {
      e = e || window.event
      e.preventDefault()
      // calculate the new cursor position:
      pos1 = pos3 - e.clientX
      pos2 = pos4 - e.clientY
      pos3 = e.clientX
      pos4 = e.clientY
      // set the element's new position:
      if (elmnt.offsetTop - pos2 <= 0) {
        pos2 = 0
      }
      if (elmnt.offsetLeft - pos1 <= 0) {
        pos1 = 0
      }
      elmnt.style.top = elmnt.offsetTop - pos2 + 'px'
      elmnt.style.left = elmnt.offsetLeft - pos1 + 'px'
    }
    function closeDragElement() {
      // stop moving when mouse button is released:
      document.onmouseup = null
      document.onmousemove = null
    }
    if (document.getElementById(elmnt.id + 'header')) {
      // if present, the header is where you move the DIV from:
      document.getElementById(elmnt.id + 'header').onmousedown = dragMouseDown
    } else {
      // otherwise, move the DIV from anywhere inside the DIV:
      elmnt.onmousedown = dragMouseDown
    }
  },
  fileIcon(type) {
    let icon = ''
    switch (type) {
      // Media
      case 'image':
      case 'image/jpeg':
      case 'image/jpg':
      case 'image/pjpeg':
      case 'image/apng':
      case 'image/png':
      case 'image/gif':
      case 'image/bmp':
      case 'image/x-windows-bmp':
      case 'image/tiff':
        icon = 'far fa-file-image'
        break
      case 'audio':
      case 'audio/midi':
      case 'audio/mpeg':
      case 'audio/mpeg3':
      case 'audio/x-mpeg':
      case 'audio/wav':
      case 'audio/x-wav':
        icon = 'far fa-file-audio'
        break
      case 'video':
      case 'video/mpeg':
      case 'video/mpeg3':
      case 'video/x-mpeg':
      case 'video/avi':
      case 'video/msvideo':
        icon = 'far fa-file-video'
        break
      // Documents
      case 'application/pdf':
        icon = 'far fa-file-pdf'
        break
      case 'application/msword':
      case 'application/vnd.ms-word':
      case 'application/vnd.oasis.opendocument.text':
      case 'application/vnd.openxmlformats-officedocument.wordprocessingml':
        icon = 'far fa-file-word'
        break
      case 'application/excel':
      case 'application/x-excel':
      case 'application/x-msexcel':
      case 'application/vnd.ms-excel':
      case 'application/vnd.openxmlformats-officedocument.spreadsheetml':
      case 'application/vnd.oasis.opendocument.spreadsheet':
        icon = 'far fa-file-excel'
        break
      case 'application/mspowerpoint':
      case 'application/powerpoint':
      case 'application/vnd.ms-powerpoint':
      case 'application/vnd.openxmlformats-officedocument.presentationml':
      case 'application/vnd.oasis.opendocument.presentation':
        icon = 'far fa-file-powerpoint'
        break
      case 'text/plain':
        icon = 'far fa-file-text'
        break
      case 'text/html':
      case 'application/json':
        icon = 'far fa-file-code'
        break
      // Archives
      case 'application/gzip':
      case 'application/zip':
      case 'application/x-compress':
      case 'application/x-compressed':
      case 'application/x-zip-compressed':
      case 'multipart/x-gzip':
      case 'multipart/x-zip':
        icon = 'far fa-file-archive'
        break
      default:
        icon = 'far fa-file'
        break
    }
    return icon
  },
  htmlEncode(string) {
    return string
      .replace(/&/g, '&amp;')
      .replace(/</g, '&lt;')
      .replace(/>/g, '&gt;')
      .replace(/'/g, '&#39;')
      .replace(/"/g, '&#34;')
      .replace(/\//, '&#x2F;')
  },
  uuid() {
    return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
      (c ^ (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))).toString(16)
    )
  },
  isEmpty(object) {
    return Object.keys(object).length === 0
  },
  rand(min, max) {
    return Math.floor(Math.random() * (max - min + 1)) + min
  },
  generateHslaColors(saturation, lightness, alpha, amount) {
    let colors = []
    let huedelta = Math.trunc(360 / amount)

    for (let i = 0; i < amount; i++) {
      let hue = i * huedelta
      colors.push(`hsla(${hue},${saturation}%,${lightness}%,${alpha})`)
    }

    return colors
  },
  downloadTableAsCsv(el, filename) {
    var csv = []
    var rows = el.querySelectorAll('table tr')
    for (var i = 0; i < rows.length; i++) {
      var row = []
      var cols = rows[i].querySelectorAll('td, th')
      for (var j = 0; j < cols.length; j++) {
        let attribute = cols[j].getAttribute('data-attribute')
        let isIgnore = false
        if (attribute) {
          if (attribute === 'ignore') {
            isIgnore = true
          }
        }
        if (!isIgnore) {
          // Clean innertext to remove multiple spaces and jumpline (break csv)
          var data = cols[j].innerText.replace(/(\r\n|\n|\r)/gm, '').replace(/(\s\s)/gm, ' ')
          // Escape double-quote with double-double-quote (see https://stackoverflow.com/questions/17808511/properly-escape-a-double-quote-in-csv)
          data = data.replace(/"/g, '""')
          // Push escaped string
          row.push('"' + data + '"')
        }
      }
      csv.push(row.join(','))
    }
    var csvString = csv.join('\n')
    console.log('Comma Delimited Data', csv)
    // CSV file
    // var csvFile = new Blob([csv], { type: 'text/csv' })
    // Download link
    var downloadLink = document.createElement('a')
    // Hide download link
    downloadLink.style.display = 'none'
    downloadLink.setAttribute('target', '_blank')
    downloadLink.setAttribute('download', filename)
    // Create a link to the file
    downloadLink.setAttribute('href', 'data:text/csv;charset=utf-8,' + encodeURIComponent(csvString))
    // downloadLink.href = window.URL.createObjectURL(csvFile)
    // Add the link to DOM
    document.body.appendChild(downloadLink)
    // Click download link
    downloadLink.click()
    document.body.removeChild(downloadLink)
  }
}
