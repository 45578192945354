import { defineStore } from 'pinia'
import { api } from '@/plugins/api'
import { authStore } from './auth'
import storage from '@/plugins/storage'
import globals from '../plugins/globals'

const devicetypeDefault = {
  deviceType: null,
  category: null,
  deviceTypeName: null,
  internalName: null,
  internalProgram: null,
  FollowedWizard: null
}
export const lookupStore = defineStore('lookup', {
  state: () => ({
    lookups: {},
    userRoles: [],
    apps: [],
    technicians: null,
    queues: [],
    loading: {
      technicians: false,
      apps: false,
      lookups: false,
      userRoles: false,
      distributionChannels: false
    },
    adasMatrixCategories: [],
    services: [],
    devicetypes: [],
    distributionChannels: [],
    newDeviceType: JSON.parse(JSON.stringify(devicetypeDefault)),
    mapFields: [
      {
        id: 0,
        name: 'ADASLevel1AmtUSD',
        value: 'ADASLevel1AmtUSD'
      },
      {
        id: 1,
        name: 'ADASLevel2AmtUSD',
        value: 'ADASLevel2AmtUSD'
      },
      {
        id: 2,
        name: 'ADASLevel3AmtUSD',
        value: 'ADASLevel3AmtUSD'
      },
      {
        id: 3,
        name: 'CalibrationCheckUSD',
        value: 'CalibrationCheckUSD'
      },
      {
        id: 4,
        name: 'DiagnosticAssistanceAmtUSD',
        value: 'DiagnosticAssistanceAmtUSD'
      },
      {
        id: 5,
        name: 'FastPassAmtUSD',
        value: 'FastPassAmtUSD'
      },
      {
        id: 6,
        name: 'IVSMapAmtUSD',
        value: 'IVSMapAmtUSD'
      },
      {
        id: 7,
        name: 'ProgrammingAmtUSD',
        value: 'ProgrammingAmtUSD'
      },
      {
        id: 8,
        name: 'SubscriptionFeeUSD',
        value: 'SubscriptionFeeUSD'
      },
      {
        id: 9,
        name: 'billedamountUSD',
        value: 'billedamountUSD'
      }
    ]
  }),
  actions: {
    async getLookups(data) {
      const store = authStore()
      let _lookups = {}
      let _keys = data.keys.filter((k) => !this.lookups[k])
      let reset = false
      if (data.refresh) {
        reset = data.refresh
      }
      if (_keys.length || reset) {
        this.loading.lookups = true
        let results = await api.post(`lookup/byKeys`, {
          app: data.app ? data.app : null,
          keys: data.keys,
          firm: data.firm ? parseInt(data.firm) : parseInt(store.profile.firm),
          showInactive: false
        })
        this.loading.lookups = false
        if (results) {
          for (let key of data.keys) {
            _lookups[key] = []
            for (let item of results) {
              if (item.key.toLowerCase() === key.toLowerCase()) {
                item.codename = item.name
                item.name = item.value || item.name
                item.value = item.id || item.value
                item.isInactive = false
                _lookups[key].push(item)
              }
            }
          }
          this.lookups = JSON.parse(JSON.stringify(Object.assign(this.lookups, _lookups)))
        }
      }
      console.log('Got Lookups', this.lookups)
      return this.lookups
    },
    async getRoles(firm) {
      this.userRoles = []
      this.loading.userRoles = true
      this.userRoles = await api.get(`lookup/roles?firm=${firm}`)
      this.loading.userRoles = false
      return this.userRoles
    },
    async getApps() {
      this.apps = []
      this.loading.apps = true
      this.apps = await api.get('app/apps')
      this.loading.apps = false
      console.log('Got Apps', this.apps)
      return this.apps
    },
    async getUserQueueOptions() {
      let queues = await api.get('user/ticketQueues')
      console.log('Got User Queue Options', queues)
      this.queues = queues
    },
    getTechniciansOffice(drewId){
      if (this.technicians === null) {
        return ''
      }
      let found = this.technicians.filter((tech) => tech.value == drewId)
      console.log('Found Tech ', found)
      return (found.length > 0 ? found[0].office : 'No Office Found!')
    },
    async getTechnicians(reset) {
      if (this.technicians === null || reset) {
        this.technicians = []
        this.queues = []
        this.loading.technicians = true
        let techs = await api.get('lookup/technicians')
        if (techs) {
          if (Array.isArray(techs)) {
            techs = globals.stringSort(techs, 'LastName')
            techs.forEach((tech) => {
              this.technicians.push({
                id: tech.ContactID,
                name: `${tech.LastName}, ${tech.FirstName}`,
                value: tech.DrewTechnicianID,
                office: tech.Office
              })
            })
          }
        }
        storage.session.setItem('technicians', JSON.stringify(this.technicians))
        this.loading.technicians = false
        console.log('Got Technicians', this.technicians)
        console.log('Got Queues', this.queues)
      } else {
        this.technicians = JSON.parse(storage.session.getItem('technicians')) || null
      }
      return this.technicians
    },
    async getAdasMatrixCategories() {
      this.adasMatrixCategories = []
      this.adasMatrixCategories = await api.get('lookup/adasMatrixCategories')
      return this.adasMatrixCategories
    },
    async getServices() {
      this.services = []
      this.services = await api.get('service/services')
      return this.services
    },
    async getDeviceTypes() {
      this.devicetypes = []
      this.devicetypes = await api.get('service/devicetypes')
      return this.devicetypes
    },
    resetDistributionChannels() {
      this.distributionChannels = []
    },
    async searchDistributionChannels(search) {
      this.distributionChannels = []
      this.loading.distributionChannels = false
      let results = await api.get(`service/distributionChannelSearch?search=${search}`)
      if (results) {
        if (Array.isArray(results)) {
          results.forEach((element) => {
            this.distributionChannels.push({
              id: parseInt(element.ID),
              name: element.Name
            })
          })
        }
      }
      this.loading.distributionChannels = false
      console.log('Got Distribution Channels', this.distributionChannels)
      return this.distributionChannels
    },
    setDeviceType(data) {
      switch (data.name) {
        case 'add':
          this.newDeviceType = JSON.parse(JSON.stringify(devicetypeDefault))
          break
        case 'edit':
          this.newDeviceType = JSON.parse(JSON.stringify(data.value))
          break
        case 'category':
          this.newDeviceType.category = data.value
          break
        case 'deviceTypeName':
          this.newDeviceType.deviceTypeName = data.value
          break
        case 'internalName':
          this.newDeviceType.internalName = data.value
          break
        case 'internalProgram':
          this.newDeviceType.internalProgram = data.value
          break
        case 'followWizard':
          this.newDeviceType.FollowWizard = data.value
          break
      }
    }
  }
})
