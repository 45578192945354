<template>
  <form @submit.prevent="">
    <z-dialog :show="isVisible" :modal-size="modalSize">
      <template #header>
        <div class="custom-header">
          <div class="row">
            <div class="col-md-11">
              <h4 class="modal-title">
                {{ title }}
              </h4>
            </div>
            <div class="col-md-1">
              <a href="#" class="custom-close" @click="close">
                <i class="fa fa-times"></i>
              </a>
            </div>
          </div>
        </div>
      </template>
      <template #body>
        <div class="hostWrapper">
          <z-tabs v-model="currentUserTab" :tabs="userTabs" @change="setUserTab" />
          <div v-if="currentUserTab === 'Basic'" class="row p-2">
            <div class="col">
              <user-basic ref="user" :is-add="isAdd" :firm="firm" :show-firm="showFirm" @saved="saved" @changed="changed" @moved="moved" />
            </div>
          </div>
          <div v-if="currentUserTab === 'Locations'" class="row p-2">
            <user-locations ref="locations" :firm="firm" @saved="saved" @changed="changed" />
          </div>
          <div v-if="currentUserTab === 'Channels'" class="row p-2">
            <user-channels ref="channels" @saved="saved" @changed="changed" />
          </div>
          <div v-if="currentUserTab === 'Queues'" class="row p-2">
            <user-queues ref="queues" @saved="saved" @changed="changed" />
          </div>
        </div>
      </template>
      <template #footer>
        <div class="custom-footer">
          <div class="col-1">
            <span title="user/contact ids">{{ ids }}</span>
          </div>
          <div class="col">
            <button v-if="!isAdd" type="button" class="btn btn-outline-secondary" @click="changePassword">Change Password</button>
            <z-message v-model="message" class="alert alert-warning mt-2" />
          </div>
          <div class="col custom-right-align">
            <button type="button" class="btn btn-light" @click="close()">Cancel</button>
            <button v-show="isSave" class="btn btn-danger ms-2" @click="save(false)">Save</button>
            <button v-show="isSave" class="btn btn-danger ms-2" @click="save(true)">Save & Close</button>
          </div>
        </div>
      </template>
    </z-dialog>
  </form>
</template>
<script>
import { mapState, mapActions } from 'pinia'
import { userStore } from '@/stores/user'
import ZDialog from '@/components/utilities/elements/Dialog.vue'
import ZTabs from '@/components/utilities/elements/Tabs.vue'
import ZMessage from '@/components/utilities/elements/Message.vue'
import UserBasic from './UserBasic.vue'
import UserLocations from './UserLocations2.vue'
import UserChannels from './UserChannels.vue'
import UserQueues from './UserQueues.vue'
export default {
  name: 'DialogUser',
  components: {
    ZDialog,
    ZTabs,
    ZMessage,
    UserBasic,
    UserLocations,
    UserChannels,
    UserQueues
  },
  props: {
    modelValue: Object,
    showFirm: Object
  },
  emits: ['closing', 'saved', 'moved', 'changePassword'],
  data() {
    return {
      modalSize: 'modal-xl',
      isSave: false,
      currentUserTab: 'Basic',
      message: null
    }
  },
  computed: {
    ...mapState(userStore, ['user']),
    isVisible() {
      return this.modelValue !== null
    },
    userTabs() {
      let _tabs = ['Basic']
      if (!this.isAdd) {
        _tabs.push('Locations', 'Channels', 'Queues')
      }
      return _tabs
    },
    title() {
      let _title = ''
      if (this.modelValue) {
        if (this.modelValue.isAdd) {
          _title = 'Adding User'
        } else {
          _title = `Editing ${this.user.firstName} ${this.user.lastName}`
        }
      }
      return _title
    },
    ids() {
      let _ids = ''
      if (this.modelValue) {
        if (!this.modelValue.isAdd) {
          _ids = `${this.modelValue.user}`
        }
      }
      return _ids
    },
    firm() {
      let _id = null
      if (this.modelValue) {
        _id = this.modelValue.firm
      }
      return _id
    },
    isAdd() {
      let _is = false
      if (this.modelValue) {
        if (this.modelValue.isAdd) {
          _is = true
        }
      }
      return _is
    },
    isEdit() {
      let _is = false
      if (this.modelValue) {
        if (!this.modelValue.isAdd) {
          _is = true
        }
      }
      return _is
    },
    contactData() {
      let _data = null
      if (this.contact) {
        _data = {
          firm: this.contact.firm,
          contact: this.contact.contact
        }
      }
      return _data
    }
  },
  methods: {
    ...mapActions(userStore, ['setUser']),
    close() {
      this.isSave = false
      this.currentUserTab = 'Basic'
      this.$emit('closing')
    },
    setUserTab(value) {
      this.currentUserTab = value
    },
    saved() {
      this.$emit('saved')
    },
    changed() {
      this.isSave = true
    },
    changePassword() {
      let data = {
        firstName: this.user.firstName,
        lastName: this.user.lastName,
        user: this.user.user,
        username: this.user.username
      }
      this.$emit('changePassword', data)
    },
    moved() {
      this.$emit('moved')
    },
    async save(closeIt) {
      this.message = null
      let response = null
      if (this.currentUserTab === 'Basic') {
        response = await this.$refs.user.save()
      } else if (this.currentUserTab === 'Locations') {
        response = await this.$refs.locations.save()
      } else if (this.currentUserTab === 'Channels') {
        response = await this.$refs.channels.save()
      } else if (this.currentUserTab === 'Queues') {
        response = await this.$refs.queues.save()
      }
      if (response.valid) {
        this.isSave = false
        this.$emit('saved', response)
        if (closeIt) {
          this.currentUserTab = 'Basic'
          this.$emit('closing')
        }
      } else {
        this.message = response.msg
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.custom-header {
  width: 100%;
}
.custom-textarea {
  height: 200px;
}
.custom-close {
  float: right;
  margin-right: 10px;
  color: #000;
  cursor: pointer;
}
.custom-footer {
  width: 100%;
  display: inline-flex;
}
.custom-right-align {
  text-align: right;
}
.hostWrapper {
  max-height: 650px;
  overflow-x: hidden;
  overflow-y: auto;
  height: 600px;
}
</style>
