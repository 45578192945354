<template>
  <section>
    <div class="form-group">
      <div :class="{ 'form-group row': inline }">
        <div :class="{ 'col-sm-2 mt-1': inline }">
          <label v-if="label" :for="name" class="control-label">
            {{ label }}:
            <span v-if="hasAsterisk" class="text-danger">*</span>
          </label>
        </div>
        <div :class="{ 'col-sm-10': inline }">
          <div :class="{ error: errors.length > 0 }">
            <textarea
              :id="name"
              class="form-control"
              :rows="rows || 2"
              :name="name"
              :value="textValue || ''"
              :maxlength="maxlength || 500"
              :placeholder="placeholder"
              :readonly="readonly"
              :disabled="disabled"
              :tabindex="tabindex"
              @keyup="keyup"
              @input="change"
            />
            <div v-if="errors.length > 0">
              <div v-for="error of errors" :key="error.id" class="input-errors">
                <small class="error-msg text-danger">{{ error.msg }}</small>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: 'ZTextarea',
  props: {
    name: String,
    modelValue: String,
    label: String,
    validator: Object,
    disabled: Boolean,
    maxlength: Number,
    readonly: Boolean,
    placeholder: String,
    rows: [Number, String],
    inline: {
      type: Boolean,
      default: false
    },
    tabindex: [Number, String]
  },
  emits: ['keyup', 'update:modelValue'],
  computed: {
    textValue() {
      return this.modelValue
    },
    hasAsterisk() {
      let _has = false
      if (this.validator) {
        _has = true
      }
      return _has
    },
    errors() {
      let _data = []
      if (this.validator) {
        if (this.validator.$errors.length > 0) {
          let found = this.validator.$errors.find((x) => x.$property === this.name)
          if (found) {
            _data.push({
              id: 0,
              msg: found.$message
            })
          }
        }
      }
      return _data
    }
  },
  methods: {
    change($event) {
      this.$emit('update:modelValue', $event.target.value)
    },
    keyup($event) {
      this.$emit('keyup', $event.target.value)
    }
  }
}
</script>
