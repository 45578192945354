<template>
  <div class="tree-node">
    <div :id="id" class="row tree-node-wrapper" :class="selectedClass">
      <div class="tree-node-icon" :class="{ 'col-8': showAny, col: !showAny }" :style="indent" :z-data-isopen="isOpen">
        <span v-for="(item, idx) of indents" :key="idx" class="tree-line" :style="`left:${item * -20}px;`"></span>
        <div class="d-inline" @click="toggleChildren(tree)">
          <font-awesome-icon v-show="hasMembers && !showChildren" icon="fa-solid fa-plus-square" style="font-size: 20px; color: #198754" />
          <font-awesome-icon v-show="hasMembers && showChildren" icon="fa-solid fa-minus-square" style="font-size: 20px; color: #0d6efd" />
        </div>
        <div class="d-inline tree-node-label" :class="getClass()" @click="selectChild(tree)">
          <span class="ms-2">{{ name }}</span>
          <span v-if="showType && type !== ''" class="ms-2">- {{ type }}</span>
        </div>
      </div>
      <div v-if="showRemove" class="col-1 tree-node-remove">
        <a @click="removeFunction(id)">
          <i class="fa fa-times" title="remove"></i>
        </a>
      </div>
      <div v-if="showEdit" class="col-1 tree-node-edit">
        <a @click="editFunction(id, name)">
          <i class="fas fa-pencil-alt" title="edit"></i>
        </a>
      </div>
      <div v-if="showAdd" class="col-1 tree-node-add">
        <a @click="addFunction(id, name)">
          <i class="fa fa-plus" title="add"></i>
        </a>
      </div>
      <div v-if="showAdd2" class="col-1 tree-node-add">
        <a @click="add2Function(id, name)">
          <i class="fa fa-user-plus" title="add member"></i>
        </a>
      </div>
    </div>
    <div v-if="showChildren">
      <tree-node
        v-for="(member, idx) in members"
        :key="idx"
        v-model:is-open="member.isOpen"
        :tree="member"
        :depth="depth + 1"
        :show-type="showType"
        :add-settings="addSettings"
        :edit-settings="editSettings"
        :remove-settings="removeSettings"
        :add2-settings="add2Settings"
        :select-function="selectFunction"
        :class-function="classFunction"
      />
    </div>
  </div>
</template>

<script>
import { defineAsyncComponent } from 'vue'
export default {
  name: 'TreeNode',
  components: {
    TreeNode: defineAsyncComponent(() => import('./TreeNode.vue'))
  },
  props: {
    tree: Object,
    depth: {
      type: Number,
      default() {
        return 0
      }
    },
    showType: {
      type: Boolean,
      default: false
    },
    isOpen: {
      type: Boolean,
      default: false
    },
    addSettings: Object,
    editSettings: Object,
    removeSettings: Object,
    add2Settings: Object,
    selectFunction: Function,
    classFunction: Function
  },
  data() {
    return {
      selectedClass: ''
    }
  },
  emits: ['update:isOpen'],
  computed: {
    name: {
      get() {
        let _name = ''
        if (this.tree) {
          _name = this.tree.name
        }
        return _name
      }
    },
    type: {
      get() {
        let _type = ''
        if (this.tree) {
          if (this.tree.type) {
            _type = this.tree.type
          }
        }
        return _type
      }
    },
    id: {
      get() {
        let _id = ''
        if (this.tree) {
          _id = this.tree.id
        }
        return _id
      }
    },
    parent: {
      get() {
        let _id = ''
        if (this.tree) {
          _id = this.tree.parent
        }
        return _id
      }
    },
    members: {
      get() {
        let _members = ''
        if (this.tree) {
          _members = this.tree.members
        }
        return _members
      }
    },
    hasMembers: {
      get() {
        let _any = false
        if (this.tree) {
          if (this.tree.members) {
            if (this.tree.members.length > 0) {
              _any = true
            }
          }
        }
        return _any
      }
    },
    showAdd: {
      get() {
        let _show = false
        if (this.addSettings) {
          _show = this.addSettings.showAdd
        }
        return _show
      }
    },
    addFunction: {
      get() {
        let _function = null
        if (this.addSettings) {
          _function = this.addSettings.addFunction
        }
        return _function
      }
    },
    showEdit: {
      get() {
        let _show = false
        if (this.editSettings) {
          if (this.tree) {
            if (this.tree.name !== 'Calibrate') {
              _show = this.editSettings.showEdit
            }
          }
        }
        return _show
      }
    },
    editFunction: {
      get() {
        let _function = null
        if (this.editSettings) {
          _function = this.editSettings.editFunction
        }
        return _function
      }
    },
    showRemove: {
      get() {
        let _show = false
        if (this.removeSettings) {
          if (this.tree !== undefined && this.tree !== null) {
            if (this.tree.name !== 'Calibrate') {
              _show = this.removeSettings.showRemove
            }
          }
        }
        return _show
      }
    },
    removeFunction: {
      get() {
        let _function = null
        if (this.removeSettings) {
          _function = this.removeSettings.removeFunction
        }
        return _function
      }
    },
    showAdd2: {
      get() {
        let _show = false
        if (this.add2Settings) {
          _show = this.add2Settings.showAdd2
        }
        return _show
      }
    },
    add2Function: {
      get() {
        let _function = null
        if (this.add2Settings) {
          _function = this.add2Settings.add2Function
        }
        return _function
      }
    },
    iconClasses() {
      return {
        'fa-plus-square': !this.showChildren,
        'fa-minus-square': this.showChildren
      }
    },
    indents() {
      let _items = []
      if (this.depth) {
        for (let idx = this.depth; idx >= 0; idx--) {
          _items.push(idx)
        }
      }
      return _items
    },
    indent() {
      return { transform: `translate(${this.depth * 20}px)` }
    },
    showAny() {
      let _show = false
      if (this.showAdd || this.showEdit || this.showAdd2 || this.showRemove) {
        _show = true
      }
      return _show
    },
    showChildren: {
      get() {
        return this.isOpen
      },
      set(value) {
        this.$emit('update:isOpen', value)
      }
    }
  },
  methods: {
    selectChild() {
      if (this.selectFunction) {
        this.selectFunction(this.tree)
      }
    },
    toggleChildren() {
      this.showChildren = !this.showChildren
      if (this.selectFunction) {
        this.selectFunction(this.tree)
      }
    },
    getClass() {
      return this.classFunction(this.type)
    }
  }
}
</script>

<style lang="scss" scoped>
.tree-node-wrapper {
  border-bottom: 1px solid #b8b8b8;
  padding-top: 10px;
  padding-bottom: 10px;
  cursor: pointer;
}
.tree-node-icon {
  margin-left: 5px;
}
.tree-node-wrapper:hover {
  background-color: rgb(219, 219, 219);
  cursor: pointer;
}
.tree-node-selection {
  background-color: rgb(255, 255, 187);
}
.tree-node-remove {
  text-align: center;
  color: #b30505;
}
.tree-node-add {
  text-align: center;
  color: #127515;
}
.tree-node-edit {
  text-align: center;
  color: rgb(0, 38, 163);
}
.tree-node-selected {
  background-color: rgba(0, 0, 0, 0.5);
}
.custom-dark-green {
  color: rgb(17, 66, 17) !important;
  font-weight: 500 !important;
}
.custom-orange {
  color: rgb(172, 112, 0) !important;
  font-weight: 700 !important;
}
.custom-blue {
  color: rgb(29, 72, 216) !important;
  font-weight: 500 !important;
}
.custom-blue-italic {
  color: rgb(52, 99, 253) !important;
  font-weight: 500 !important;
  font-style: italic;
}
.custom-green {
  color: rgb(3, 80, 0) !important;
  font-weight: 500 !important;
}
.custom-green-italic {
  color: rgb(5, 148, 0) !important;
  font-weight: 500 !important;
  font-style: italic;
}
.custom-black-italic {
  font-style: italic;
  font-weight: 500 !important;
}
.tree-line {
  position: absolute;
  height: 100%;
  border-left: 1px solid;
  border-color: rgba(0, 0, 0, 0.2);
}
.tree-node-label {
  position: relative;
  left: 8px;
  padding: 4px;
  :hover {
    left: -6px;
    padding: 5px;
    border: 1px dashed rgba(0, 0, 0, 0.5);
  }
}
</style>
