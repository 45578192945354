<template>
  <div>
    <nav-top />
    <div class="container-fluid">
      <div class="row flex-xl-nowrap">
        <main class="p-3 col-12" role="main">
          <slot />
        </main>
      </div>
    </div>
    <hr />
    <pre class="text-right text-muted px-4"> v{{ version }} </pre>
  </div>
</template>

<script>
import NavTop from '@/layouts/navigation/NavTop.vue'

export default {
  name: 'LayoutDefault',
  components: {
    NavTop
  },
  computed: {
    version() {
      let _version = import.meta.env.VITE_VERSION
      return _version
    }
  }
}
</script>
