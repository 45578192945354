<template>
  <div class="custom-location-wrapper">
    <span>User's Current Locations:</span>
    <ul class="custom-list">
      <li v-for="firm of userFirms" :key="firm.userFirm">
        <span>{{ firm.firmName }}</span>
        <span v-if="firm.roleName !== null" class="ms-2">({{ firm.roleName }})</span>
        <span v-if="firm.isPrimary" class="ms-2">*primary*</span>
      </li>
    </ul>
  </div>
</template>
<script>
import { mapState, mapActions } from 'pinia'
import { userStore } from '@/stores/user'
export default {
  name: 'UserCurrentLocations',
  props: {
    user: Number
  },
  async mounted() {
    this.getUserCurrentFirms(this.user)
  },
  computed: {
    ...mapState(userStore, ['loading', 'userFirms']),
    isLoading() {
      return this.loading.userFirms
    }
  },
  methods: {
    ...mapActions(userStore, ['getUserCurrentFirms'])
  }
}
</script>
<style lang="scss" scoped>
.custom-location-wrapper {
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 200px;
  height: 200px;
}
.custom-list {
  list-style: none;
}
</style>
