<template>
  <h5 v-show="showTitle">Users</h5>
  <span>{{ title }}</span>
  <div class="row">
    <!-- Search -->
    <div class="col-3">
      <z-search v-model.trim="searchTerm" class="mt-2" name="search" label="" placeholder="Search" :readonly="isLoading" />
    </div>
    <!-- Filter -->
    <!-- <div class="col-md-4">
        <div class="row mt-2">
          <i class="fas fa-filter col-md-1 filterIcon"></i>
          <dt-select class="col-md-10" v-model="filterBy" :options="filterOptions"></dt-select>
        </div>
      </div> -->
    <!-- Placeholder -->
    <div class="col-3">
      <div class="row mt-2">
        <div>
          <button class="btn btn-primary btn-sm" @click="addUser">Add User</button>
        </div>
        <div v-show="isChecked" class="ml-2">
          <button class="btn btn-primary btn-sm" @click="deleteUser">Delete</button>
        </div>
        <div v-show="isChecked" class="ml-2">
          <button class="btn btn-primary btn-sm" @click="activateUser">Toggle Active</button>
        </div>
      </div>
    </div>
    <div class="col">
      <div class="row">
        <!-- Pagination -->
        <div class="col-2">
          <z-select v-model="pageSize" :options="pageSizes" label="" name="pageSize" :disabled="isLoading" />
        </div>
        <div class="col-3"></div>
        <div class="col-5">
          <nav>
            <z-paginate v-model="page" :page-count="pages" />
          </nav>
        </div>
        <div class="col-2 text-right">
          <h5 class="mt-0 me-0" title="# items">{{ numItems }}</h5>
          <span class="custom-items">items</span>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from 'pinia'
import { userStore } from '@/stores/user'
import ZSearch from '@/components/utilities/elements/Search.vue'
import ZSelect from '@/components/utilities/elements/Select.vue'
import ZPaginate from '@/components/utilities/elements/Paginate.vue'

export default {
  name: 'UserHeader',
  components: {
    ZSearch,
    ZSelect,
    ZPaginate
  },
  props: {
    pages: Number,
    pageSizes: Array,
    numItems: Number,
    showTitle: {
      type: Boolean,
      default: true
    },
    firm: Object
  },
  data() {
    return {
      isChecked: false,
      title: ''
    }
  },
  watch: {
    isUserChecked() {
      this.isChecked = false
      if (this.isUserChecked) {
        this.isChecked = true
      }
    },
    firm() {
      this.title = ''
      if (this.firm) {
        this.title = ` for ${this.firm.name}`
      }
    }
  },
  emits: ['search', 'add', 'delete', 'activate'],
  computed: {
    ...mapState(userStore, ['loading', 'listParams']),
    isLoading() {
      return this.loading.users
    },
    page: {
      get() {
        return this.listParams.page
      },
      set(value) {
        this.setListParam({
          name: 'page',
          value: parseInt(value)
        })
        this.search('')
      }
    },
    pageSize: {
      get() {
        return this.listParams.pageSize
      },
      set(value) {
        this.setListParam({
          name: 'pageSize',
          value: parseInt(value)
        })
        this.page = 1
      }
    },
    filterBy: {
      get() {
        return this.listParams.filterBy
      },
      set(value) {
        this.setListParam({
          name: 'filterBy',
          value: value
        })
        this.page = 1
      }
    },
    searchTerm: {
      get() {
        return this.listParams.searchTerm
      },
      set(value) {
        this.setListParam({
          name: 'searchTerm',
          value: value
        })
        this.page = 1
        this.change()
      }
    }
  },
  methods: {
    ...mapActions(userStore, ['setListParam']),
    change() {
      this.search(this.listParams.searchTerm)
    },
    search() {
      this.$emit('search', this.page, this.listParams.searchTerm)
    },
    addUser() {
      this.$emit('add')
    },
    deleteUser() {
      this.$emit('delete')
    },
    activateUser() {
      this.$emit('activate')
    }
  }
}
</script>
<style lang="scss">
.filterIcon {
  top: 8px;
  font-size: 1.2em;
}
.filterListing {
  float: left;
}
.custom-items {
  right: 10px;
  top: -10px;
  position: relative;
}
</style>
