<template>
  <p>Select those queues this user will have access to:</p>
  <p class="text-danger">Note: They will receive all queue's from their office as well and are marked with an asterisk (*).</p>
  <div class="custom-list-wrapper">
    <div class="row">
      <div class="col-8">
        <div class="input-group mb-3">
          <span class="input-group-text">Office:</span>
          <input type="text" disabled="disabled" class="form-control" :value="techniciansOffice" />
        </div>
      </div>
      <div class="col-8">
        <div class="input-group mb-3">
          <span class="input-group-text">Queue:</span>
          <select id="queue" v-model="queue" class="form-control" @change="changed">
            <option v-for="item of queues" :key="item.ticketQueue" :value="item.ticketTypeName">
              {{ item.ticketTypeName }}
            </option>
          </select>
          <button class="btn btn-primary" type="button" tabindex="-1" :disabled="!gotQueue" @click="addQueue">Add</button>
        </div>
      </div>
      <div class="col">
        <button v-if="isChecked" class="btn btn-danger btn-sm" @click="removeQueues">remove</button>
      </div>
    </div>
    <ul class="custom-list">
      <li v-for="(item, idx) of currentQueues" :key="idx">
        <div class="row">
          <div class="col">
            <input
              :id="item.ticketQueue"
              v-model="item.isChecked"
              type="checkbox"
              :true-value="true"
              :false-value="false"
              class="custom-checkbox me-2"
              @click="changed"
              :disabled="item.officeQueue"
            />
            <span>{{ item.ticketTypeName}}</span><span v-if="item.officeQueue" class="text-danger">*</span>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>
<script>
import { mapState, mapActions } from 'pinia'
import { authStore } from '@/stores/auth'
import { userStore } from '@/stores/user'
import { lookupStore } from '@/stores/lookup'
export default {
  name: 'UserQueues',
  data() {
    return {
      searchTerm: null,
      isLoading: false,
      queue: null,
      techniciansOffice: null,
    }
  },
  async mounted() {
    await this.init()
  },
  emits: ['changed', 'saved'],
  computed: {
    ...mapState(authStore, ['profile']),
    ...mapState(userStore, ['loading', 'user', 'userQueues']),
    ...mapState(lookupStore, ['lookups', 'queues', 'technicians']),
    currentQueues() {
      let _queues = []
      if (this.userQueues) {
        this.userQueues.forEach((queue) => {
          if (!queue.isRemove) {
            _queues.push(queue)
          }
        })
      }
      return _queues
    },
    isChecked() {
      let _is = false
      let found = this.userQueues.filter((x) => x.isChecked)
      if (found) {
        if (found.length > 0) {
          _is = true
        }
      }
      return _is
    },
    gotQueue() {
      let _got = false
      if (this.queue) {
        if (this.queue.trim() !== '') {
          _got = true
        }
      }
      return _got
    },
    
  },
  methods: {
    ...mapActions(userStore, ['getUserQueues', 'saveUserQueues', 'setUserQueue', 'removeUserQueues']),
    ...mapActions(lookupStore, ['resetDistributionQueues', 'searchDistributionQueues', 'getUserQueueOptions', 'getTechniciansOffice']),
    async init() {
      console.log('Get User', this.user)
      this.techniciansOffice = this.getTechniciansOffice(this.user.techId)
      await this.getUserQueues(this.user.user, this.techniciansOffice)
      await this.getTicketTypes()
    },
    changed() {
      this.$emit('changed')
    },
    async search() {
      this.resetDistributionQueues()
      if (this.searchTerm) {
        if (this.searchTerm.trim() !== '') {
          this.isLoading = true
          await this.searchDistributionQueues(this.searchTerm)
          this.isLoading = false
        }
      }
    },
    addQueue() {
      var desiredQueue = this.queues.find((x) => x.ticketTypeName === this.queue)
      if (this.queue !== null) {
        let data = {
          user: this.user.user,
          ticketTypeName: desiredQueue.ticketTypeName,
          ticketQueue: desiredQueue.ticketQueue,
          ticketQueueName: desiredQueue.ticketQueueName,
        }
        this.setUserQueue(data)
        this.queue = null        
      }
    },
    removeQueues() {
      this.removeUserQueues()
    },
    async save() {
      let response = {
        valid: false,
        user: this.user.user,
        msg: null
      }
      console.log('Saving User Queues', this.userQueues)
      await this.saveUserQueues()
      await this.init()
      response.valid = true
      this.$emit('saved')
      return response
    },
    async getTicketTypes(){
      this.getUserQueueOptions()
    }
  }
}
</script>
<style lang="scss" scoped>
.custom-list-wrapper {
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 400px;
  height: 400px;
}
.custom-list {
  list-style: none;
  li {
    padding-top: 5px;
    padding-bottom: 5px;
  }
}
</style>
