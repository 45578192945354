<template>
  <div class="container">
    <div class="mx-auto" style="width: 300px">
      <div class="custom-logo">
        <img src="~@/assets/images/Opus_IVS_PoweredBy_Autotechcelerators_256.png" alt="Launchpad" width="256" />
      </div>
      <div class="card">
        <h5 class="card-header">Login to Launchpad</h5>
        <div class="card-body">
          <form-login />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import FormLogin from '@/components/auth/Login.vue'
export default {
  name: 'LoginView',
  components: {
    FormLogin
  }
}
</script>
<style lang="scss" scoped>
.custom-logo {
  width: 300px;
  text-align: center;
  margin-bottom: 20px;
}
</style>
