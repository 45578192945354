<template>
  <form @submit.prevent="">
    <z-dialog :show="isVisible">
      <template #header>
        <div class="custom-header">
          <div class="row">
            <div class="col-md-11">
              <h4 class="modal-title">
                {{ title }}
              </h4>
            </div>
            <div class="col-md-1">
              <a href="#" class="custom-close" @click="close">
                <i class="fa fa-times"></i>
              </a>
            </div>
          </div>
        </div>
      </template>
      <template #body>
        <div class="row">
          <div class="col">
            <z-input v-model="channelName" name="channelName" label="Channel Name" @keyup="changed" />
          </div>
        </div>
      </template>
      <template #footer>
        <div class="custom-footer">
          <div class="col">
            <z-message v-model="message" class="alert alert-warning mt-2" />
          </div>
          <div class="col custom-right-align">
            <button type="button" class="btn btn-light" :disabled="isImporting" @click="close()">Cancel</button>
            <button v-if="isSave" class="btn btn-danger ms-2" :disabled="isImporting" @click="save">Save</button>
          </div>
        </div>
      </template>
    </z-dialog>
  </form>
</template>
<script>
import { mapActions } from 'pinia'
import { locationStore } from '@/stores/location'
import ZDialog from '@/components/utilities/elements/Dialog.vue'
import ZInput from '@/components/utilities/elements/Input.vue'
import ZMessage from '@/components/utilities/elements/Message.vue'
export default {
  name: 'DialogLocation',
  components: {
    ZDialog,
    ZInput,
    ZMessage
  },
  props: {
    modelValue: {
      type: Object
    }
  },
  data() {
    return {
      modalSize: 'modal-lg',
      isSave: false,
      message: null,
      channelName: null
    }
  },
  emits: ['closing', 'saved'],
  computed: {
    isVisible() {
      return this.modelValue !== null
    },
    title() {
      let _title = 'Add Distributor Channel'
      return _title
    }
  },
  methods: {
    ...mapActions(locationStore, ['saveChannel']),
    close() {
      this.isSave = false
      this.$emit('closing')
    },
    changed() {
      this.isSave = true
    },
    async save() {
      this.message = null
      if (this.isSave) {
        if (this.channelName) {
          if (this.channelName !== '') {
            let data = {
              channel: null,
              channelName: this.channelName,
              sort: 0,
              isInactive: false
            }
            await this.saveChannel(data)
            this.$emit('saved')
          } else {
            alert('Please provide a new Distributor name.')
          }
        } else {
          alert('Please provide a new Distributor name.')
        }
        this.message = null
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.custom-header {
  width: 100%;
}
.custom-close {
  float: right;
  margin-right: 10px;
  color: #000;
  cursor: pointer;
}
.custom-footer {
  width: 100%;
  display: inline-flex;
}
</style>
