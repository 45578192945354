<template>
  <div>
    <h4>Welcome</h4>
  </div>
  <div class="row">
    <div class="col">
      <p>The purpose of Launchpad is to centralize management of OPUS IVS client locations and users across OPUS IVS application solutions.</p>
      <p>
        Because locations/users are centralized, users will be able to log into each application using the same credentials. The goal is to only
        require the user to log in once (per day) for all applications.
      </p>
      <p>In the future, Launchpad will also be used to perform rollup reporting and dashboarding, as well as, data import/export and transfer.</p>
    </div>
  </div>
</template>
<script>
export default {
  name: 'HomePage'
}
</script>
<style lang="scss" scoped></style>
