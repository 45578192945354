<template>
  <div class="row">
    <div v-if="isAdd" class="col p-3">
      <z-input v-model="username" label="Username (email address)" :validator="v$" @keyup="changed" @change="changed" @blur="verify" />
    </div>
    <div v-else class="col p-3 custom-username">
      <span>Username:</span>
      <input v-if="editingUsername" v-model="newUsername" name="newUsername" label="Username (email address)" class="form-control ms-2 me-2" />
      <span v-else class="ms-2 me-2">{{ username }}</span>
      <a v-show="!editingUsername" href="#" @click="editUsername">
        <i class="fa-solid fa-pencil-alt"></i>
      </a>
      <a v-show="editingUsername" href="#" @click="saveUsername">
        <i class="fa-solid fa-save"></i>
      </a>
    </div>
    <div class="col"></div>
  </div>
  <div v-if="isAdd" class="row">
    <div class="col">
      <z-input v-model="password" name="password" label="Password" :validator="v$" @keyup="changed" @change="changed" />
    </div>
    <div class="col">
      <z-input
        v-model="password2"
        name="password2"
        label="Password (again)"
        :validator="v$"
        @keyup="changed"
        @change="changed"
        @blur="validatePassword(true)"
      />
    </div>
  </div>
  <div class="row">
    <div class="col">
      <z-input v-model="firstName" name="firstName" label="First Name" :validator="v$" @keyup="changed" @change="changed" />
    </div>
    <div class="col">
      <z-input v-model="lastName" name="lastName" label="Last Name" :validator="v$" @keyup="changed" @change="changed" />
    </div>
  </div>
  <div class="row">
    <div class="col">
      <z-select v-model="technician" name="technicians" label="Existing Techs" :options="technicians" @change="changedTech" />
    </div>
    <div class="col">
      <z-input v-model="techId" name="techId" label="Tech Id" type="number" @keyup="changed" @change="changed" />
    </div>
    <div class="col">
      <z-input v-model="contactId" name="contactId" label="Contact Id" type="number" @keyup="changed" @change="changed" />
    </div>
  </div>
  <div class="row">
    <div class="col-4">
      <z-select v-model="roles" name="roles" label="User Role" label-field="description" :options="userRoles" :validator="v$" @change="changed" />
    </div>
    <div v-if="!isAdd" class="col pt-4">
      <div class="row">
        <div v-if="isFirm" class="col-5">
          <input v-model="isFirmContact" type="checkbox" true-value="true" false-value="false" class="custom-checkbox me-2" @click="changed" />
          <span>This Location's primary contact?</span>
        </div>
        <div class="col">
          <input v-model="isInactive" type="checkbox" true-value="true" false-value="false" class="custom-checkbox me-2" @click="changed" />
          <span>Inactive?</span>
        </div>
      </div>
    </div>
    <div v-else class="col"></div>
  </div>
  <div v-if="!isAdd" class="row mt-3">
    <div class="col">
      <p>
        Check those App Solutions this user will be using. At the same time, if the user exists within an App Solution, configure their Ids here. To
        assist, use the 'search' button/icon in attempt to find the user Id (using their username). You can also manually add the Id.
      </p>
    </div>
  </div>
  <div v-if="!isAdd" class="row">
    <!-- ADAS MAP Setting -->
    <div v-if="isAdasAvailable" class="col-4">
      <div class="input-group">
        <input v-model="adasId" class="form-control" name="adasId" placeholder="ADAS Map Id" type="number" @keyup="changed" @change="changed" />
        <button class="btn btn-link" title="Search ADAS Map for location" @click="searchApp('ADAS')">
          <font-awesome-icon icon="fa-solid fa-magnifying-glass-location custom-search-icon" style="font-size: 25px" />
        </button>
        <button class="btn btn-primary btn-sm" title="Push Location to ADAS Map" :disabled="isPushing" @click="pushApp('ADAS')">
          <div v-show="isPushing" class="custom-icon">
            <i class="fas fa-spinner fa-spin"></i>
          </div>
          Push
        </button>
      </div>
    </div>
    <!-- CalCopilot Setting -->
    <div v-if="isCalCopilotAvailable" class="col-4">
      <div class="input-group">
        <input
          v-model="calcopilotId"
          class="form-control"
          name="calcopilotId"
          placeholder="CalCopilot Id"
          type="number"
          @keyup="changed"
          @change="changed"
        />
        <button class="btn btn-link" title="Search CalCopilot for location" @click="searchApp('Calibrate')">
          <font-awesome-icon icon="fa-solid fa-magnifying-glass-location custom-search-icon" style="font-size: 25px" />
        </button>
        <button class="btn btn-primary btn-sm" title="Push Location to CalCopilot" :disabled="isPushing" @click="pushApp('Calibrate')">
          <div v-show="isPushing" class="custom-icon">
            <i class="fas fa-spinner fa-spin"></i>
          </div>
          Push
        </button>
      </div>
    </div>
    <!-- TestDrive Copilot Setting -->
    <div v-if="isTestDriveAvailable" class="col-4">
      <div class="input-group">
        <input
          v-model="adtestdriveIdasId"
          class="form-control"
          name="testdriveId"
          placeholder="Test Drive Copilot Id"
          type="number"
          @keyup="changed"
          @change="changed"
        />
        <button class="btn btn-link custom-search-icon" title="Search Test Drive Copilot for location" @click="searchApp('TestDrive')">
          <font-awesome-icon icon="fa-solid fa-magnifying-glass-location custom-search-icon" style="font-size: 25px" />
        </button>
        <button class="btn btn-primary btn-sm" title="Push Location to Test Drive Copilot" :disabled="isPushing" @click="pushApp('TestDrive')">
          <div v-show="isPushing" class="custom-icon">
            <i class="fas fa-spinner fa-spin"></i>
          </div>
          Push
        </button>
      </div>
    </div>
    <div class="row">
      <div class="col">
        <p>Otherwise, if the user does not exist in the App Solution, then use the 'Push' button to push the user over to that solution.</p>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col">
        <div class="row">
          <div class="col">
            <p>To move this user to another, search and select the location (to move to) and click the 'Move' button.</p>
          </div>
        </div>
        <div class="row">
          <div class="col-4">
            <Multiselect
              ref="searchLocation"
              v-model="foundLocation"
              class="form-control custom-multiselect"
              placeholder="type to search"
              :close-on-select="true"
              :filter-results="false"
              :min-chars="1"
              :resolve-on-load="false"
              :delay="500"
              :searchable="true"
              :open-direction="'top'"
              :options="
                async (query) => {
                  return await fetchLocations(query)
                }
              "
              label="firmName"
              value-prop="firm"
              @select="selectedLocation"
            />
          </div>
          <div class="col-1">
            <button class="btn btn-primary btn-sm" @click="reassignUser">Move</button>
          </div>
          <div class="col"></div>
        </div>
      </div>
    </div>
  </div>
  <dialog-add-user-to-app v-model="actionAddUser" @closing="closeModal" />
</template>
<script>
import { mapState, mapActions } from 'pinia'
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { lookupStore } from '@/stores/lookup'
import { userStore } from '@/stores/user'
import { locationStore } from '@/stores/location'
import ZInput from '@/components/utilities/elements/Input.vue'
import ZSelect from '@/components/utilities/elements/Select.vue'
import Multiselect from '@vueform/multiselect'
import DialogAddUserToApp from './DialogAddUserToApp.vue'
export default {
  name: 'UserBasic',
  components: {
    ZInput,
    ZSelect,
    Multiselect,
    DialogAddUserToApp
  },
  props: {
    isAdd: {
      type: Boolean,
      default: false
    },
    showFirm: Object,
    firm: [Object, Number]
  },
  data() {
    return {
      isPushing: false,
      rules: {
        username: { required },
        firstName: { required },
        lastName: { required },
        roles: { required }
      },
      newUsername: '',
      editingUsername: false,
      reason: null,
      users: [],
      v$: null,
      actionAddUser: null,
      foundLocation: null
    }
  },
  beforeMount() {
    this.v$ = useVuelidate(this.rules, this.user)
    this.newUsername = ''
    this.editingUsername = false
  },
  mounted() {
    console.log('Show User', this.user)
  },
  emits: ['message', 'changed', 'saved', 'moved'],
  computed: {
    ...mapState(lookupStore, ['userRoles', 'technicians']),
    ...mapState(locationStore, ['location', 'foundLocations']),
    ...mapState(userStore, ['loading', 'user']),
    isLoading() {
      return this.loading.user
    },
    isAdasAvailable() {
      let _is = false
      if (this.location) {
        if (this.location.availableApps) {
          let found = this.location.availableApps.find((x) => x.appNumber.includes('ADAS'))
          if (found) {
            _is = true
          }
        }
      }
      return _is
    },
    isCalCopilotAvailable() {
      let _is = false
      if (this.location) {
        if (this.location.availableApps) {
          let found = this.location.availableApps.find((x) => x.appNumber.includes('Calibrate'))
          if (found) {
            _is = true
          }
        }
      }
      return _is
    },
    isTestDriveAvailable() {
      let _is = false
      if (this.location) {
        if (this.location.availableApps) {
          let found = this.location.availableApps.find((x) => x.appNumber.includes('TestDrive'))
          if (found) {
            _is = true
          }
        }
      }
      return _is
    },
    adasId: {
      get() {
        return this.user.adasId
      },
      set(value) {
        this.setUser({
          name: 'adasId',
          value: parseInt(value)
        })
      }
    },
    calcopilotId: {
      get() {
        return this.user.calcopilotId
      },
      set(value) {
        this.setUser({
          name: 'calcopilotId',
          value: parseInt(value)
        })
      }
    },
    testdriveId: {
      get() {
        return this.user.testdriveId
      },
      set(value) {
        this.setUser({
          name: 'testdriveId',
          value: parseInt(value)
        })
      }
    },
    username: {
      get() {
        return this.user.username
      },
      set(value) {
        this.setUser({
          name: 'username',
          value: value
        })
        this.setUser({
          name: 'emailAddress',
          value: value
        })
      }
    },
    password: {
      get() {
        return this.user.password
      },
      set(value) {
        this.setUser({
          name: 'password',
          value: value
        })
      }
    },
    password2: {
      get() {
        return this.user.password2
      },
      set(value) {
        this.setUser({
          name: 'password2',
          value: value
        })
      }
    },
    roles: {
      get() {
        return parseInt(this.user.roles)
      },
      set(value) {
        this.setUser({
          name: 'roles',
          value: value
        })
      }
    },
    firstName: {
      get() {
        return this.user.firstName
      },
      set(value) {
        this.setUser({
          name: 'firstName',
          value: value
        })
      }
    },
    lastName: {
      get() {
        return this.user.lastName
      },
      set(value) {
        this.setUser({
          name: 'lastName',
          value: value
        })
      }
    },
    technician: {
      get() {
        return this.techId
      },
      set(value) {
        this.setUser({
          name: 'techId',
          value: parseInt(value)
        })
        let found = this.technicians.find((x) => parseInt(x.value) === parseInt(this.technician))
        if (found) {
          this.setUser({
            name: 'contactId',
            value: parseInt(found.id)
          })
        }
      }
    },
    techId: {
      get() {
        return this.user.techId
      },
      set(value) {
        this.setUser({
          name: 'techId',
          value: parseInt(value)
        })
      }
    },
    contactId: {
      get() {
        return this.user.contactId
      },
      set(value) {
        this.setUser({
          name: 'contactId',
          value: parseInt(value)
        })
      }
    },
    isInactive: {
      get() {
        return this.user.isInactive
      },
      set(value) {
        this.setUser({
          name: 'isInactive',
          value: value
        })
      }
    },
    isFirm() {
      let _is = false
      if (this.showFirm) {
        _is = true
      }
      return _is
    },
    isFirmContact: {
      get() {
        let _is = false
        if (this.showFirm) {
          if (this.location) {
            if (parseInt(this.location.userId) === parseInt(this.user.user)) {
              _is = true
            }
          }
        }
        return _is
      },
      set() {
        this.setUserContact({
          user: parseInt(this.user.user),
          firstname: this.user.firstName,
          lastname: this.user.lastName
        })
        this.emitter.emit('firm-contact-changed', true)
      }
    }
  },
  methods: {
    ...mapActions(userStore, ['getUser', 'setUser', 'saveUser', 'verifyUsername', 'searchAppUser', 'pushUser', 'moveUser']),
    ...mapActions(locationStore, ['setUserContact', 'searchLocations']),
    closeModal() {
      this.actionAddUser = null
    },
    async verify() {
      let results = await this.verifyUsername(this.username)
      if (parseInt(results.isThere) === 1) {
        this.username = null
        alert('Username already exists, please verify and try again.')
      }
    },
    changed() {
      this.$emit('changed')
    },
    changedTech() {
      this.$emit('changed')
    },
    editUsername() {
      this.newUsername = this.username
      this.editingUsername = true
    },
    async saveUsername() {
      this.setUser({
        name: 'username',
        value: this.newUsername
      })
      this.editingUsername = false
      await this.save()
    },
    async searchApp(app) {
      let found = null
      let name = ''
      this.users = []
      switch (app) {
        case 'ADAS':
          console.log('Searching ADAS', app)
          name = 'ADAS Copilot'
          found = await this.searchAppUser(app)
          console.log('Found ADAS User', found)
          if (found) {
            if (found.length > 1) {
              // show users
              this.users = found
            } else {
              this.adasId = found[0].user
              this.changed()
            }
          }
          break
        case 'Calibrate':
          console.log('Searching Calibrate', app)
          name = 'Cal Copilot'
          found = await this.searchAppUser(app)
          console.log('Found Calibrate User', found)
          if (found) {
            if (found.length > 1) {
              // show users
              this.users = found
            } else {
              this.calcopilotId = found[0].user
              this.changed()
            }
          }
          break
        case 'TestDrive':
          name = 'Test Drive Copilot'
          console.log('Searching TestDrive', app)
          found = await this.searchAppUser(app)
          console.log('Found TestDrive User', found)
          if (found) {
            if (found.length > 1) {
              // show users
              this.users = found
            } else {
              this.testdriveId = found[0].user
              this.changed()
            }
          }
          break
      }
      if (found === undefined || found === null) {
        alert(`Could not find User in ${name}. Please verify username/first/last, then try again. Otherwise, you may have to 'Add User'.`)
      } else {
        alert('Found User')
      }
    },
    async pushApp(app) {
      let data = {
        user: this.user.user,
        appAdas: null,
        appCalibrate: null,
        appTestDrive: null
      }
      let found = null
      switch (app) {
        case 'ADAS':
          found = this.location.availableApps.find((x) => x.appNumber.includes('ADAS'))
          if (found) {
            data.appAdas = found.appNumber
          }
          break
        case 'Calibrate':
          found = this.location.availableApps.find((x) => x.appNumber.includes('Calibrate'))
          if (found) {
            data.appCalibrate = found.appNumber
          }
          break
        case 'TestDrive':
          found = this.location.availableApps.find((x) => x.appNumber.includes('TestDrive'))
          if (found) {
            data.appTestDrive = found.appNumber
          }
          break
      }
      if (data.appAdas !== null || data.appCalibrate !== null || data.appTestDrive !== null) {
        console.log('Push App', data)
        this.isPushing = true
        await this.pushUser(data)
        await this.getUser(this.user.username)
        this.isPushing = false
      }
    },
    addAppUser() {
      this.actionAddUser = this.user
    },
    selectedUser() {},
    validatePassword(isNotify = false) {
      let valid = true
      this.reason = ''
      if (this.password !== '') {
        if (this.password === this.password2) {
          let strongRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})')
          valid = strongRegex.test(this.password)
          if (valid === false) {
            this.reason =
              "Password doesn't meet minimum strength of special characters (!@#$%^&*), lower case (a-z), upper case (A-Z) and numbers (0-9), and at least 8 characters in length."
          }
        } else {
          this.reason = "Passwords don't match."
          valid = false
        }
      }
      if (isNotify && !valid) {
        alert(this.reason)
      }
      return valid
    },
    async fetchLocations(query) {
      let search = {
        search: query,
        type: 3,
        firm: this.firm
      }
      console.log('Fetching Locations', {
        search: search,
        firm: this.firm
      })
      return await this.searchLocations(search)
    },
    selectedLocation() {
      this.$nextTick(() => {
        let found = this.foundLocations.find((x) => parseInt(x.firm) === parseInt(this.foundLocation))
        if (found) {
          console.log('Found Location', found)
        }
      })
    },
    async reassignUser() {
      let data = {
        user: this.user.user,
        firm: this.foundLocation
      }
      console.log('Moving User', data)
      let result = await this.moveUser(data)
      if (result !== undefined && result !== null) {
        this.$emit('moved')
      } else {
        alert('User Move failed.')
      }
    },
    async save() {
      let response = {
        valid: false,
        contact: null,
        msg: null
      }
      this.reason = null
      try {
        let valid = await this.v$.$validate()
        if (valid) {
          if (this.isAdd) {
            if (this.validatePassword(true)) {
              valid = true
            } else {
              valid = false
            }
          }
          if (valid) {
            let result = await this.saveUser()
            response.valid = true
            response.user = JSON.parse(result)
            response.msg = 'success'
            this.$emit('saved', response)
          } else {
            response.msg = this.reason
            this.$emit('message', this.reason)
          }
        } else {
          let msg = 'User Not Valid'
          response.msg = msg
          this.$emit('message', msg)
        }
      } catch (e) {
        console.error(e)
        response.msg = 'Error: Unable to save'
      }
      return response
    }
  }
}
</script>
<style lang="scss">
.custom-username {
  position: relative;
  display: inline-flex;
  input {
    width: 250px;
  }
  a {
    color: #2c6ffe;
  }
}
.custom-inline {
  display: inline-flex;
}
input.lp-ignore + div[data-lastpass-icon-root] {
  display: none !important;
}
</style>
