import { defineStore } from 'pinia'
import { api } from '@/plugins/api'

export const dataStore = defineStore('data', {
  /* Current State */
  state: () => ({
    locations: [],
    users: [],
    loading: {
      locations: false,
      users: false
    },
    csvData: [],
    csvValidation: []
  }),
  actions: {
    resetCsvData() {
      this.csvData = []
      this.csvValidation = []
    },
    setCsvData(type, data) {
      if (data) {
        if (data.length > 0) {
          if (type === 'locations') {
            let hierarchy = [
              {
                name: 'Corporate',
                value: 1
              },
              {
                name: 'Division',
                value: 2
              },
              {
                name: 'Distributor',
                value: 3
              },
              {
                name: 'Partner',
                value: 4
              },
              {
                name: 'Center',
                value: 5
              },
              {
                name: 'Shop',
                value: 6
              }
            ]
            var parents = [
              ...new Map(
                data.map((item) => [
                  item['parentName'],
                  {
                    name: item.parentName,
                    level: hierarchy.map((e) => e.name).indexOf(item.firmTypeName)
                  }
                ])
              ).values()
            ].filter((x) => x.level !== 0)
            var firmsAsParents = data
              .map((item, idx) => {
                return {
                  name: item.firmName,
                  level: hierarchy.find((e) => e.name === item.firmTypeName).value,
                  idx
                }
              })
              .filter((x) => x.level !== 6)

            let differences = firmsAsParents.filter((x) => !parents.some((y) => y.name === x.name))
            console.log('Differences', differences)
            this.csvData = data
          } else {
            this.csvData = data
          }
          // // var parents = [
          // //   ...new Set(
          // //     data.reduce((result, element) => {
          // //       let level = hierarchy.map((e) => e.name).indexOf(element.firmTypeName)
          // //       result.push({
          // //         name: element.parentName,
          // //         level: level
          // //       })
          // //       return result
          // //     }, [])
          // //   )
          // // ]
          // console.log('Array of Parents', {
          //   parents: parents,
          //   firms: firmsAsParents,
          //   difference: difference
          // })

          // let valid = true
          // let at = null
          // this.csvValidation = []
          // // Test 1: validate initial hierarchy structure
          // for (let idx = 0; idx < hierarchy.length; idx++) {
          //   if (hierarchy[idx].name !== data[idx].firmTypeName) {
          //     valid = false
          //     at = `${data[idx].firmTypeName} (row ${idx + 1})`
          //     break
          //   }
          // }
          // this.csvValidation.push({
          //   name: 'Incorrect Hierarchy structure',
          //   valid: valid,
          //   data: at
          // })
          // valid = true
          // // Test 2: validate current vs previous parent flow
          // let lastParent = null
          // let lastType = null
          // let cnt = 1
          // let row = 0
          // for (let idx = 0; idx < data.length; idx++) {
          //   const element = data[idx]
          //   if (lastParent !== null) {
          //     let lastIdx = hierarchy.map((e) => e.name).indexOf(lastType)
          //     let currentIdx = hierarchy.map((e) => e.name).indexOf(element.firmTypeName)
          //     if (lastType !== element.firmTypeName) {
          //       if (element.parentName !== lastParent) {
          //         valid = false
          //         row = cnt
          //         break
          //       }
          //     }
          //     if (lastIdx !== currentIdx) {
          //       lastParent = element.firmName
          //       lastType = element.firmTypeName
          //     }
          //   } else {
          //     lastParent = element.firmName
          //     lastType = element.firmTypeName
          //   }
          //   cnt++
          // }
          // if (!valid) {
          //   this.csvValidation.push({
          //     name: 'Mismatched current Parent vs previous Parent names',
          //     valid: valid,
          //     data: row
          //   })
          // }
          // // data.forEach((element) => {
          // //   if (lastParent !== null) {
          // //     let lastIdx = hierarchy.indexOf(lastType)
          // //     let currentIdx = hierarchy.indexOf(element.firmTypeName)
          // //     if (element.parentName !== lastParent) {
          // //       if (lastIdx + 1 === currentIdx) {
          // //         valid = false
          // //         row = cnt
          // //       }
          // //     }
          // //     if (lastType !== element.firmTypeName) {
          // //       if (element.parentName !== lastParent) {
          // //         valid = false
          // //         row = cnt
          // //       }
          // //     }
          // //   } else {
          // //     lastParent = element.firmName
          // //     lastType = element.firmTypeName
          // //   }
          // //   cnt++
          // // })

          // // Test 2: validate hierarchy location names
          // // let last = {
          // //   name: null,
          // //   value: 0
          // // }
          // // let cnt = 1
          // // let row = null
          // // let found = null
          // // data.forEach((element) => {
          // //   if (cnt > 1) {
          // //     if (element.parentName === last.name) {
          // //       // still evaluating current parent
          // //       found = hierarchy.find((x) => x.name === element.firmTypeName)
          // //       // now ensure the hierarchy is valid
          // //       if (last.value !== found.value - 1) {
          // //         valid = false
          // //         row = cnt
          // //       }
          // //     } else {
          // //       if (last.name !== found.name) {
          // //         valid = false
          // //         row = cnt
          // //       } else {
          // //         // parent changed, set as last
          // //         found = hierarchy.find((x) => x.name === element.firmTypeName)
          // //         last.name = element.parentName
          // //         last.value = found.value
          // //       }
          // //     }
          // //     cnt++
          // //   }
          // // })

          console.log('Got CSV data', this.csvData)
        }
      }
    },
    async getLocationsForExport(firm) {
      this.locations = []
      this.loading.locations = true
      this.locations = await api.get(`data/firms?firm=${firm}`)
      this.loading.locations = false
      console.log('Got Locations', this.locations)
      return this.locations
    },
    async getUsersForExport(firm) {
      this.users = []
      this.loading.users = true
      this.users = await api.get(`data/users?firm=${firm}`)
      this.loading.users = false
      console.log('Got Users', this.users)
      return this.users
    },
    async importLocations(parentBranch) {
      console.log('Importing Locations', {
        parentBranch: parentBranch,
        csvData: this.csvData
      })
      // return null
      return await api.post(`data/importFirms?parentBranch=${parentBranch}`, this.csvData)
    },
    async importUsers() {
      console.log('Importing Users', this.csvData)
      return await api.post('data/importUsers', this.csvData)
    },
    async verifyCSV(data) {
      console.log('Verifying CSV data', data)
      return await api.post('data/verifyCSV', data)
    }
  }
})
