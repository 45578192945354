<template>
  <home-page />
</template>
<script>
import HomePage from '@/components/HomePage.vue'
export default {
  name: 'HomeView',
  components: {
    HomePage
  }
}
</script>
<style lang="scss" scoped></style>
