<template>
  <div class="row">
    <div class="col">
      <span>The following are those shops being served by this location.</span>
    </div>
  </div>
  <div class="row mt-4">
    <div class="col">
      <span class="custom-label">In Network Shops:</span>
    </div>
    <div class="col">
      <span class="custom-label">Out of Network Shops:</span>
    </div>
  </div>
  <div class="row mt-2">
    <div class="col">
      <in-network ref="inNetwork" :firm="firm" @changed="changed" @saved="savedOutNetwork" />
    </div>
    <div class="col">
      <out-of-network ref="outOfNetwork" :firm="firm" @changed="changed" @saved="savedOutNetwork" />
    </div>
  </div>
</template>
<script>
import { mapState, mapActions } from 'pinia'
import { locationStore } from '@/stores/location'
import InNetwork from './InNetwork.vue'
import OutOfNetwork from './OutOfNetwork.vue'
export default {
  name: 'NetworkTree',
  components: {
    InNetwork,
    OutOfNetwork
  },
  props: {
    firm: Object
  },
  mounted() {
    if (this.firm) {
      this.getInNetwork(this.firm.id)
      this.getOutNetwork(this.firm.id)
    }
  },
  emits: ['changed'],
  computed: {
    ...mapState(locationStore, ['loading']),
    isCenter() {
      let _is = false
      if (this.firm) {
        if (this.firm.type === 'Center') {
          _is = true
        }
      }
      return _is
    }
  },
  methods: {
    ...mapActions(locationStore, ['getInNetwork', 'getOutNetwork']),
    async savedInNetwork() {
      console.log('Calling for In Network', this.firm)
      await this.getInNetwork(this.firm.id)
    },
    async savedOutNetwork() {
      console.log('Calling for Out of Network', this.firm)
      await this.getOutNetwork(this.firm.id)
    },
    changed() {
      this.$emit('changed')
    },
    async save() {
      await this.$refs.inNetwork.save()
      await this.$refs.outOfNetwork.save()
      return {
        valid: true,
        msg: null
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.custom-locations-wrapper {
  max-height: 800px;
  overflow-y: auto;
  overflow-x: hidden;
}
.loadingSpinner {
  margin-top: 40px;
  text-align: center;
  font-weight: 700;
  width: 100%;
  background: rgba(255, 255, 255, 0.6);
  padding: 10px;
  span {
    margin-left: 5px;
  }
}
.custom-label {
  font-weight: 600;
}
</style>
