import { authStore } from '@/stores/auth'
export default (to, from, next) => {
  const store = authStore()
  // exit if we are allowing anonymous
  if (to.matched.some((record) => record.meta.allowAnonymous)) {
    next()
    return
  }
  // exit if they are not authenticated
  if (!store.token) {
    store.setPassthrough = to.path
    next('/login')
    return
  }
  // exit if they are not allowed by role
  if (!store.isAllowed(to.path)) {
    next('/')
    return
  }
  // allowed
  next()
}
