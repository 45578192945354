<template>
  <form @submit.prevent="">
    <z-dialog :show="isVisible" :modal-size="modalSize">
      <template #header>
        <div class="custom-header">
          <div class="row">
            <div class="col-md-11">
              <h4 class="modal-title">Phone</h4>
            </div>
            <div class="col-md-1">
              <a href="#" class="custom-close" @click="close">
                <i class="fa fa-times"></i>
              </a>
            </div>
          </div>
        </div>
      </template>
      <template #body>
        <contact-summary class="ms-4" :record="value" />
        <div class="form-control custom-form">
          <z-select v-model="phoneType" label="Phone Type" name="phoneType" :options="lookups['PhoneType']" :validator="v$" />
          <label for="PhoneNo">Phone #:</label>
          <span class="text-danger">*</span>
          <input v-model="phoneNo" type="tel" class="form-control ms-3" name="PhoneNo" :validator="v$" />
          <z-input v-model="extension" name="Extension" label="Extension" type="Number" />
          <z-textarea v-model="phoneNote" name="PhoneNote" label="Notes" />
        </div>
      </template>
      <template #footer>
        <button type="button" class="btn btn-light" @click="close()">Cancel</button>
        <button type="submit" class="btn btn-primary" @click="save()">Save</button>
      </template>
    </z-dialog>
  </form>
</template>
<script>
import { mapState, mapActions } from 'pinia'
import { contactStore } from '@/stores/contact'
import { lookupStore } from '@/stores/lookup'
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import ZDialog from '@/components/utilities/elements/Dialog.vue'
import ZInput from '@/components/utilities/elements/Input.vue'
import ZSelect from '@/components/utilities/elements/Select.vue'
import ZTextarea from '@/components/utilities/elements/TextArea.vue'
import ContactSummary from '@/components/contact/forms/ContactSummary.vue'
export default {
  name: 'ContactPhone',
  components: {
    ZDialog,
    ZInput,
    ZSelect,
    ZTextarea,
    ContactSummary
  },
  props: {
    modelValue: {
      type: Object
    }
  },
  data() {
    return {
      modalSize: null,
      rules: {
        phoneType: { required },
        phoneNo: { required }
      },
      v$: null
    }
  },
  emits: ['input', 'closing', 'send'],
  computed: {
    ...mapState(lookupStore, ['lookups']),
    ...mapState(contactStore, ['loading', 'phone']),
    phoneType: {
      get() {
        return this.phone !== null ? this.phone.phoneType : 0
      },
      set(value) {
        this.setPhone({
          name: 'phoneType',
          value: parseInt(value)
        })
      }
    },
    phoneNo: {
      get() {
        return this.phone !== null ? this.phone.phoneNo : ''
      },
      set(value) {
        this.setPhone({
          name: 'phoneNo',
          value: value
        })
      }
    },
    extension: {
      get() {
        return this.phone !== null ? this.phone.extension : ''
      },
      set(value) {
        this.setPhone({
          name: 'extension',
          value: value
        })
      }
    },
    phoneNote: {
      get() {
        return this.phone !== null ? this.phone.phoneNote : ''
      },
      set(value) {
        this.setPhone({
          name: 'phoneNote',
          value: value
        })
      }
    },
    isVisible: {
      get() {
        let _is = false
        if (this.modelValue) {
          _is = true
          this.init()
        }
        return _is
      }
    }
  },
  methods: {
    ...mapActions(contactStore, ['setPhone']),
    init() {
      this.v$ = useVuelidate(this.rules, this.phone)
    },
    close() {
      this.$emit('closing')
    },
    async save() {
      console.log('Saving', this.phone)
      const valid = await this.v$.$validate()
      if (valid) {
        this.$emit('send', this.phone.contactPhone)
      }
    },
    submit() {}
  }
}
</script>
<style lang="scss" scoped>
.custom-form {
  text-align: left;
  max-height: 320px;
  height: 320px;
  overflow-x: hidden;
  overflow-y: auto;
}
</style>
