<template>
  <section></section>
</template>
<script>
import { mapState } from 'pinia'
import { authStore } from '@/stores/auth'
import { HubConnectionBuilder, LogLevel } from '@microsoft/signalr'
import globals from '@/plugins/globals'
export default {
  name: 'ServiceHub',
  data() {
    return {
      connection: null,
      signalRstatus: '',
      signalRerror: '',
      connectedInterval: 0,
      signalrUrl: ''
    }
  },
  mounted() {
    this.signalrUrl = window.config ? window.config.signalr_adas : ''
    this.initSignalR()
  },
  computed: {
    ...mapState(authStore, ['profile']),
    isAdmin() {
      let _is = false
      if (this.profile.roles[0] === 1 || this.profile.roles[0] === 2) {
        _is = true
      }
      return _is
    }
  },
  methods: {
    initSignalR() {
      try {
        // Initialize SignalR
        if (this.connection === null) {
          this.connection = new HubConnectionBuilder()
            .withUrl(this.signalrUrl)
            .withAutomaticReconnect([0, 3000, 5000, 10000, 15000, 30000, 60000])
            .configureLogging(LogLevel.Information)
            .build()
          // Listen to "sendMessage"
          this.connection.on('sendMessage', (data) => {
            if (data !== undefined && data !== null) {
              if (globals.isJson(data)) {
                let msg = JSON.parse(data)
                if (msg.name === 'Process_ALLDATA_Link' && this.isAdmin) {
                  this.emitter.emit('process-alldata', msg.data)
                } else if (msg.name === 'Process_AdasMatrix_Import' && this.isAdmin) {
                  this.emitter.emit('process-import', msg.data)
                } else if (msg.name === 'ImportData') {
                  this.emitter.emit('process-dataimport', msg.data)
                } else if (msg.name === 'PushData') {
                  this.emitter.emit('process-pushlocation', msg.data)
                }
              }
            }
          })
          // Start SignalR connection
          this.connection
            .start()
            .then(() => {
              console.log('Service Hub Started')
            })
            .catch((err) => {
              console.error(err.toString())
            })
        }
      } catch (e) {
        console.error('Error: serviceHub > initSignalR', e)
      }
    }
  },
  beforeUnmount() {
    this.connection.stop().then(() => {
      this.connection = null
      console.log('Service Hub Stopped')
    })
  }
}
</script>
<style lang="scss" scoped></style>
