<template>
  <section>
    <div class="form-group mb-2">
      <div :class="{ 'form-group row': inline, 'red-border': isredborder }">
        <div :class="{ 'col-sm-3 mt-1': inline }">
          <label v-if="label" :for="name" class="control-label">
            {{ label }}:
            <span v-if="hasAsterisk" class="text-danger">*</span>
          </label>
        </div>
        <template v-if="disabled">
          <div :class="{ 'col-sm-9': inline }">
            <input class="form-control" :name="name" :value="disabledText" disabled />
          </div>
        </template>
        <template v-else>
          <div :class="{ 'col-sm-9': inline }">
            <div :class="{ error: errors.length > 0 }">
              <select v-model="selected" :name="name" class="form-control" :tabindex="tabindex" @change="change">
                <option v-if="placeholder !== undefined && placeholder !== null" value="" disabled selected>
                  {{ placeholder }}
                </option>
                <option v-for="(option, index) of options" :key="index" :value="option.value">
                  <span v-if="useLabelField">{{ option[labelField] }}</span>
                  <span v-else>{{ option.name }}</span>
                </option>
              </select>
              <div v-if="errors.length > 0">
                <div v-for="error of errors" :key="error.id" class="input-errors">
                  <small class="error-msg text-danger">{{ error.msg }}</small>
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
  </section>
</template>
<script>
import _ from 'lodash'
export default {
  name: 'ZSelect',
  props: {
    name: String,
    modelValue: [Number, String, Boolean],
    label: String,
    labelField: String,
    validator: Object,
    disabled: Boolean,
    readonly: Boolean,
    options: Array,
    inline: {
      type: Boolean,
      default: false
    },
    isredborder: {
      type: Boolean,
      default: false
    },
    tabindex: [Number, String],
    placeholder: String
  },
  emits: ['change', 'update:modelValue'],
  computed: {
    selected() {
      return this.modelValue
    },
    hasAsterisk() {
      let _has = false
      if (this.validator) {
        _has = true
      }
      return _has
    },
    errors() {
      let _data = []
      if (this.validator) {
        if (this.validator.$errors.length > 0) {
          console.log('Select Errors Found', this.validator)
          let found = this.validator.$errors.find((x) => x.$property === this.name)
          if (found) {
            _data.push({
              id: 0,
              msg: found.$message
            })
          }
        }
      }
      return _data
    },
    disabledText() {
      if (this.options && this.options.length) {
        let options = this.options.filter((option) => _.toString(option.value) === _.toString(this.modelValue))
        return options.length ? options[0].name : this.modelValue
      }
      return this.modelValue
    },
    useLabelField() {
      let _use = false
      if (this.labelField !== undefined && this.labelField !== null) {
        _use = true
      }
      return _use
    }
  },
  methods: {
    change($event) {
      this.$emit('update:modelValue', $event.target.value)
      this.$emit('change')
    }
  }
}
</script>

<style scoped="true">
input:disabled {
  cursor: text;
}
.red-border {
  border: solid 1px red;
  padding-top: 5px;
  padding-bottom: 5px;
}
</style>
