<template>
  <z-tabs v-model="currentTab" :tabs="tabs" @change="setTab" />
  <div class="custom-wrapper">
    <div v-if="currentTab === 'Channels'">
      <partner-channels />
    </div>
  </div>
</template>
<script>
import { mapActions } from 'pinia'
import { locationStore } from '@/stores/location'
import { lookupStore } from '@/stores/lookup'
import ZTabs from '@/components/utilities/elements/Tabs.vue'
import PartnerChannels from '@/components/configuration/channels/PartnerChannels.vue'
export default {
  name: 'ConfigHost',
  components: {
    ZTabs,
    PartnerChannels
  },
  data() {
    return {
      currentTab: 'Channels'
    }
  },
  mounted() {
    this.setTab('Channels')
  },
  computed: {
    tabs() {
      let _tabs = ['Channels']
      return _tabs
    }
  },
  methods: {
    ...mapActions(locationStore, ['getPartners']),
    ...mapActions(lookupStore, ['getLookups']),
    async setTab(value) {
      if (value === 'Channels') {
        await this.getPartners(1)
        await this.getLookups({
          keys: ['Channel'],
          firm: 1,
          refresh: false
        })
      }
      this.currentTab = value
    }
  }
}
</script>
<style lang="scss" scoped>
.custom-wrapper {
  padding: 15px;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 700px;
  height: 700px;
}
</style>
