<template>
  <section class="custom-listing">
    <z-table
      v-model="log"
      class="table table-bordered table-striped"
      :is-single-row-detail="false"
      :is-header-fixed="isHeaderFixed"
      :columns="tableColumnsName"
      :detail-column-class="detailColumnClass"
      :loading="isLoading"
      @sort="setOrder"
    >
      <template #tbody="props">
        <td>{{ formatDate(props.row.logged) }}</td>
        <td>{{ props.row.username }}</td>
        <td>{{ props.row.level }}</td>
        <td>{{ props.row.message }}</td>
        <td>{{ props.row.logger }}</td>
        <td>{{ props.row.position }}</td>
      </template>
      <template #empty> Nothing found! </template>
    </z-table>
  </section>
</template>
<script>
import { mapState } from 'pinia'
import { locationStore } from '@/stores/location'
import ZTable from '@/components/utilities/elements/Table.vue'
export default {
  name: 'ReviewLog',
  components: {
    ZTable
  },
  data() {
    return {
      isHeaderFixed: true
    }
  },
  computed: {
    ...mapState(locationStore, ['log']),
    tableColumnsName() {
      let columns = [
        {
          field: 'logged',
          label: 'Logged On',
          sortable: false
        },
        {
          field: 'username',
          label: 'By',
          sortable: false
        },
        {
          field: 'level',
          label: 'Level',
          sortable: false
        },
        {
          field: 'message',
          label: 'Message',
          sortable: false
        },
        {
          field: 'logger',
          label: 'Controller',
          sortable: false
        },
        {
          field: 'position',
          label: 'Position',
          sortable: false
        }
      ]
      return columns
    }
  },
  methods: {
    formatDate(data) {
      let result = null
      if (data) {
        var utc = new Date(data)
        var offset = utc.getTimezoneOffset()
        var local = new Date(utc.getTime() - offset * 60000)
        result = local.toLocaleString('en-US', {
          month: '2-digit',
          day: '2-digit',
          year: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
          second: '2-digit',
          hour12: true
        })
      }
      return result
    }
  }
}
</script>
<style lang="scss" scoped>
.custom-listing {
  overflow-x: hidden;
  overflow-y: auto;
  width: 100%;
  max-height: 600px;
  height: 600px;
}
</style>
