<template>
  <div v-if="isVisible">
    <div class="modal show" :style="dStyle" tabindex="-1" role="dialog" data-bs-backdrop="static" data-bs-keyboard="false">
      <div class="modal-dialog modal-dialog-scrollable" :class="modalSize" role="document">
        <div class="modal-content">
          <div class="modal-header">
            <slot name="header"></slot>
          </div>
          <div class="modal-body" :style="bodyStyle">
            <slot name="body"></slot>
          </div>
          <div class="modal-footer">
            <slot name="footer"></slot>
          </div>
        </div>
      </div>
    </div>
    <div class="modal-backdrop fade show" :style="bdStyle"></div>
  </div>
</template>
<script>
export default {
  name: 'ZDialog',
  props: {
    show: {
      type: Boolean,
      default: false
    },
    modalSize: String,
    bodyStyle: String,
    dStyle: String,
    bdStyle: String
  },
  computed: {
    isVisible: {
      get() {
        return this.show
      }
    }
  }
}
</script>
<style scoped>
.show {
  display: block;
}
</style>
