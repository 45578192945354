<template>
  <section class="nav-wrap">
    <ul ref="ztabs" class="nav nav-tabs" :class="{ 'nav-fill': isFill }">
      <li v-for="tab in tabs" :key="tab" :ref="tab" class="nav-item custom-nav-item">
        <a href="#" class="nav-link" :class="[isActive(tab) ? 'active' : '']" :disabled="isActive(tab)" @click="setTab(tab)">
          {{ tab }}
        </a>
        <div v-if="showBadge(tab)">
          <span class="badge rounded-pill bg-danger custom-badge">
            {{ badgeCount(tab) }}
          </span>
        </div>
      </li>
    </ul>
  </section>
</template>
<script>
export default {
  name: 'ZTabs',
  props: {
    modelValue: String,
    tabs: Array,
    badges: {
      type: Array,
      default: () => []
    },
    useScroll: {
      type: Boolean,
      default: false
    },
    showNumTabs: {
      type: Number,
      default: 0
    },
    isFill: {
      type: Boolean,
      default: false
    }
  },
  emits: ['change', 'save'],
  computed: {
    status() {
      return this.modelValue
    }
  },
  mounted() {
    if (this.useScroll) {
      this.$refs.ztabs.NavPaginator(this.showNumTabs, 'nav-link')
    }
  },
  methods: {
    isActive(tab) {
      let status = this.status || ''
      return status.toUpperCase() === tab.toUpperCase()
    },
    setTab(tab) {
      this.$emit('change', tab)
    },
    setVisibility(tab) {
      if (!tab.isVisible) {
        this.$refs[tab.name].map((el) => el.classList.add('custom-show'))
      } else {
        this.$refs[tab.name].map((el) => el.classList.remove('custom-show'))
      }
    },
    showBadge(tab) {
      let show = false
      if (this.badges.length > 0) {
        let found = this.badges.find((x) => x.name === tab)
        if (found) {
          if (found.count > 0) {
            show = true
          }
        }
      }
      return show
    },
    badgeCount(tab) {
      let count = 0
      if (this.badges.length > 0) {
        let found = this.badges.find((x) => x.name === tab)
        if (found) {
          count = found.count
        }
      }
      return count
    }
  }
}
</script>
<style lang="scss" scoped>
.nav-wrap {
  position: relative;
}
.custom-tab {
  position: relative;
}
button {
  padding: 13px 25px;
}
button:disabled {
  opacity: 1;
}
.custom-button {
  position: absolute;
  right: 60px;
  top: 5px;
  padding: 0.25rem 0.5rem;
  z-index: 10;
}
.custom-nav-item {
  position: relative;
}
.custom-badge {
  position: absolute;
  top: -10px;
  right: -5px;
}
.nav-scroll {
  position: absolute;
  top: 0;
  right: 0;
}
.custom-show {
  display: none;
}
</style>
