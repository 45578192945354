<template>
  <span>For Mobile Copilot, this location is being served by:</span>
  <span class="ms-2 custom-name">{{ firmName }}</span>
  <span class="ms-2">id: {{ id }}</span>
</template>
<script>
import { mapState } from 'pinia'
import { locationStore } from '@/stores/location'
export default {
  name: 'LocationServedBy',
  computed: {
    ...mapState(locationStore, ['servedBy']),
    firmName() {
      let _data = null
      if (this.servedBy) {
        _data = this.servedBy.firmName
      }
      return _data
    },
    id() {
      let _data = null
      if (this.servedBy) {
        _data = this.servedBy.firm
      }
      return _data
    }
  }
}
</script>
<style lang="scss" scoped>
.custom-name {
  font-size: 1em;
  font-weight: 500;
}
</style>
