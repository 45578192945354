<template>
  <form @submit.prevent="">
    <z-dialog :show="isVisible">
      <template #header>
        <div class="custom-header">
          <div class="row">
            <div class="col-md-11">
              <h4 class="modal-title">Change Password</h4>
            </div>
            <div class="col-md-1">
              <a href="#" class="custom-close" @click="close">
                <i class="fa fa-times"></i>
              </a>
            </div>
          </div>
        </div>
      </template>
      <template #body>
        <div class="hostWrapper">
          <div class="row">
            <div class="col">
              <p class="ml-2">Please supply a new password for {{ firstName }} {{ lastName }} ({{ username }}):</p>
            </div>
          </div>
          <div class="row">
            <div class="col form-group">
              <z-input
                v-model="passwordData.password"
                type="password"
                name="password"
                label="Password"
                autocomplete="new-password"
                :validator="v$"
                @keyup="isSave = true"
              />
              <z-input
                v-model="passwordData.password2"
                type="password"
                name="password2"
                label="Password again"
                autocomplete="new-password"
                :validator="v$"
                @keyup="isSave = true"
              />
            </div>
          </div>
        </div>
      </template>
      <template #footer>
        <div class="custom-footer">
          <div class="col">
            <span title="id">{{ ids }}</span>
          </div>
          <div class="col"></div>
          <div class="col custom-right-align">
            <button type="button" class="btn btn-light" @click="close()">Cancel</button>
            <button v-show="isSave" class="btn btn-danger" @click="submit">Save</button>
          </div>
        </div>
      </template>
    </z-dialog>
  </form>
</template>
<script>
import ZDialog from '@/components/utilities/elements/Dialog.vue'
import ZInput from '@/components/utilities/elements/Input.vue'

export default {
  name: 'DialogPassword',
  components: {
    ZDialog,
    ZInput
  },
  props: {
    modelValue: {
      type: Object
    }
  },
  data() {
    return {
      passwordData: {
        password: null,
        password2: null
      },
      reason: '',
      isLoading: true,
      isSave: false
    }
  },
  mounted() {
    let $this = this
    this.isLoading = false
    this.passwordData = {
      password: null,
      password2: null
    }
    this.reason = ''
    setTimeout(function () {
      $this.passwordData = {
        password: null,
        password2: null
      }
      $this.reason = ''
    }, 400)
  },
  emits: ['closing', 'save'],
  computed: {
    isVisible() {
      return this.modelValue !== null
    },
    isAdd() {
      let _is = false
      if (this.modelValue) {
        _is = this.modelValue.isAdd
      }
      return _is
    },
    ids() {
      let _ids = ''
      if (this.modelValue) {
        _ids = `# ${this.modelValue.user}`
      }
      return _ids
    },
    firstName: {
      get() {
        let _name = ''
        if (this.modelValue) {
          _name = this.modelValue.firstName
        }
        return _name
      }
    },
    lastName: {
      get() {
        let _name = ''
        if (this.modelValue) {
          _name = this.modelValue.lastName
        }
        return _name
      }
    },
    username: {
      get() {
        let _name = ''
        if (this.modelValue) {
          _name = this.modelValue.username
        }
        return _name
      }
    }
  },
  methods: {
    close() {
      this.$emit('closing')
    },
    validatePassword() {
      let valid = true
      this.reason = ''
      if (this.isLoading === false && this.passwordData.password !== '') {
        if (this.passwordData.password === this.passwordData.password2) {
          let strongRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})')
          valid = strongRegex.test(this.passwordData.password)
          if (valid === false) {
            this.reason =
              "Password doesn't meet minimum strength of special characters (!@#$%^&*), lower case (a-z), upper case (A-Z) and numbers (0-9), and at least 8 characters in length."
          }
        } else {
          this.reason = "Passwords don't match."
          valid = false
        }
      }
      return valid
    },
    async submit() {
      if (this.passwordData.password === this.passwordData.password2) {
        if (this.validatePassword()) {
          let data = {
            contact: this.modelValue.contact,
            userid: this.modelValue.user,
            password: this.passwordData.password,
            username: this.modelValue.username
          }
          this.$emit('save', data)
          this.passwordData = {
            password: null,
            password2: null
          }
        } else {
          alert(this.reason)
        }
      } else {
        alert('Please enter the same password in both fields')
      }
    }
  }
}
</script>
<style lang="scss">
.custom-header {
  width: 100%;
}
.hostWrapper {
  max-height: 650px;
  overflow-x: hidden;
  overflow-y: auto;
}
.custom-footer {
  width: 100%;
  display: inline-flex;
}
.custom-right-align {
  text-align: right;
}
</style>
