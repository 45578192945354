<template>
  <form @submit.prevent="">
    <z-dialog :show="isVisible" :modal-size="modalSize">
      <template #header>
        <div class="custom-header">
          <div class="row">
            <div class="col-md-11">
              <h4 class="modal-title">Address</h4>
            </div>
            <div class="col-md-1">
              <a href="#" class="custom-close" @click="close">
                <i class="fa fa-times"></i>
              </a>
            </div>
          </div>
        </div>
      </template>
      <template #body>
        <contact-summary class="ms-4" :record="value" />
        <div class="form-control custom-form">
          <z-select v-model="addressType" label="Address Type" name="addressType" :options="lookups['AddressType']" :validator="v$" />
          <z-input v-model="addressLines" name="AddressLines" label="Street Address" :validator="v$" />
          <z-input v-model="city" name="City" label="City" :validator="v$" />
          <z-select v-model="stateProvince" label="State" name="stateProvince" :options="lookups['StateProvince']" :validator="v$" />
          <z-input v-model="postalCode" name="PostalCode" label="Postal Code" :validator="v$" />
          <z-select v-model="country" label="Country" name="country" :options="lookups['Country']" :validator="v$" />
          <z-textarea v-model="addressNote" name="AddressNote" label="Notes" />
        </div>
      </template>
      <template #footer>
        <button type="button" class="btn btn-light" @click="close()">Cancel</button>
        <button type="button" class="btn btn-primary" @click="save()">Save</button>
      </template>
    </z-dialog>
  </form>
</template>
<script>
import { mapState, mapActions } from 'pinia'
import { contactStore } from '@/stores/contact'
import { lookupStore } from '@/stores/lookup'
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import ZDialog from '@/components/utilities/elements/Dialog.vue'
import ZInput from '@/components/utilities/elements/Input.vue'
import ZSelect from '@/components/utilities/elements/Select.vue'
import ZTextarea from '@/components/utilities/elements/TextArea.vue'
import ContactSummary from '@/components/contact/forms/ContactSummary.vue'
export default {
  name: 'DialogAddress',
  components: {
    ZDialog,
    ZInput,
    ZSelect,
    ZTextarea,
    ContactSummary
  },
  props: {
    modelValue: {
      type: Object
    }
  },
  data() {
    return {
      modalSize: null,
      rules: {
        addressType: { required },
        addressLines: { required },
        city: { required },
        stateProvince: { required },
        postalCode: { required },
        country: { required }
      },
      v$: null
    }
  },
  emits: ['input', 'closing', 'send'],
  computed: {
    ...mapState(lookupStore, ['lookups']),
    ...mapState(contactStore, ['loading', 'address']),
    addressType: {
      get() {
        return this.address !== null ? this.address.addressType : 0
      },
      set(value) {
        this.setAddress({
          name: 'addressType',
          value: parseInt(value)
        })
      }
    },
    addressLines: {
      get() {
        return this.address !== null ? this.address.addressLines : ''
      },
      set(value) {
        this.setAddress({
          name: 'addressLines',
          value: value
        })
      }
    },
    city: {
      get() {
        return this.address !== null ? this.address.city : ''
      },
      set(value) {
        this.setAddress({
          name: 'city',
          value: value
        })
      }
    },
    stateProvince: {
      get() {
        return this.address !== null ? this.address.stateProvince : 0
      },
      set(value) {
        this.setAddress({
          name: 'stateProvince',
          value: parseInt(value)
        })
      }
    },
    postalCode: {
      get() {
        return this.address !== null ? this.address.postalCode : ''
      },
      set(value) {
        this.setAddress({
          name: 'postalCode',
          value: value
        })
      }
    },
    country: {
      get() {
        return this.address !== null ? this.address.country : 0
      },
      set(value) {
        this.setAddress({
          name: 'country',
          value: parseInt(value)
        })
      }
    },
    addressNote: {
      get() {
        return this.address !== null ? this.address.addressNote : ''
      },
      set(value) {
        this.setAddress({
          name: 'addressNote',
          value: value
        })
      }
    },
    isVisible: {
      get() {
        let _is = false
        if (this.modelValue) {
          _is = true
          this.init()
        }
        return _is
      }
    }
  },
  methods: {
    ...mapActions(contactStore, ['setAddress']),
    init() {
      this.v$ = useVuelidate(this.rules, this.address)
    },
    close() {
      this.$emit('closing')
    },
    async save() {
      const valid = await this.v$.$validate()
      if (valid) {
        this.$emit('send', this.address.contactAddress)
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.custom-header {
  width: 100%;
}
.custom-close {
  float: right;
  margin-right: 10px;
  color: #000;
  cursor: pointer;
}
.custom-subtitle {
  font-size: 0.9em;
}
.custom-form {
  text-align: left;
  max-height: 400px;
  height: 400px;
  overflow-x: hidden;
  overflow-y: auto;
}
</style>
