import { authStore } from '../stores'

export const api = {
  get: request('GET'),
  post: request('POST'),
  put: request('PUT'),
  delete: request('DELETE')
}

function request(method) {
  return async (url, body) => {
    let apiUrl = window.config ? window.config.apiUrl : ''
    if (!url.startsWith(apiUrl)) {
      url = `${apiUrl}/${url}`
    }
    const requestOptions = {
      method,
      headers: authHeader(url)
    }
    if (body) {
      if (method === 'GET') {
        let params = new URLSearchParams(body)
        let keysForDel = []
        params.forEach((value, key) => {
          if (value === '' || value === null || value === 'null' || value === undefined || value === 'undefined') {
            keysForDel.push(key)
          }
        })
        keysForDel.forEach((key) => {
          params.delete(key)
        })
        url += `?${params.toString()}`
      } else {
        requestOptions.headers['Content-Type'] = 'application/json'
        requestOptions.body = JSON.stringify(body)
      }
    }
    return await fetch(url, requestOptions).then(handleResponse)
  }
}

// helper functions

function authHeader(url) {
  // return auth header with jwt if user is logged in and request is to the api url
  const { token } = authStore()
  const isApiUrl = url.startsWith(window.config ? window.config.apiUrl : '')
  if (token && isApiUrl) {
    return { Authorization: `Bearer ${token}` }
  } else {
    return {}
  }
}

function handleResponse(response) {
  return response.text().then((text) => {
    const data = text && JSON.parse(text)

    if (!response.ok) {
      const { user, logout } = authStore()
      if ([401, 403].includes(response.status) && user) {
        // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
        logout()
      }

      const error = (data && data.message) || response.statusText
      return Promise.reject(error)
    }

    return data
  })
}
