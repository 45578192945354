<template>
  <p>Select those distribution channels this user will have access to:</p>
  <div class="custom-list-wrapper">
    <div class="row">
      <div class="col-8">
        <div class="input-group mb-3">
          <span class="input-group-text">Distributor:</span>
          <select id="channel" v-model="channel" class="form-control" @change="changed">
            <option v-for="item of channels" :key="item.id" :value="item.name">
              {{ item.name }}
            </option>
          </select>
          <button class="btn btn-primary" type="button" tabindex="-1" :disabled="!gotChannel" @click="addChannel">Add</button>
        </div>
      </div>
      <div class="col">
        <button v-if="isChecked" class="btn btn-danger btn-sm" @click="removeChannels">remove</button>
      </div>
    </div>
    <ul class="custom-list">
      <li v-for="(item, idx) of currentChannels" :key="idx">
        <div class="row">
          <div class="col">
            <input
              :id="item.channelId"
              v-model="item.isChecked"
              type="checkbox"
              :true-value="true"
              :false-value="false"
              class="custom-checkbox me-2"
              @click="changed"
            />
            <span>{{ item.channelName }}</span>
          </div>
        </div>
      </li>
    </ul>
  </div>
</template>
<script>
import { mapState, mapActions } from 'pinia'
import { authStore } from '@/stores/auth'
import { userStore } from '@/stores/user'
import { lookupStore } from '@/stores/lookup'
export default {
  name: 'UserChannels',
  data() {
    return {
      searchTerm: null,
      isLoading: false,
      channel: null
    }
  },
  async mounted() {
    await this.init()
  },
  emits: ['changed', 'saved'],
  computed: {
    ...mapState(authStore, ['profile']),
    ...mapState(userStore, ['loading', 'user', 'userChannels']),
    ...mapState(lookupStore, ['lookups']),
    channels() {
      let _data = []
      if (this.lookups['FirmChannel']) {
        _data = this.lookups['FirmChannel']
      }
      return _data
    },
    currentChannels() {
      let _channels = []
      if (this.userChannels) {
        this.userChannels.forEach((channel) => {
          if (!channel.isRemove) {
            _channels.push(channel)
          }
        })
      }
      return _channels
    },
    isChecked() {
      let _is = false
      let found = this.userChannels.filter((x) => x.isChecked)
      if (found) {
        if (found.length > 0) {
          _is = true
        }
      }
      return _is
    },
    gotChannel() {
      let _got = false
      if (this.channel) {
        if (this.channel.trim() !== '') {
          _got = true
        }
      }
      return _got
    }
  },
  methods: {
    ...mapActions(userStore, ['getUserChannels', 'saveUserChannels', 'setUserChannel', 'removeUserChannels']),
    ...mapActions(lookupStore, ['resetDistributionChannels', 'searchDistributionChannels']),
    async init() {
      console.log('Get User Channels', this.user)
      await this.getUserChannels(this.user.user)
    },
    changed() {
      this.$emit('changed')
    },
    async search() {
      this.resetDistributionChannels()
      if (this.searchTerm) {
        if (this.searchTerm.trim() !== '') {
          this.isLoading = true
          await this.searchDistributionChannels(this.searchTerm)
          this.isLoading = false
        }
      }
    },
    addChannel() {
      if (this.channel !== null) {
        let data = {
          user: this.user.user,
          channel: this.channel
        }
        this.setUserChannel(data)
        this.channel = null
      }
    },
    removeChannels() {
      this.removeUserChannels()
    },
    async save() {
      let response = {
        valid: false,
        user: this.user.user,
        msg: null
      }
      console.log('Saving User Channels', this.userChannels)
      await this.saveUserChannels()
      await this.init()
      response.valid = true
      this.$emit('saved')
      return response
    }
  }
}
</script>
<style lang="scss" scoped>
.custom-list-wrapper {
  overflow-x: hidden;
  overflow-y: auto;
  max-height: 400px;
  height: 400px;
}
.custom-list {
  list-style: none;
  li {
    padding-top: 5px;
    padding-bottom: 5px;
  }
}
</style>
