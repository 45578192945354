<template>
  <user-listing :firm="firm" />
</template>
<script>
import { mapState } from 'pinia'
import { authStore } from '@/stores/auth'
import UserListing from '@/components/users/UserListing.vue'
export default {
  name: 'UsersView',
  components: {
    UserListing
  },
  mounted() {},
  computed: {
    ...mapState(authStore, ['profile']),
    firm() {
      let _firm = {
        firm: this.profile.firm,
        firmName: this.profile.firmName,
        firmType: null,
        isAdd: false,
        parentFirm: null
      }
      return _firm
    }
  }
}
</script>
<style lang="scss" scoped></style>
