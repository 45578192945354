<template>
  <paginate
    ref="paginate"
    v-model="currentPage"
    :page-count="pageCount"
    :force-page="currentPage"
    :click-handler="changePage"
    :page-range="1"
    :margin-pages="1"
    prev-text="Prev"
    next-text="Next"
    container-class="pagination"
    page-class="page-item"
    page-link-class="page-link"
    prev-class="page-item"
    prev-link-class="page-link"
    next-class="page-item"
    next-link-class="page-link"
  />
</template>

<script>
import Paginate from './VuePaginate.vue'
export default {
  name: 'ZPaginate',
  components: {
    Paginate
  },
  props: {
    modelValue: Number,
    pageCount: Number
  },
  emits: ['change', 'update:modelValue'],
  computed: {
    currentPage: {
      get() {
        return this.modelValue
      },
      set(value) {
        console.log('Paginator Changed', value)
        this.$emit('update:modelValue', value)
      }
    }
  },
  watch: {
    currentPage(value) {
      this.$refs.paginate.selected = value
    }
  },
  methods: {
    changePage(pageNumber) {
      // this.currentPage = pageNumber
      this.$emit('change', pageNumber)
    }
  }
}
</script>
