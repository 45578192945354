<template>
  <section>
    <label v-if="label" :for="name" class="control-label"> {{ label }}: </label>
    <div class="input-group">
      <input
        :id="name"
        class="form-control"
        type="text"
        :name="name"
        :value="modelValue"
        :maxlength="maxlength || 50"
        :placeholder="placeholder || label"
        :disabled="disabled"
        :readonly="readonly"
        @keyup="keyupHandler"
      />
      <span class="input-group-text custom-clear" title="clear search" @click="clearSearch">
        <i class="fas fa-times"></i>
      </span>
      <button v-if="isAction" class="btn btn-primary btn-sm" @click="performAction">{{ actionTitle }}</button>
    </div>
  </section>
</template>

<script>
import debounce from 'lodash/debounce'
let _wait = 750
export default {
  name: 'ZSearch',
  props: {
    name: String,
    modelValue: String,
    label: String,
    disabled: Boolean,
    readonly: Boolean,
    maxlength: Number,
    placeholder: String,
    wait: Number,
    isAction: {
      type: Boolean,
      default: false
    },
    actionFunction: Function,
    actionTitle: String
  },
  emits: ['search', 'update:modelValue'],
  created() {
    _wait = this.wait || 750
  },
  methods: {
    keyupHandler: debounce(function ($event) {
      this.$emit('update:modelValue', $event.target.value)
      this.$emit('search')
    }, _wait),
    clearSearch() {
      this.$emit('update:modelValue', '')
      this.$emit('search')
    },
    performAction() {
      this.actionFunction()
    }
  }
}
</script>
<style lang="scss" scoped>
.custom-clear {
  cursor: pointer;
}
</style>
