<template>
  <div class="row">
    <div class="col">
      <p>Choose a Partner, then associate Channels you wish to make available to that Partner.</p>
      <p>Note: only those Partners having OPUS IVS Remote App configured (see Locations > Partner > App Config) will appear here.</p>
    </div>
  </div>
  <div class="row">
    <div class="col-4">
      <div class="input-group mb-3">
        <span class="input-group-text">Partner:</span>
        <select id="channel" v-model="partner" class="form-control" @change="selectedPartner">
          <option v-for="item of partners" :key="item.id" :value="item">
            {{ item.name }}
          </option>
        </select>
      </div>
    </div>
    <div class="col"></div>
  </div>
  <div v-if="isPartner" class="row">
    <div class="col-4">
      <div class="input-group mb-3">
        <span class="input-group-text">Distributor:</span>
        <select id="channel" v-model="channel" class="form-control" @change="selectedDistributor">
          <option v-for="item of channels" :key="item.id" :value="item">
            {{ item.name }}
          </option>
        </select>
        <button class="btn btn-primary btn-sm" type="button" tabindex="-1" title="add a distributor" @click="addChannel">Add</button>
        <button
          class="btn btn-primary btn-sm custom-remove-btn"
          type="button"
          tabindex="-1"
          title="remove distributor"
          :disabled="!gotChannel"
          @click="removeChannel"
        >
          Remove
        </button>
      </div>
    </div>
    <div class="col"></div>
  </div>
  <div class="row">
    <div class="col-4 custom-list-wrapper">
      <ul class="custom-list">
        <li v-for="(item, idx) of currentChannels" :key="idx">
          <div class="row">
            <div class="col">
              <input
                :id="item.channelId"
                v-model="item.isChecked"
                type="checkbox"
                :true-value="true"
                :false-value="false"
                class="custom-checkbox me-2"
                @click="changed"
              />
              <span>{{ item.channelName }}</span>
            </div>
          </div>
        </li>
      </ul>
    </div>
    <div class="col"></div>
  </div>
  <div class="row mt-2">
    <div class="col-2">
      <button v-if="isChecked" class="btn btn-primary" @click="deleteChannels">remove</button>
    </div>
    <div class="col-2 text-right">
      <button v-if="isSave" class="btn btn-danger" @click="saveChannels">Save</button>
    </div>
    <div class="col"></div>
  </div>
  <confirm-delete
    v-model="actionDelete"
    :confirm-title="confirmDeleteTitle"
    :confirm-action="confirmDeleteAction"
    :confirm-question="confirmDeleteQuestion"
    @closing="closeModal"
    @proceed="performDeletion"
  />
  <dialog-channel v-model="actionChannel" @closing="closeModal" @saved="savedChannel" />
</template>
<script>
import { mapState, mapActions } from 'pinia'
import { locationStore } from '@/stores/location'
import { lookupStore } from '@/stores/lookup'
import ConfirmDelete from '@/components/utilities/elements/ConfirmDialog.vue'
import DialogChannel from '@/components/configuration/channels/DialogChannel.vue'
import globals from '@/plugins/globals'
export default {
  name: 'PartnerChannels',
  components: {
    ConfirmDelete,
    DialogChannel
  },
  data() {
    return {
      isSave: false,
      partner: null,
      channel: null,
      confirmDeleteTitle: 'Confirm Distributor Removal',
      confirmDeleteAction: 'Set Inactive',
      confirmDeleteQuestion:
        'Note: this will remove the selected Distributor from your main Distributor list.  Are you sure you wish to make this Distributor inactive?',
      actionDelete: null,
      actionChannel: null
    }
  },
  mounted() {
    this.isSave = false
    this.channel = null
    this.partner = null
  },
  computed: {
    ...mapState(locationStore, ['partners', 'partnerChannels']),
    ...mapState(lookupStore, ['lookups']),
    channels() {
      let _data = []
      if (this.lookups['Channel']) {
        let types = JSON.parse(JSON.stringify(this.lookups['Channel']))
        _data = globals.sortArray(types, 'name', 'asc')
      }
      return _data
    },
    currentChannels() {
      let _channels = []
      if (this.partnerChannels) {
        this.partnerChannels.forEach((channel) => {
          if (!channel.isRemove) {
            _channels.push(channel)
          }
        })
      }
      return _channels
    },
    isPartner() {
      let _is = false
      if (this.partner) {
        _is = true
      }
      return _is
    },
    isChecked() {
      let _is = false
      if (this.partnerChannels) {
        let found = this.partnerChannels.filter((x) => x.isChecked)
        if (found) {
          if (found.length > 0) {
            _is = true
          }
        }
      }
      return _is
    },
    gotChannel() {
      let _got = false
      if (this.channel) {
        if (this.channel.name.trim() !== '') {
          _got = true
        }
      }
      return _got
    }
  },
  methods: {
    ...mapActions(locationStore, ['savePartnerChannels', 'getPartnerChannels', 'setPartnerChannel', 'removePartnerChannels', 'deleteChannel']),
    ...mapActions(lookupStore, ['getLookups']),
    closeModal() {
      this.isSave = false
      this.actionDelete = null
      this.actionChannel = null
    },
    async selectedPartner() {
      if (this.partner) {
        this.channel = null
        await this.getPartnerChannels(this.partner.id)
      }
    },
    selectedDistributor() {
      this.isSave = true
      this.$nextTick(() => {
        if (this.channel !== null) {
          console.log('Adding Channel', this.channel)
          let data = {
            firm: this.partner.id,
            channelName: this.channel.name,
            channelId: this.channel.id,
            app: this.partner.value
          }
          this.setPartnerChannel(data)
          console.log('Current Partner Channels', this.partnerChannels)
        }
      })
    },
    changed() {
      this.isSave = true
    },
    addChannel() {
      this.actionChannel = true
    },
    async savedChannel() {
      this.actionChannel = null
      this.channel = null
      console.log('Saved a Channel, refreshing...')
      await this.getLookups({
        keys: ['Channel'],
        firm: 1,
        refresh: true
      })
      if (this.partner) {
        await this.getPartnerChannels(this.partner.id)
      }
      this.isSave = false
    },
    removeChannel() {
      this.actionDelete = true
    },
    async performDeletion() {
      if (this.channel !== null) {
        let found = this.partnerChannels.find((x) => parseInt(x.channel) === parseInt(this.channel.id))
        if (found) {
          found.isAdd = false
          found.isRemove = true
        }
      }
      await this.deleteChannel(this.channel.id)
      await this.getLookups({
        keys: ['Channel'],
        firm: 1,
        refresh: true
      })
      this.isSave = false
      this.channel = null
      this.actionDelete = null
    },
    async deleteChannels() {
      this.removePartnerChannels()
      this.isSave = true
      await this.saveChannels()
    },
    async saveChannels() {
      if (this.isSave) {
        await this.savePartnerChannels()
        this.channel = null
        this.isSave = false
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.custom-list-wrapper {
  height: 450px;
  max-height: 450px;
  overflow-x: hidden;
  overflow-y: auto;
  border: 1px solid #ced4da;
  border-radius: 5px;
  margin-left: 13px;
}
.custom-list {
  list-style: none;
  li {
    padding-top: 5px;
    padding-bottom: 5px;
  }
}
.custom-remove-btn {
  margin-left: 1px !important;
}
</style>
