import crypto from 'crypto-js'

const appName = 'service'
const environment = window.config ? window.config.environment : 'local'

let hash = (key) => {
  return crypto.MD5(`${appName}/${environment}/${key}`)
  // return crypto.createHash('md5').update(`${appName}/${environment}/${key}`).digest('hex')
}

export default {
  session: {
    setItem(key, value, isHash = false) {
      if (isHash) {
        sessionStorage.setItem(hash(key), value)
      } else {
        sessionStorage.setItem(key, value)
      }
    },
    getItem(key, isHash = false) {
      let item = null
      if (isHash) {
        item = sessionStorage.getItem(hash(key))
      } else {
        item = sessionStorage.getItem(key)
      }
      return item
    },
    clear() {
      sessionStorage.clear()
    }
  },
  local: {
    setItem(key, value, isHash = false) {
      if (isHash) {
        localStorage.setItem(hash(key), value)
      } else {
        localStorage.setItem(key, value)
      }
    },
    getItem(key, isHash = false) {
      let item = null
      if (isHash) {
        item = localStorage.getItem(hash(key))
      } else {
        item = localStorage.getItem(key)
      }
      return item
    },
    clear() {
      localStorage.clear()
    }
  }
}
