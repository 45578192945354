<template>
  <section>
    <div class="row">
      <div class="col">
        <span>Please supply a new password for {{ fullname }}:</span>
      </div>
    </div>
    <div class="row mt-2">
      <div class="col-1"></div>
      <div class="form-group col">
        <z-input v-model="password" type="password" name="password" label="Password" autocomplete="new-password" validate="required" />
        <z-input v-model="password2" type="password" name="password2" label="Password again" autocomplete="new-password" validate="required" />
      </div>
      <div class="col-1"></div>
    </div>
    <div class="row mt-2 text-center">
      <div class="col">
        <button v-if="isThere" class="btn btn-primary" @click="changePassword">Change Password</button>
      </div>
    </div>
  </section>
</template>
<script>
import { mapActions } from 'pinia'
import { userStore } from '@/stores/user'
import ZInput from '@/components/utilities/elements/Input.vue'
export default {
  name: 'ResetPassword',
  components: {
    ZInput
  },
  props: {
    userinfo: Object
  },
  data() {
    return {
      password: '',
      password2: '',
      reason: '',
      isLoading: true
    }
  },
  computed: {
    fullname() {
      let _name = null
      if (this.userinfo) {
        _name = `${this.userinfo.firstname} ${this.userinfo.lastname}`
      }
      return _name
    },
    isThere() {
      let _is = false
      if (this.userinfo) {
        _is = true
      }
      return _is
    }
  },
  methods: {
    ...mapActions(userStore, ['updateUserParams']),
    validatePassword() {
      let valid = true
      this.reason = ''
      if (this.isLoading === false && this.password !== '') {
        if (this.password === this.password2) {
          let strongRegex = new RegExp('^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})')
          valid = strongRegex.test(this.password)
          if (valid === false) {
            this.reason =
              "Password doesn't meet minimum strength of special characters (!@#$%^&*), lower case (a-z), upper case (A-Z) and numbers (0-9), and at least 8 characters in length."
          }
        } else {
          this.reason = "Passwords don't match."
          valid = false
        }
      }
      return valid
    },
    validate() {
      let valid = true
      if (this.password.trim() !== '' && this.password2.trim() !== '') {
        if (this.password.trim() === this.password2.trim()) {
          if (this.validatePassword() === false) {
            valid = false
            alert(this.reason)
          }
        } else {
          valid = false
          alert('Password fields do not match')
        }
      } else {
        valid = false
        alert('Please supply a matching password in both fields')
      }
      return valid
    },
    async changePassword() {
      if (this.validate()) {
        let data = {
          firstName: this.userinfo.firstName,
          lastName: this.userinfo.lastName,
          userId: this.userinfo.user,
          contact: this.userinfo.contact,
          username: this.userinfo.username,
          password: this.password,
          token: this.userinfo.token
        }
        let results = await this.updateUserParams(data)
        console.log('Save Credentials', {
          data: data,
          results: results
        })
        this.$router.push('/login')
      }
    }
  }
}
</script>
<style lang="scss" scoped></style>
