<template>
  <form @submit.prevent="">
    <z-dialog :show="isVisible" :modal-size="modalSize">
      <template #header>
        <div class="custom-header">
          <div class="row">
            <div class="col-md-11">
              <h4 class="modal-title">
                {{ title }}
              </h4>
              <span class="custom-subtitle">{{ parentFirm }}</span>
            </div>
            <div class="col-md-1">
              <a href="#" class="custom-close" @click="close">
                <i class="fa fa-times"></i>
              </a>
            </div>
          </div>
        </div>
      </template>
      <template #body>
        <div class="row">
          <div class="col">
            <div class="row">
              <div class="col">
                <z-input v-model="firmName" name="firmName" label="Name" :validator="v$" @keyup="changed" @change="changed" />
              </div>
            </div>
            <div class="row">
              <div class="col">
                <span>{{ firmType }}</span>
              </div>
            </div>
            <div class="row">
              <div class="col">
                <z-input v-model="firmId" name="firmId" label="Id #" @keyup="changed" @change="changed" />
                <div ref="infoId" data-bs-toggle="tooltip" data-bs-placement="top" title="Extra ID reference." class="custom-info">
                  <i class="fas fa-info-circle"></i>
                </div>
              </div>
              <div class="col">
                <z-input v-model="firmNumber" name="firmNumber" label="Number" @keyup="changed" @change="changed" />
                <div ref="infoNumber" data-bs-toggle="tooltip" data-bs-placement="top" title="Extra Number reference." class="custom-info">
                  <i class="fas fa-info-circle"></i>
                </div>
              </div>
              <div class="col">
                <z-input v-model="opusId" name="opusId" label="OPUS IVS #" @keyup="changed" @change="changed" />
              </div>
            </div>
          </div>
        </div>
      </template>
      <template #footer>
        <div class="custom-footer">
          <div class="col-1">
            <span title="location id">#{{ ids }}</span>
          </div>
          <div class="col">
            <z-message v-model="message" class="alert alert-warning mt-2" />
          </div>
          <div class="col custom-right-align">
            <button type="button" class="btn btn-light" @click="close()">Cancel</button>
            <button v-show="isSave" class="btn btn-danger ms-2" @click="save()">Save</button>
          </div>
        </div>
      </template>
    </z-dialog>
  </form>
</template>
<script>
import { mapState, mapActions } from 'pinia'
import { useVuelidate } from '@vuelidate/core'
import { required } from '@vuelidate/validators'
import { locationStore } from '@/stores/location'
// import { Tooltip } from 'bootstrap'
import ZDialog from '@/components/utilities/elements/Dialog.vue'
import ZInput from '@/components/utilities/elements/Input.vue'
import ZMessage from '@/components/utilities/elements/Message.vue'
export default {
  name: 'DialogAddLocation',
  components: {
    ZDialog,
    ZInput,
    ZMessage
  },
  props: {
    modelValue: {
      type: Object
    }
  },
  data() {
    return {
      modalSize: 'modal-xl',
      isSave: false,
      message: null,
      rules: {
        firmName: { required },
        firmType: { required }
      },
      v$: null
    }
  },
  mounted() {},
  emits: ['changed', 'closing', 'saved'],
  computed: {
    ...mapState(locationStore, ['addLocation']),
    isVisible() {
      let _is = false
      if (this.modelValue) {
        _is = true
        this.init()
      }
      return _is
    },
    title() {
      let _title = ''
      if (this.modelValue) {
        _title = 'Adding Location'
      }
      return _title
    },
    parentFirm() {
      let _data = null
      if (this.modelValue) {
        if (this.modelValue.parentFirm.trim() !== '') {
          _data = `to ${this.modelValue.parentFirm}`
        }
      }
      return _data
    },
    firmType() {
      let _data = null
      if (this.modelValue) {
        _data = this.modelValue.firmType
      }
      return _data
    },
    firmName: {
      get() {
        let _data = null
        if (this.addLocation) {
          _data = this.addLocation.firmName
        }
        return _data
      },
      set(value) {
        this.setAddLocation({
          name: 'firmName',
          value: value
        })
      }
    },
    firmId: {
      get() {
        let _data = null
        if (this.addLocation) {
          _data = this.addLocation.firmId
        }
        return _data
      },
      set(value) {
        this.setAddLocation({
          name: 'firmId',
          value: value
        })
      }
    },
    firmNumber: {
      get() {
        let _data = null
        if (this.addLocation) {
          _data = this.addLocation.firmNumber
        }
        return _data
      },
      set(value) {
        this.setAddLocation({
          name: 'firmNumber',
          value: value
        })
      }
    },
    opusId: {
      get() {
        let _data = null
        if (this.addLocation) {
          _data = this.addLocation.opusId
        }
        return _data
      },
      set(value) {
        this.setAddLocation({
          name: 'opusId',
          value: value
        })
      }
    }
  },
  methods: {
    ...mapActions(locationStore, ['setAddLocation', 'saveNewLocation']),
    init() {
      this.v$ = useVuelidate(this.rules, this.addLocation)
      // new Tooltip(this.$refs.infoId)
      // new Tooltip(this.$refs.infoNumber)
    },
    close() {
      this.$emit('closing')
    },
    changed() {
      this.isSave = true
      this.$emit('changed')
    },
    async save() {
      this.message = null
      try {
        const valid = await this.v$.$validate()
        if (valid) {
          let result = await this.saveNewLocation()
          this.$emit('saved', result)
        } else {
          this.message = 'Location Not Valid'
        }
      } catch (e) {
        console.error(e)
        this.message = 'Error: Unable to save'
      }
    }
  }
}
</script>
<style lang="scss" scoped>
.custom-header {
  width: 100%;
}
.custom-close {
  float: right;
  margin-right: 10px;
  color: #000;
  cursor: pointer;
}
.custom-footer {
  width: 100%;
  display: inline-flex;
}
.custom-right-align {
  text-align: right;
}
.custom-subtitle {
  font-size: 0.9em;
}
</style>
