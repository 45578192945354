<template>
  <!-- <location-listing /> -->
  <location-tree />
</template>
<script>
// import LocationListing from '@/components/locations/LocationListing.vue'
import LocationTree from '@/components/locations/LocationTree.vue'
export default {
  name: 'LocationView',
  components: {
    LocationTree
  }
}
</script>
<style lang="scss" scoped></style>
