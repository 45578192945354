import { defineStore } from 'pinia'
import { api } from '@/plugins/api'

const locationDefault = {
  firm: null,
  parent: null,
  parentName: 'OPUS IVS',
  firmName: '',
  firmId: null,
  firmNumber: null,
  firmType: null,
  isInactive: false,
  isTest: false,
  opusId: null,
  cccId: null,
  accountId: null,
  testdriveId: null,
  calcopilotId: null,
  adasId: null,
  isADAS: false,
  isCalCopilot: false,
  isTestDrive: false,
  postalCode: null,
  user: null,
  userId: null
}

export const locationStore = defineStore('location', {
  state: () => ({
    location: JSON.parse(JSON.stringify(locationDefault)),
    locations: {
      count: 0,
      items: []
    },
    innetwork: [],
    outnetwork: [],
    locationTree: [],
    networkTree: [],
    parentLocation: null,
    addLocation: JSON.parse(JSON.stringify(locationDefault)),
    apps: [],
    partners: [],
    children: [],
    loading: {
      location: false,
      locations: false,
      apps: false,
      partners: false,
      children: false,
      users: false,
      locationTree: false,
      networkTree: false,
      innetwork: false,
      outnetwork: false,
      findingLocations: false,
      channels: false,
      partnerChannels: false,
      servedBy: false,
      log: false
    },
    networkParams: {
      page: 1,
      pageSize: 10,
      filterOptions: null,
      filterBy: null,
      sortBy: 'asc',
      orderBy: 'Firm',
      searchTerm: ''
    },
    listParams: {
      page: 1,
      pageSize: 10,
      filterOptions: null,
      filterBy: null,
      sortBy: 'asc',
      orderBy: 'Firm',
      searchTerm: ''
    },
    foundFirm: null,
    userSearchTerm: '',
    foundFirmUsers: null,
    foundLocations: [],
    partnerChannels: [],
    channels: [],
    servedBy: null,
    log: []
  }),
  actions: {
    setListParam(data) {
      this.listParams[data.name] = data.value
    },
    setLoading(data) {
      this.loading[data.name] = data.value
    },
    resetLocation() {
      this.location = JSON.parse(JSON.stringify(locationDefault))
    },
    setLocation(data) {
      this.location[data.name] = data.value
    },
    setAddLocation(data) {
      this.addLocation[data.name] = data.value
    },
    resetAddLocation() {
      this.addLocation = JSON.parse(JSON.stringify(locationDefault))
    },
    resetFoundLocations() {
      this.foundLocations = []
    },
    setNetworkParam(data) {
      this.networkParams[data.name] = data.value
    },
    pushInNetwork(data) {
      let found = this.innetwork.find((x) => parseInt(x.firm2) === parseInt(data.firm2))
      if (!found) {
        this.innetwork.push(data)
      }
    },
    popInNetwork(id) {
      let found = this.innetwork.find((x) => parseInt(x.firm2) === parseInt(id))
      if (found) {
        if (found.isAdd) {
          this.innetwork = this.innetwork.filter((x) => parseInt(x.firm2) !== parseInt(id))
        } else {
          found.isRemove = true
        }
      }
    },
    pushOutNetwork(data) {
      let found = this.outnetwork.find((x) => parseInt(x.firm2) === parseInt(data.firm2))
      if (!found) {
        this.outnetwork.push(data)
      }
    },
    popOutNetwork(id) {
      let found = this.outnetwork.find((x) => parseInt(x.firm2) === parseInt(id))
      if (found) {
        if (found.isAdd) {
          this.outnetwork = this.outnetwork.filter((x) => parseInt(x.firm2) !== parseInt(id))
        } else {
          found.isRemove = true
        }
      }
    },
    setUserContact(data) {
      this.location.userId = data.user
      this.userSearchTerm = null
      if (data.firstname) {
        this.userSearchTerm = `${data.lastname}, ${data.firstname}`
      } else {
        this.userSearchTerm = data.lastname
      }
    },
    setApp(data) {
      if (data) {
        let found = this.apps.find((x) => parseInt(x.app) === parseInt(data.app))
        if (found) {
          found.firm = data.firm
          found.portalUrl = data.portalUrl
          found.apiUrl = data.apiUrl
        }
      }
    },
    setOnboardLocation(data) {
      this.location = data
    },
    setUserSearchTerm(data) {
      this.userSearchTerm = data
      this.foundFirmUsers = []
    },
    async getLocation(id) {
      this.resetLocation()
      this.loading.location = true
      this.location = await api.get(`firm/firm?firm=${id}`)
      this.loading.location = false
      if (this.location.parent) {
        this.parentLocation = JSON.parse(this.location.parent)
        this.location.parent = this.parentLocation.firm
      }
      this.userSearchTerm = null
      if (this.location.user) {
        let user = JSON.parse(this.location.user)
        if (user) {
          this.location.userId = user.user
          if (user.firstname) {
            this.userSearchTerm = `${user.lastname}, ${user.firstname}`
          } else {
            this.userSearchTerm = user.lastname
          }
        }
      }
      console.log('Got Location', this.location)
      return this.location
    },
    async getLocations(options) {
      this.locations = {
        count: 0,
        items: []
      }
      this.loading.locations = true
      this.locations = await api.get('firm/firms', options)
      this.loading.locations = false
      console.log('Got Locations', this.locations)
      return this.locations
    },
    async getLocationTree(data) {
      this.locationTree = []
      // NOTE: this is currently not being used
      this.loading.locationTree = true
      this.locationTree = await api.get(`firm/locationTree?firm=${data.firm}&showInactive=${data.showInactive}`)
      this.loading.locationTree = false
      console.log('Got Location Tree', this.locationTree)
      return this.locationTree
    },
    async getNetworkTree(firm) {
      this.networkTree = []
      // NOTE: this is currently not being used
      this.loading.networkTree = true
      this.networkTree = await api.get(`firm/locationTree?firm=${firm}`)
      this.loading.networkTree = false
      console.log('Got Current Location Tree', this.networkTree)
      return this.networkTree
    },
    async getInNetwork(firm) {
      this.innetwork = []
      this.loading.innetwork = true
      let results = await api.get(`firm/firmFirms?firm=${firm}&relationship=1`)
      this.loading.innetwork = false
      if (results) {
        this.innetwork = results
      }
      console.log('Got In Network', this.innetwork)
      return this.innetwork
    },
    async getOutNetwork(firm) {
      this.outnetwork = []
      this.loading.outnetwork = true
      let results = await api.get(`firm/firmFirms?firm=${firm}&relationship=2`)
      this.loading.outnetwork = false
      if (results) {
        this.outnetwork = results
      }
      console.log('Got Out of Network', this.outnetwork)
      return this.outnetwork
    },
    async saveInNetwork() {
      return await api.post('firm/saveFirmFirms', this.innetwork)
    },
    async saveOutNetwork() {
      return await api.post('firm/saveFirmFirms', this.outnetwork)
    },
    async saveNewLocation() {
      return await api.post('firm/saveFirm', this.addLocation)
    },
    async saveLocation() {
      return await api.post('firm/saveFirm', this.location)
    },
    async deleteLocation(data) {
      return await api.post('firm/deleteFirms', data)
    },
    async getChildren(firm) {
      this.children = []
      this.loading.children = true
      this.children = await api.get(`firm/childFirms?firm=${firm}`)
      this.loading.children = false
      console.log('Got Children', this.children)
      return this.children
    },
    async getPartners(firm) {
      this.partners = []
      this.loading.partners = true
      let results = await api.get(`firm/partnerFirms?firm=${firm}`)
      if (results) {
        results.forEach((element) => {
          this.partners.push({
            id: element.firm,
            name: element.firmName,
            value: element.app
          })
        })
      }
      this.loading.partners = false
      console.log('Got Partners', this.partners)
      return this.partners
    },
    async getLocationApps(data) {
      // NOTE: Location Apps only refer to the Distributor (Brand, such as Gerber, Caliber, etc.)
      //       Remember, this is mainly to capture the App Urls.
      this.apps = []
      this.loading.apps = true
      this.apps = await api.get('firm/firmApps', data) // data = id, dir (byFirm)
      this.loading.apps = false
      if (this.apps) {
        this.apps.forEach((app) => {
          app.checked = true
          app.isPushUsers = true
          if (app.firm === null) {
            app.checked = false
            app.firm = data.id
          }
          if (app.isBms === 1) {
            app.isBms = true
          } else {
            app.isBms = false
          }
        })
      }
      console.log('Got Location Apps', this.apps)
      return this.apps
    },
    async saveLocationApps() {
      let apps = this.apps.filter((x) => x.checked === true)
      if (apps) {
        await api.post('firm/saveApps', apps)
      }
    },
    async deleteLocationApp(data) {
      return await api.post('firm/deleteApp', data)
    },
    async searchAppFirm(app) {
      let data = {
        app: app,
        name: encodeURIComponent(this.location.firmName),
        postalcode: this.location.postalCode
      }
      this.foundFirm = await api.get('firm/searchAppFirm', data)
      return this.foundFirm
    },
    async searchFirmUser(search) {
      this.loading.users = true
      this.foundFirmUsers = await api.get('firm/searchFirmUser', search)
      this.loading.users = false
      return this.foundFirmUsers
    },
    async searchLocations(options) {
      this.loading.findingLocations = true
      this.foundLocations = await api.get(
        `firm/searchFirms?search=${options.search}&type=${options.type}&firm=${options.firm}&firmType=${options.firmType}`
      )
      this.loading.findingLocations = false
      return this.foundLocations
    },
    async pushLocation(data) {
      await api.post('data/pushLocationTree', data)
    },
    async moveLocation(data) {
      return await api.post('firm/moveFirm', data)
    },
    async saveChannel(data) {
      await api.post('app/saveChannel', data)
    },
    async deleteChannel(channel) {
      return await api.get(`app/removeChannel?channel=${channel}`)
    },
    setPartnerChannel(data) {
      let found = this.partnerChannels.find((x) => x.channelName === data.channel)
      if (!found) {
        this.partnerChannels.push({
          channelApp: null,
          firm: data.firm,
          channel: data.channelId,
          app: data.app,
          isInactive: false,
          channelName: data.channelName,
          isChecked: false,
          isRemove: false,
          isAdd: true
        })
      }
    },
    removePartnerChannels() {
      this.partnerChannels.forEach((channel) => {
        if (channel.isChecked) {
          channel.isAdd = false
          channel.isRemove = true
          channel.isChecked = false
        }
      })
    },
    async getPartnerChannels(firm) {
      this.partnerChannels = []
      this.loading.partnerChannels = true
      let results = await api.get(`app/channelApps?firm=${firm}`)
      this.loading.partnerChannels = false
      if (results) {
        if (Array.isArray(this.partnerChannels)) {
          this.partnerChannels = results
          this.partnerChannels.forEach((element) => {
            element.isChecked = false
            element.isRemove = false
            element.isAdd = false
          })
        }
      }
      console.log('Got Partner Channels', this.partnerChannels)
      return this.partnerChannels
    },
    async savePartnerChannels() {
      if (this.partnerChannels) {
        let channels = this.partnerChannels.filter((x) => x.isAdd || x.isRemove)
        if (channels) {
          await api.post(`app/savePartnerChannels`, channels)
        }
      }
    },
    async getServedBy(firm) {
      this.loading.servedBy = true
      this.servedBy = await api.get(`firm/getServedBy?firm=${firm}`)
      this.loading.servedBy = false
      console.log('Got Served By', this.servedBy)
      return this.servedBy
    },
    async getLog(data) {
      this.loading.log = true
      this.log = await api.get('admin/getLog', data)
      this.loading.log = false
      console.log('Got Log', this.log)
      return this.log
    },
    exportLocations(firm) {
      console.log('Export Locations', firm)
    },
    exportUsers(firm) {
      console.log('Export Users', firm)
    }
  }
})
